import { faBuilding, faCartShopping, faFileInvoiceDollar, faFilePdf, faMoneyBillTrendUp } from "@fortawesome/free-solid-svg-icons"
import MenuItem from "../../../types/menu/MenuItem"

const RHONavList: MenuItem[] = [
  {
    id: 1,
    name: 'contactInfo',
    componentName: 'ContactInfo',
    complete: false
  },
  {
    id: 2,
    name: 'incomeExpense',
    componentName: 'IncomeExpense',
    complete: false,
    icon: faMoneyBillTrendUp,
    subItems: [
      {
        id: 21,
        name: 'occupancyStats',
        componentName: 'OccupancyStatistics',
        complete: false
      },
      {
        id: 22,
        name: 'revenue',
        complete: false,
        hasTabs: true,
        componentName: 'RevenueContainer',
        subItems: [
          {
            id: 221,
            name: 'operatingRevenue',
            complete: false,
          },
          {
            id: 222,
            name: 'additionalCommRevenue',
            complete: false,
          }
        ]
      },
      {
        id: 23,
        name: 'expenses',
        componentName: 'ExpenseContainer',
        hasTabs: true,
        complete: false,
        subItems: [
          {
            id: 231,
            name: 'expenses',
            complete: false
          },
          {
            id: 232,
            name: 'fixedCharges',
            complete: false
          }
        ]
      },
      {
        id: 24,
        name: 'reserveForReplacementAllowance',
        componentName: 'ReserveForReplacementAllowance',
        complete: false,
      },
      {
        id: 25,
        name: 'capitalExpSummary',
        complete: false,
        componentName: 'CapitalCostsSummary'
      },
      {
        id: 26,
        name: 'comments',
        componentName: 'Comment',
        complete: false
      }
    ],
  },
  {
    id: 3,
    name: 'residential',
    complete: false,
    icon: faBuilding,
    subItems: [
      {
        id: 31,
        name: 'independentLiving',
        complete: false,
        componentName: 'IndependentLiving'
      },
      {
        id: 32,
        name: 'assistedLiving',
        complete: false,
        componentName: 'AssistedLiving'
      },
      {
        id: 33,
        name: 'memoryCare',
        complete: false,
        componentName: 'MemoryCare'
      },
      {
        id: 34,
        name: 'residentialParking',
        componentName: 'ResidentialParking',
        complete: false,
      },
    ]
  },
  {
    id: 4,
    name: 'commercial',
    icon: faCartShopping,
    complete: false,
    subItems: [
      {
        id: 41,
        name: 'leaseInformationTable',
        componentName: 'LeaseInfo',
        complete: false,
      },
      {
        id: 42,
        name: 'vacantAskingRate',
        complete: false,
        componentName: 'VacantAskingRate',
      },
      {
        id: 43,
        name: 'parkingSummary',
        complete: false,
        componentName: 'ParkingPropertySummary',
      }
    ]
  },
  {
    id: 5,
    name: 'summaryReview',
    componentName: 'Summary',
    complete: false,
    icon: faFilePdf
  },
  {
    id: 6,
    name: 'submit',
    componentName: 'Submit',
    complete: false,
    icon: faFileInvoiceDollar
  }
]

export default RHONavList