import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { allOtherOptions, BusinessOperationsKeys, farmingOption, noFormSelectedOption, orderedBusinessOperations } from '../../pages/BusinessOperations/BusinessOperations';
import { useBusinessOperationsState } from '../../utils/context/BusinessOperationsContext';
import { FormError, useFormErrorContext } from '../../utils/context/FormErrorContext';
import getFullJsonPayload from '../../utils/gev/getFullJsonPayload';
import ConfirmDialog from './ConfirmDialog';
import Checkbox from '../common/Checkbox/Checkbox';
import ErrorMessages from '../common/ErrorMessages/ErrorMessages';
import { setSaving } from '../../redux/actions/saveData/saveData';
import { useDispatch } from 'react-redux';

export interface OverviewProps {
    resetErrCount: (operationKey: BusinessOperationsKeys) => void,
    handleTriggerValidation: (newValue: boolean) => void
}
export default function Overview(props: OverviewProps): JSX.Element {
    const { formData, setFormData } = useBusinessOperationsState();
    const { errors } = useFormErrorContext();
    const dispatch = useDispatch();
    const { t } = useTranslation('gev')
    const [disabled, setDisabled] = useState<Record<string, boolean>>({});
    const [selectedOperation, setSelectedOperation] = useState<BusinessOperationsKeys>();
    const [showConfirmUnselectDialog, setShowConfirmUnselectDialog] = useState(false)
    const [formErrors, setFormErrors] = useState<FormError[]>([]);

    useEffect(() => {
        if (errors.length > 0) {
            errors.forEach((error) => {
                if (error.primaryKey === null) {
                    setFormErrors(prevData => [...prevData, error])
                }
            })
        }
        else {
            setFormErrors([]);
        }
    }, [errors]);

    const handleFormSelection = (operationKey: BusinessOperationsKeys, selected: boolean) => {
        setFormErrors([]);
        setSelectedOperation(operationKey);
        if (!selected) {
            setShowConfirmUnselectDialog(true)
        }
        else {
            const fullPayload = getFullJsonPayload(operationKey);
            switch (operationKey) {
                case noFormSelectedOption:
                    setFormData({
                        commercialGrainElevatorFacility: { selected: false },
                        feedMillingProcessingFacility: { selected: false },
                        otherAgriBusiness: { selected: false },
                        farmingOperationCommercialGrain: { selected: false },
                        noFormSelected: fullPayload
                    });
                    break;
                case farmingOption:
                    setFormData({
                        commercialGrainElevatorFacility: { selected: false },
                        feedMillingProcessingFacility: { selected: false },
                        otherAgriBusiness: { selected: false },
                        farmingOperationCommercialGrain: fullPayload,
                        noFormSelected: { selected: false }
                    });
                    break;
                case "commercialGrainElevatorFacility":
                case "feedMillingProcessingFacility":
                case "otherAgriBusiness":
                    // Set selected to true and disable relevant operations
                    setFormData((prevData) => ({
                        ...prevData,
                        [operationKey]: fullPayload
                    }));
                    break;
                default:
                    break;
            }
        }
    }

    useEffect(() => {
        const selected = {
            commercialGrainElevatorFacility: formData.commercialGrainElevatorFacility.selected,
            feedMillingProcessingFacility: formData.feedMillingProcessingFacility.selected,
            otherAgriBusiness: formData.otherAgriBusiness.selected,
            farmingOperationCommercialGrain: formData.farmingOperationCommercialGrain.selected,
            noFormSelected: formData.noFormSelected.selected
        }
        // reset disabled
        const disabled: Record<string, boolean> = {}
        Object.keys(selected).forEach((key) => { disabled[key] = false })

        const isAnySelected = Object.values(selected).find((v) => v === true)
        if (isAnySelected) {
            if (selected.noFormSelected) {
                allOtherOptions.concat(farmingOption).forEach((key) => { disabled[key] = true })
            } else if (selected.farmingOperationCommercialGrain) {
                allOtherOptions.forEach((key) => { disabled[key] = true })
            } else {
                disabled[farmingOption] = true
            }
        }
        setDisabled(disabled)
    }, [formData.commercialGrainElevatorFacility.selected, formData.farmingOperationCommercialGrain.selected, formData.feedMillingProcessingFacility.selected, formData.noFormSelected.selected, formData.otherAgriBusiness.selected])

    // useEffect(() => {
    //     if (props.validating) {
    //         if (localStorage.getItem("isInternalUser")) {
    //             props.validate("overview", true)
    //             return
    //         }

    //         let valid = false
    //         if (props.assessmentInformation) {
    //             valid = allOtherOptions.concat(farmingOption).concat(noFormSelectedOption).some((key) => props.assessmentInformation[key] && props.assessmentInformation[key]["selected"])
    //         }
    //         if (valid === false) {
    //             props.setErrors("overview", { "operations": t("missingRequiredField") })
    //         }
    //         props.validate("overview", valid)
    //     }
    // }, [props, t])

    // const selectOperation = (operationKey: BusinessOperationsKeys) => {
    //     const data = structuredClone(assessmentInformation[operationKey])
    //     if (!data.selected) {
    //         data.selected = true
    //         changeSelection(operationKey)
    //     }
    // }

    // const unselectOperation = (operationKey: BusinessOperationsKeys) => {
    //     const data = structuredClone(assessmentInformation[operationKey])
    //     if (data["selected"]) {
    //         data["selected"] = false
    //         changeSelection(operationKey)
    //     }
    // }

    const handleUnselectDialogBox = () => {
        setFormData((prevData) => ({
            ...prevData,
            [selectedOperation as BusinessOperationsKeys]: { selected: false }
        }));
        dispatch(setSaving());
        props.handleTriggerValidation(false); // if user unselects a form, delete data from magnetar without triggering spectral
        props.resetErrCount(selectedOperation as BusinessOperationsKeys);
        setShowConfirmUnselectDialog(false);
    }

    return (
        <>
            <div>
                <p className='strong'>{t("overview.heading")}</p>
                <form>
                    {orderedBusinessOperations.length > 0 &&
                        (orderedBusinessOperations.map((key) => {
                            if (Object.keys(formData).length > 0 && Object.keys(formData).includes(key)) {
                                return (
                                    <ul key={key} className='gev-operations-options'>
                                        <li className='flex flex-row align-items-center'>
                                            <Checkbox name={key}
                                                checked={formData[key as BusinessOperationsKeys].selected}
                                                onChange={(e) => handleFormSelection(key as BusinessOperationsKeys, e.currentTarget.checked)}
                                                disabled={disabled[key]}
                                            />
                                            <div className='padding-left-10'>
                                                {t('overview.' + key)}
                                            </div>
                                        </li>
                                    </ul>
                                )
                            } else {
                                return null
                            }
                        }))
                    }
                </form>
                {formErrors && <ErrorMessages errors={formErrors} />}
            </div>
            {
                showConfirmUnselectDialog &&
                <ConfirmDialog onClose={() => setShowConfirmUnselectDialog(false)}
                    onSubmit={handleUnselectDialogBox}
                    title={`${t('overview.areYouSureUnselect')} ${t('overview.' + selectedOperation)}?`}
                    body={t('overview.ifUnselect')}
                />
            }
        </>
    )
}
