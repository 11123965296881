import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getLookups } from '../../redux/actions/lookups/lookups'
import { setSaveResultSuccess } from '../../redux/actions/saveData/saveData'
// import { INTERNAL_IN_PROGRESS } from '../../redux/actionTypes/actionsTypes'
import { RESET_LOOKUPS } from '../../redux/actionTypes/actionsTypes'
import { RootState } from '../../redux/reducers/root'
import { LookupsState } from '../../redux/reducers/getLookups/lookupsReducer'
import { ProfileOverviewState } from '../../redux/reducers/getProfile/getProfileOverviewReducer'
import { SaveDataState } from '../../redux/reducers/getSaveData/saveDataReducer'
import IncomeAttributeLookupObject from '../../types/lookups/IncomeAttributeLookupObject'
import './Summary.css'
import { IncomeAttributeState } from '../../redux/reducers/getIncomeAttributes/getIncomeAttributesReducer'
import { getIncomeAttributesBySnapshotId } from '../../redux/actions/incomeAttributes/incomeAttributes'
import { GlobalSettingState } from '../../redux/reducers/getGlobalSetting/getGlobalSettingReducer'
import { ProfileAttributesState } from '../../redux/reducers/getProfile/profileAttributes/getAttributesReducer'
import { getProfileAttributes } from '../../redux/actions/profile/profile'
import IncomeExpenseSummary from './IncomeExpenseSummary'
import PropertySummary from './PropertySummary'
import UnitSummary from './UnitSummary'
import SuiteSummary from './SuiteSummary'
import { getFullTenantSummaryBySnapshotId } from '../../redux/actions/incomeUnit/incomeUnit'
import BedCounts from '../PIE/BedCounts/BedCounts'
import OccupancyStatistics from '../PIE/OccupancyStatistics/OccupancyStatistics'
import NumberOfSites from '../PIE/NumberOfSites/NumberOfSites'
import RoundsRate from '../PIE/RoundsRate'
import NewAccordion from '../../components/common/NewAccordion/NewAccordion'

export default function Summary(): JSX.Element {

    const dispatch = useDispatch()
    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview)
    const profileAttributes: ProfileAttributesState = useSelector((state: RootState) => state.profileReducer.getProfileAttributes as ProfileAttributesState)
    const incomeAttributeLookupType = profileOverview.profile.profile_type_code.toLowerCase()
    const lookupState: LookupsState = useSelector((state: RootState) => state.lookupsReducer.getLookups)
    const incomeAttributesState: IncomeAttributeState = useSelector((state: RootState) => state.incomeAttributesReducer.incomeAttributes);
    const globalSettingState: GlobalSettingState = useSelector((state: RootState) => state.globalSettingReducer.globalSetting);
    const currencySymbol = (globalSettingState.setting["currency"] as Record<string, unknown>)["symbol"] as string;
    const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData)
    const tenantSummaryType = (localStorage.getItem("propertyCode") === "580" ? "2" : "1");
    const { t } = useTranslation(['pie', 'room_statistics', 'navigation', 'number_of_sites', 'rounds_rate', 'occupancy_statistics']);

    useEffect(() => {
        const currentSnapshotId = Number(localStorage.getItem('currentSnapshotId') as string);
        dispatch(getIncomeAttributesBySnapshotId(currentSnapshotId));
        dispatch(getFullTenantSummaryBySnapshotId(currentSnapshotId, tenantSummaryType));
        dispatch(getProfileAttributes(profileOverview.profile.profile_type_code, currentSnapshotId))
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!lookupState.success || lookupState.lookupType !== incomeAttributeLookupType + "-income-attributes") {
          dispatch(getLookups(incomeAttributeLookupType + "-income-attributes"));
        }
    }, [dispatch, lookupState.success, incomeAttributeLookupType, lookupState.lookupType]);

    useEffect(() => {
        if (saveData.saving) {
            dispatch(setSaveResultSuccess())
            dispatch({ type: RESET_LOOKUPS })
            dispatch(getLookups(incomeAttributeLookupType))
        }
    }, [dispatch, incomeAttributeLookupType, saveData.saving])

    const findAttributeData = (attributeCode: string) => {
        let incomeAttributesData: Record<string, unknown> | undefined = undefined;
        if (incomeAttributesState.data) {
          incomeAttributesData = (incomeAttributesState.data as Record<string, unknown>[]).find(({ code }) => code === attributeCode);
        }
        return incomeAttributesData;
    }

    const calcTotalSubsidy = () => {
        const keys = [
            'nursingPersonalCareTotal',
            'programSupportServiceTotal',
            'rawFoodTotal',
            'cchsaTotal',
            "globalPierDiem",
            'equalizationAdjustmentTotal',
            'aggregateSubsidyTotal',
            'structuralComplianceFundingTotal',
            'municipalTaxAllowanceTotal',
            'additionalConstructionFundingTotal',
            'subsidyOtherTotal',
            'subsidyOtherDescription'
        ];
        let total = 0;
        keys.forEach((key) => {
            const value = profileAttributes.data[key as keyof typeof profileAttributes.data]
            if (value && typeof value === "number") {
                total = total + ((value as number) ?? 0);
            }
        })
        return total;
    }

    const initTotalIncome = (showDetails = true, ignoreFields?: string[]) => {
        let total = 0;
        let vacancyPercent = 0;
        let incomeTotals: Record<string, number> = {};
        if (incomeAttributeLookupType === "ltc" && profileAttributes.data) {
            const subsidyTotal = calcTotalSubsidy();
            incomeTotals["subsidy"] = subsidyTotal;
            total += subsidyTotal;
        }
        const keys = Object.keys(lookupState.lookups[1]);
        keys.forEach((key) => {
            let currentTotal = 0;
            const lookupAttributes = lookupState.lookups[1][key] as IncomeAttributeLookupObject[];
            lookupAttributes.forEach((attribute) => {
                if (attribute.activeFlag === "Y") {
                    const code = attribute.incomeAttributeCode;
                    const incomeAttributesData = findAttributeData(code);
                    if (incomeAttributeLookupType === "ltc" && code === "159") {
                        if (incomeAttributesData && incomeAttributesData.percent && incomeAttributesData.percent !== 0) {
                            vacancyPercent = (incomeAttributesData.percent as number);
                        }
                    } else {
                        if (incomeAttributesData && incomeAttributesData.value && incomeAttributesData.value !== 0) {
                            if (key !== "rentalLoss") {
                                total = total + (incomeAttributesData.value as number);
                            }
                            currentTotal = currentTotal + (incomeAttributesData.value as number);
                        }
                    }
                }
            });
            (!ignoreFields || (ignoreFields && !(ignoreFields.includes(key)))) && (incomeTotals[key] = currentTotal);
        });
        if (vacancyPercent !== 0) {
            total = total - (total * vacancyPercent / 100);
        }
        if (!showDetails) {
            incomeTotals = {};
        }
        (!ignoreFields || (ignoreFields && !(ignoreFields.includes("grandTotal")))) && (incomeTotals["grandTotal"] = total)
        return incomeTotals;
    }

    const initTotalExpenseOrCost = (type: 2 | 3 | 4, showDetails = true, ignoreFields?: string[]) => {
        let total = 0;
        let expenseTotals: Record<string, number> = {};
        const keys = Object.keys(lookupState.lookups[type]);
        keys.forEach((key) => {
            let currentTotal = 0;
            const lookupAttributes = lookupState.lookups[type][key] as IncomeAttributeLookupObject[];
            lookupAttributes.forEach((attribute) => {
                if (attribute.activeFlag === "Y") {
                    const code = attribute.incomeAttributeCode;
                    const incomeAttributesData = findAttributeData(code);
                    if (incomeAttributesData && incomeAttributesData.value && incomeAttributesData.value !== 0) {
                        if (!ignoreFields || (ignoreFields && !(ignoreFields.includes(key)))) {
                            total = total + (incomeAttributesData.value as number);
                        }
                        currentTotal = currentTotal + (incomeAttributesData.value as number);
                    }
                }
            });
            (!ignoreFields || (ignoreFields && !(ignoreFields.includes(key)))) && (expenseTotals[key] = currentTotal);
        });
        if (!showDetails) {
            expenseTotals = {};
        }
        (!ignoreFields || (ignoreFields && !(ignoreFields.includes("grandTotal")))) && (expenseTotals["grandTotal"] = total)
        return expenseTotals;
    }

    return (
        <div className='summary-container'>
            <div className='summary-page-container'>
                {["htl", "mtl"].includes(incomeAttributeLookupType) && 
                    <NewAccordion header={t("roomTableHeader", {ns: 'room_statistics'})}>
                        <div className='summary-grid'>
                            <div className='summary-section-container'>
                                <table className='summary-cost summary-table summary-table-headerless'>
                                    <thead className='invisible-header'><tr><th></th></tr></thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {t("roomNumOfRoomHeader", { ns: 'room_statistics' })}
                                            </td>
                                            <td className='summary-right-aligned-cell'>
                                                {profileAttributes.data && ("numberOfRoom" in profileAttributes.data) && profileAttributes.data["numberOfRoom"] ? profileAttributes.data["numberOfRoom"] : "" }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </NewAccordion>
                }
                {["ltc"].includes(incomeAttributeLookupType) && <>
                    <NewAccordion header={t("bedCounts", {ns: 'navigation'})}><div className='summary-grid'><div className='summary-section-container summary-two-grids'><BedCounts readonly={true} /></div></div></NewAccordion>
                </>}   
                {["rho"].includes(incomeAttributeLookupType) && <>
                    <NewAccordion header={t("Occupancy Statistics", {ns: 'occupancy_statistics'})}><div className='summary-grid'><div className='summary-section-container'><OccupancyStatistics readonly={true} /></div></div></NewAccordion>
                </>}   
                {["lle"].includes(incomeAttributeLookupType) && 
                    <NewAccordion header={t("numberOfSitesTableHeader", {ns: 'number_of_sites'})}><div className='summary-grid'><div className='summary-section-container'><NumberOfSites readonly={true} /></div></div></NewAccordion>
                }   
                {["glf"].includes(incomeAttributeLookupType) && 
                    <NewAccordion header={t("roundsTableHeader", {ns: 'rounds_rate'})}>
                        <div className='summary-grid'><div className='summary-section-container summary-two-grids'><RoundsRate readonly={true} /></div></div>
                    </NewAccordion>} 
                {["bip", "htl", "mtl", "ltc", "rho", "lle", "glf"].includes(incomeAttributeLookupType) && <NewAccordion header={t("incomeExpense", {ns: 'navigation'})}><div className='summary-grid'>
                {["bip", "htl", "mtl", "ltc", "rho", "lle", "glf"].includes(incomeAttributeLookupType) && lookupState.success && lookupState.lookups && <IncomeExpenseSummary currencySymbol={currencySymbol} totalHeader={t('revenues.totalRevenueIncome' , { ns: 'pie' }) as string} tableData={initTotalIncome(["bip", "htl", "mtl", "ltc", "rho", "glf"].includes(incomeAttributeLookupType))} />}
                {["bip", "htl", "mtl", "ltc", "rho", "lle", "glf"].includes(incomeAttributeLookupType) && lookupState.success && lookupState.lookups && <IncomeExpenseSummary currencySymbol={currencySymbol} totalHeader={t('GLF.expense.total', { ns: 'pie' }) as string} tableData={initTotalExpenseOrCost(2 , true, 
                    ["htl", "mtl", "ltc"].includes(incomeAttributeLookupType) ? ["replacementReserve"] : (["rho"].includes(incomeAttributeLookupType) ? ["reserve"] : undefined))} />}
                {["htl", "mtl", "rho", "glf"].includes(incomeAttributeLookupType) && lookupState.success && lookupState.lookups && <IncomeExpenseSummary currencySymbol={currencySymbol} totalHeader={t('reserve.Total', { ns: 'pie' }) as string} tableData={initTotalExpenseOrCost(4, false)} />}
                {["bip", "htl", "mtl", "rho", "lle", "glf"].includes(incomeAttributeLookupType) && lookupState.success && lookupState.lookups && <IncomeExpenseSummary currencySymbol={currencySymbol} totalHeader={t('costs.totalCapitalExp', { ns: 'pie' }) as string} tableData={initTotalExpenseOrCost(3, ["lle"].includes(incomeAttributeLookupType),
                    (["lle"].includes(incomeAttributeLookupType) ? ["grandTotal"] : undefined))} separateTables={["lle"].includes(incomeAttributeLookupType)} />}
                </div></NewAccordion>}
                {["bip", "htl", "mtl", "ltc", "rho", "lle", "glf"].includes(incomeAttributeLookupType) && !profileAttributes.loading && profileAttributes.data && <PropertySummary propertyData={profileAttributes.data} /> }
                {["bip", "htl", "mtl", "ltc", "rho", "lle", "glf"].includes(incomeAttributeLookupType) && <UnitSummary /> }
                {["bip", "mtl", "ltc", "lle", "glf"].includes(incomeAttributeLookupType) && <SuiteSummary /> }
                {["rho"].includes(incomeAttributeLookupType) && <><SuiteSummary unitType='4' /> <SuiteSummary unitType='5' /><SuiteSummary unitType='6' /></>}
            </div>
        </div>
    )
}
