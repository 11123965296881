import { faBuilding, faCartShopping } from "@fortawesome/free-solid-svg-icons"
import MenuItem from "../../../types/menu/MenuItem"

const BIPCommercialApartment: MenuItem[] = [
  {
    id: 3,
    name: 'commercial',
    complete: false,
    icon: faCartShopping,
    subItems: [
      {
        id: 31,
        name: 'leaseInformationTable',
        complete: false,
        componentName: 'LeaseInfo',
      },
      {
        id: 32,
        name: 'vacantAskingRate',
        complete: false,
        componentName: 'VacantAskingRate',
      },
      {
        id: 33,
        name: 'parkingSummary',
        complete: false,
        componentName: 'ParkingPropertySummary',
      }
    ]
  },
  {
    id: 4,
    name: 'apartment',
    complete: false,
    icon: faBuilding,
    subItems: [
      {
        id: 41,
        name: 'suiteBreakdownTable',
        complete: false,
        componentName: 'SuiteBreakdown'
      }
    ]
  }
]

export default BIPCommercialApartment