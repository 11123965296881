import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ProfileOverviewState } from '../../../redux/reducers/getProfile/getProfileOverviewReducer'
import { RootState } from '../../../redux/reducers/root'
import "./BedCounts.css"
import { getProfileAttributes, putProfileAttributes, resetGetProfileAttributes } from '../../../redux/actions/profile/profile'
import { useTranslation } from 'react-i18next'
import Input from '../../../components/common/Input/Input'
import { SaveDataState } from '../../../redux/reducers/getSaveData/saveDataReducer'
import { SaveResultState } from '../../../redux/reducers/getSaveData/saveResultReducer'
import { ProfileAttributesState } from '../../../redux/reducers/getProfile/profileAttributes/getAttributesReducer'
import { ProfileAttributes } from '../../../types/profile/ProfileAttributes'

export interface BedCountsProps {
    readonly?: boolean;
}

export default function BedCounts(props: BedCountsProps): JSX.Element {
    const { t } = useTranslation("ltc_profile_attribute")
    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview)
    const profileAttributes: ProfileAttributesState = useSelector((state: RootState) => state.profileReducer.getProfileAttributes as ProfileAttributesState)
    const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult)
    const dispatch = useDispatch()
    const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData)
    const horizontalHeaders = ["classificationHeader", "AAA", "AA", "A", "B", "C", "D", "hTotalLicensedBedsHeader"]
    const verticalHeaders = ["licensedBedTypeHeader", "basicBedsHeader", "semiPrivateBedsHeader", "privateBedsHeader", "otherHeader", "vTotalLicensedBedsHeader"]
    const basicCellNames = ["basicBedAaa", "basicBedAa", "basicBedA", "basicBedB", "basicBedC", "basicBedD"]
    const semiCellNames = ["semipublicBedAaa", "semipublicBedAa", "semipublicBedA", "semipublicBedB", "semipublicBedC", "semipublicBedD"]
    const privateCellNames = ["publicBedAaa", "publicBedAa", "publicBedA", "publicBedB", "publicBedC", "publicBedD"]
    const otherCellNames = ["otherBedAaa", "otherBedAa", "otherBedA", "otherBedB", "otherBedC", "otherBedD"]
    const keys = basicCellNames.concat(semiCellNames).concat(privateCellNames).concat(otherCellNames)
    const [data, setData] = useState<Record<string, number | null>>(() => {
        const initialData: Record<string, number | null> = {}
        keys.forEach((key) => {
            initialData[key] = null
        })
        return initialData
    })
    const currentSnapshotId = Number(localStorage.getItem('currentSnapshotId') as string)

    useEffect(() => {
        dispatch(resetGetProfileAttributes())
        dispatch(getProfileAttributes(profileOverview.profile.profile_type_code, currentSnapshotId))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!profileAttributes.loading && profileAttributes.data) {
            const initData: Record<string, number | null> = {}
            keys.forEach((key) => {
                initData[key] = profileAttributes.data[key as keyof ProfileAttributes] as number | null
            })
            setData(initData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileAttributes.data, profileAttributes.loading])

    useEffect(() => {
        if (saveData.saving && !props.readonly) {
            const fulldata = { ...profileAttributes.data, ...data }
            dispatch(putProfileAttributes(profileOverview.profile.profile_type_code, currentSnapshotId, fulldata))
        }
    }, [currentSnapshotId, data, dispatch, profileAttributes.data, profileOverview.profile.profile_type_code, saveData.saving, props.readonly])

    useEffect(() => {
        if (saveResultState.status === 200) {
            dispatch(getProfileAttributes(profileOverview.profile.profile_type_code, currentSnapshotId))
        }
    }, [currentSnapshotId, dispatch, profileOverview.profile.profile_type_code, saveResultState.status])

    const calculateBasicBedTotal = () => {
        return (data.basicBedAaa ?? 0)
            + (data.basicBedAa ?? 0)
            + (data.basicBedA ?? 0)
            + (data.basicBedB ?? 0)
            + (data.basicBedC ?? 0)
            + (data.basicBedD ?? 0)
    }
    const calculateSemiPrivateBedTotal = () => {
        return (data.semipublicBedAaa ?? 0)
            + (data.semipublicBedAa ?? 0)
            + (data.semipublicBedA ?? 0)
            + (data.semipublicBedB ?? 0)
            + (data.semipublicBedC ?? 0)
            + (data.semipublicBedD ?? 0)
    }
    const calculatePrivateBedTotal = () => {
        return (data.publicBedAaa ?? 0)
            + (data.publicBedAa ?? 0)
            + (data.publicBedA ?? 0)
            + (data.publicBedB ?? 0)
            + (data.publicBedC ?? 0)
            + (data.publicBedD ?? 0)
    }
    const calculateOtherBedTotal = () => {
        return (data.otherBedAaa ?? 0)
            + (data.otherBedAa ?? 0)
            + (data.otherBedA ?? 0)
            + (data.otherBedB ?? 0)
            + (data.otherBedC ?? 0)
            + (data.otherBedD ?? 0)
    }
    const calculateAaaBedTotal = () => {
        return (data.basicBedAaa ?? 0)
            + (data.semipublicBedAaa ?? 0)
            + (data.publicBedAaa ?? 0)
            + (data.otherBedAaa ?? 0)
    }
    const calculateAaBedTotal = () => {
        return (data.basicBedAa ?? 0)
            + (data.semipublicBedAa ?? 0)
            + (data.publicBedAa ?? 0)
            + (data.otherBedAa ?? 0)
    }
    const calculateABedTotal = () => {
        return (data.basicBedA ?? 0)
            + (data.semipublicBedA ?? 0)
            + (data.publicBedA ?? 0)
            + (data.otherBedA ?? 0)
    }
    const calculateBBedTotal = () => {
        return (data.basicBedB ?? 0)
            + (data.semipublicBedB ?? 0)
            + (data.publicBedB ?? 0)
            + (data.otherBedB ?? 0)
    }
    const calculateCBedTotal = () => {
        return (data.basicBedC ?? 0)
            + (data.semipublicBedC ?? 0)
            + (data.publicBedC ?? 0)
            + (data.otherBedC ?? 0)
    }
    const calculateDBedTotal = () => {
        return (data.basicBedD ?? 0)
            + (data.semipublicBedD ?? 0)
            + (data.publicBedD ?? 0)
            + (data.otherBedD ?? 0)
    }

    const calculateTotal = () => {
        return calculateBasicBedTotal() + calculateSemiPrivateBedTotal() + calculatePrivateBedTotal() + calculateOtherBedTotal()
    }

    const updateValue = (e: React.ChangeEvent, key: string) => {
        const tester = /^[0-9]*$/
        if (tester.test((e.target as HTMLInputElement).value)) {
            if ((e.target as HTMLInputElement).value !== "") {
                setData({ ...data, [key]: parseInt((e.target as HTMLInputElement).value) })
            } else {
                setData({ ...data, [key]: null })
            }
        } else {
            (e.target as HTMLInputElement).value = data[key as keyof ProfileAttributes]?.toString() ?? ""
        }
    }

    return (
        <>
            <div className='partial-width'>
                {(data !== undefined) &&
                    <table className='bed-counts-table table-with-border' id="bed-counts-table" aria-describedby="bed-counts-table">
                        <thead>
                            <tr>
                                <th rowSpan={2} id={verticalHeaders[0]}>{t(verticalHeaders[0])}</th>
                                <th colSpan={6} id={horizontalHeaders[0]}>{t(horizontalHeaders[0])}</th>
                                <th rowSpan={2} id={horizontalHeaders[7]}>{t(horizontalHeaders[7])}</th>
                            </tr>
                            <tr>
                                {horizontalHeaders.slice(1, 7).map((header, index) => <th key={`horizontal-header-${index}`} id={header} headers={horizontalHeaders[0] + " " + verticalHeaders[0]}>
                                    {t(header)}
                                </th>)}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td id={verticalHeaders[1]}>{t(verticalHeaders[1])}</td>
                                {basicCellNames.map((cellName, index) => <td key={`row-1-${index}`} className='bed-counts-input-cell' headers={horizontalHeaders.slice(1, 7)[index] + " " + verticalHeaders[1]}>
                                    {props.readonly ? (data[cellName as keyof ProfileAttributes] ? data[cellName as keyof ProfileAttributes]?.toString() : "")
                                    :   <Input name={cellName} id={cellName} type="text"
                                        value={data[cellName as keyof ProfileAttributes] ? data[cellName as keyof ProfileAttributes]?.toString() : undefined}
                                        ariaLabel={cellName} onChange={e => updateValue(e, cellName)}
                                    />}
                                </td>)}
                                <td headers={horizontalHeaders[7] + " " + verticalHeaders[1]}>{calculateBasicBedTotal()}</td>
                            </tr>
                            <tr>
                                <td id={verticalHeaders[2]}>{t(verticalHeaders[2])}</td>
                                {semiCellNames.map((cellName, index) => <td key={`row-2-${index}`} className='bed-counts-input-cell' headers={horizontalHeaders.slice(1, 7)[index] + " " + verticalHeaders[2]}>
                                    {props.readonly ? (data[cellName as keyof ProfileAttributes] ? data[cellName as keyof ProfileAttributes]?.toString() : "") 
                                    :   <Input name={cellName} id={cellName} type="text"
                                        value={data[cellName as keyof ProfileAttributes] ? data[cellName as keyof ProfileAttributes]?.toString() : undefined}
                                        ariaLabel={cellName} onChange={e => updateValue(e, cellName)}
                                    />}
                                </td>)}
                                <td headers={horizontalHeaders[7] + " " + verticalHeaders[2]}>{calculateSemiPrivateBedTotal()}</td>
                            </tr>
                            <tr>
                                <td id={verticalHeaders[3]}>{t(verticalHeaders[3])}</td>
                                {privateCellNames.map((cellName, index) => <td key={`row-3-${index}`} className='bed-counts-input-cell' headers={horizontalHeaders.slice(1, 7)[index] + " " + verticalHeaders[3]}>
                                    {props.readonly ? (data[cellName as keyof ProfileAttributes] ? data[cellName as keyof ProfileAttributes]?.toString() : "")
                                    :   <Input name={cellName} id={cellName} type="text"
                                        value={data[cellName as keyof ProfileAttributes] ? data[cellName as keyof ProfileAttributes]?.toString() : undefined}
                                        ariaLabel={cellName} onChange={e => updateValue(e, cellName)}
                                    />}
                                </td>)}
                                <td headers={horizontalHeaders[7] + " " + verticalHeaders[3]}>{calculatePrivateBedTotal()}</td>
                            </tr>
                            <tr>
                                <td id={verticalHeaders[4]}>{t(verticalHeaders[4])}</td>
                                {otherCellNames.map((cellName, index) => <td key={`row-4-${index}`} className='bed-counts-input-cell' headers={horizontalHeaders.slice(1, 7)[index] + " " + verticalHeaders[4]}>
                                    {props.readonly ? (data[cellName as keyof ProfileAttributes] ? data[cellName as keyof ProfileAttributes]?.toString() : "")
                                    :   <Input name={cellName} id={cellName} type="text"
                                        value={data[cellName as keyof ProfileAttributes] ? data[cellName as keyof ProfileAttributes]?.toString() : undefined}
                                        ariaLabel={cellName} onChange={e => updateValue(e, cellName)}
                                    />}
                                </td>)}
                                <td headers={horizontalHeaders[7] + " " + verticalHeaders[4]}>{calculateOtherBedTotal()}</td>
                            </tr>
                            <tr>
                                <td id={verticalHeaders[5]}>{t(verticalHeaders[5])}</td>
                                <td headers={horizontalHeaders[1] + " " + verticalHeaders[5]}>{calculateAaaBedTotal()}</td>
                                <td headers={horizontalHeaders[2] + " " + verticalHeaders[5]}>{calculateAaBedTotal()}</td>
                                <td headers={horizontalHeaders[3] + " " + verticalHeaders[5]}>{calculateABedTotal()}</td>
                                <td headers={horizontalHeaders[4] + " " + verticalHeaders[5]}>{calculateBBedTotal()}</td>
                                <td headers={horizontalHeaders[5] + " " + verticalHeaders[5]}>{calculateCBedTotal()}</td>
                                <td headers={horizontalHeaders[6] + " " + verticalHeaders[5]}>{calculateDBedTotal()}</td>
                                <td headers={horizontalHeaders[7] + " " + verticalHeaders[5]}>{calculateTotal()}</td>
                            </tr>
                        </tbody>
                    </table>}
            </div>
        </>
    )
}
