import React from 'react';
import "./GuidedExperienceWizard.css";
import Input from '../common/Input/Input';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/root';
import { useTranslation } from 'react-i18next';
import { GetElsLookupsState } from '../../redux/reducers/getLookups/getElsLookupsReducer';

interface FloorWizardProps {
    updateWizardFunction : (e: React.ChangeEvent<HTMLInputElement>) => void;
    wizardNumber: Record<string, unknown>;
}

export default function FloorWizard(props: FloorWizardProps): JSX.Element {
    const { t } = useTranslation("guided_wizard");
    const elsLookupsState: GetElsLookupsState = useSelector((state: RootState) => state.lookupsReducer.getElsLookups);

    return (<div>
        <p>{t("floor-wizard.floor-description")}</p>
        <table className='wizard-table'>
            <thead>
                <tr>
                    <th className='small-column'>{t("unit-heading.floor-level")}</th>
                    <th>{t("unit-heading.number-of-units")}</th>
                </tr>
            </thead>
            <tbody>
                {(elsLookupsState.dropdowns.floorLevel as Record<string, unknown>[]).filter((floorLevelOption) => floorLevelOption["activeFlag"] === "Y").map((floorLevelOption, index) => (
                    <tr key={`floor-wizard-row-${index}`}>
                        <td><label htmlFor={`wizard-floor-${floorLevelOption["unitLevelCode"] as string}`}>{floorLevelOption["unitLevelDescription"] as string}</label></td>
                        <td><Input type='text' id={`wizard-floor-${floorLevelOption["unitLevelCode"] as string}`} name={`wizard-floor-${floorLevelOption["unitLevelCode"] as string}`} 
                            onChange={props.updateWizardFunction} value={(props.wizardNumber[floorLevelOption["unitLevelCode"] as string] as number).toString()} /></td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>)
}