import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { numberPerPageOptions } from "../../../assets/data/pagination/pagination";
import Button from "../Button/Button";
import Dropdown from "../Dropdown/Dropdown";
import './DataTable.css';

interface DataTableProps {
    title?: string,
    titleEle?: JSX.Element,
    headings: JSX.Element[],                   // Array of <th> or <tr>
    multiLineHeading?: boolean,                // Set to true if heading is an array of <tr>
    className?: string,
    tableContainerClassName?: string,
    children?: JSX.Element,
    // properties below are used for pagination
    totalPages?: number,                       // Calculated in parent component
    currentPage?: number,                      // Bind to the "current page state" in the parent component
    numberPerPage?: number,                    // Bind to the "number per page state" in the parent component
    changePage?: (e: number) => void           // Bind to the "current page state setter" in the parent component
    changeNumberPerPage?: (e: number) => void, // Bind to the "number per page state setter" in the parent componnet
    pageTiles?: number                         // Determines the number of page tiles before and after current page
    
}

function DataTable(props: DataTableProps): ReactElement {

    const {headings, multiLineHeading, className, children, totalPages, currentPage, numberPerPage, changeNumberPerPage, changePage, pageTiles} = props;

    const { t } = useTranslation("table");
    const displayPageButtons = () => {
        if (totalPages !== undefined && currentPage && changePage){
            const tiles = (pageTiles === undefined || pageTiles < 3) ? 4 : pageTiles;
            const buttons = [];
            buttons.push(<Button
                        key="pier-table-page-button-previous"
                        onClick={() => changePage(Math.max(currentPage - 1, 1))}
                        disabled={currentPage === 1 || totalPages === 0} 
                        type={(currentPage === 1 || totalPages === 0) ? "disabled" : "cancel"}
                        testId="pier-table-previous-test"
                        text={t("previous") as string}
                        forceEnabled={true}
                    />);
            if (totalPages === 0) {
                buttons.push(
                    <Button 
                        key="pier-table-page-button-1"
                        disabled={true} 
                        type="disabled" 
                        text="1" 
                        testId="pier-table-1-test" 
                    />
                );
            }
            if (currentPage > totalPages && totalPages > 0) {
                changePage(1);
            }
            if (currentPage > (tiles + 1)) {
                buttons.push(
                    <Button
                        key="pier-table-page-button-1"
                        onClick={() => changePage(1)} 
                        type="cancel" 
                        testId="pier-table-1-test" 
                        text="1"
                        forceEnabled={true}
                    />
                );
                buttons.push(
                    <Button
                        key="pier-table-page-button-front-pages" 
                        className="pier-table-page-button-font-back-pages" 
                        disabled={true} 
                        type="cancel"
                        text="..."
                    />
                );
            }
            let frontTiles = tiles;
            let backTiles = tiles;
            if (currentPage > (tiles + 1)) {
                frontTiles -= 2;
            }
            if (currentPage < (totalPages - tiles)) {
                backTiles -= 2;
            }
            for (let i = Math.max(1, Math.min(currentPage, totalPages) - frontTiles); i <= Math.min(currentPage + backTiles, totalPages); i++) {
                buttons.push(
                    <Button
                        key={`pier-table-page-button-${i}`}
                        type={(i === currentPage) ? "submit" : "cancel"} 
                        disabled={(i === currentPage)}
                        onClick={() => changePage(i)}
                        testId={`pier-table-${i}-test`}
                        text={(i).toString()}
                        forceEnabled={!(i === currentPage)}
                    />
                );
            }
            if (currentPage < (totalPages - tiles)) {
                buttons.push(
                    <Button
                        key="pier-table-page-button-back-pages"
                        className="pier-table-page-button-font-back-pages"
                        disabled={true} 
                        type="cancel" 
                        text="..."
                    />
                );
                buttons.push(
                    <Button
                        key={`pier-table-page-button-${totalPages}`}
                        type="cancel" 
                        onClick={() => changePage(totalPages)}
                        testId={`pier-table-${totalPages}-test`} 
                        text={totalPages.toString()}
                        forceEnabled={true}
                    />
                );
            }
            buttons.push(<Button
                key="pier-table-page-button-next"
                onClick={() => changePage(Math.min(totalPages, currentPage + 1))}
                disabled={currentPage === totalPages || totalPages === 0} 
                type={(currentPage === totalPages || totalPages === 0) ? "disabled" : "cancel"} 
                testId="pier-table-next-test" 
                text={t("next") as string}
                forceEnabled={!(currentPage === totalPages || totalPages === 0)}
            />);
            return buttons;
        }
        return null;
    }

    return (
        <>
            <div className="pier-datatable-container">
                {props.title && 
                    <div className="pier-datatable-title-container">
                        <span>{props.title}</span>
                    </div>
                }

                {props.titleEle && 
                    props.titleEle
                }
                <div className={props.tableContainerClassName}>
                <table className={"pier-datatable" + (className ? " " + className : "")}>
                    <thead>
                        {multiLineHeading ? headings.map((heading) => {
                            return(heading)                     
                        }) : <tr>
                            {headings.map((heading) => {
                                return(heading)                     
                            })}
                        </tr>}
                        
                    </thead>
                    <tbody>
                        {children}
                    </tbody>
                </table>
                </div>
                {(totalPages !== undefined && currentPage && changePage) && <div className="pier-datatable-pagination">
                    <div>
                        {displayPageButtons()}
                    </div>
                    {(numberPerPage && changeNumberPerPage) && 
                        <Dropdown forceEnabled={true} options={numberPerPageOptions} value={numberPerPage.toString()} onChange={(e) => changeNumberPerPage(parseInt((e.target as HTMLSelectElement).value))} ariaLabel={t("per-page") as string} testid="per-page-dropdown" />
                    }
                </div>}
            </div>
        </>
    );
}

export default DataTable;