import React, { useEffect, useState } from 'react';
import { GetElsLookupsState } from '../../redux/reducers/getLookups/getElsLookupsReducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/root';
import "../GuidedExperience/GuidedExperienceWizard.css";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import Button from '../common/Button/Button';
import ApartmentStyleWizard from './ApartmentStyleWizard';
import ApartmentUnitsWizard from './ApartmentUnits';
import OtherUnitsWizard from './OtherUnits';
import NumberOfParkingsWizard from './NumberOfParkingsWizard';
import ParkingRateWizard from './ParkingRateWizard';
import { toast } from 'react-toastify';
import MultiresSummaryWizard from './MultiresSummaryWizard';
import { ProfileAttributesState } from '../../redux/reducers/getProfile/profileAttributes/getAttributesReducer';
import { getProfileAttributes, putProfileAttributes, resetGetProfileAttributes } from '../../redux/actions/profile/profile';
import { ProfileOverviewState } from '../../redux/reducers/getProfile/getProfileOverviewReducer';
import { SaveResultState } from '../../redux/reducers/getSaveData/saveResultReducer';
import LoadingSpinner from '../common/LoadingSpinner/LoadingSpinner';
import AllUnitsWizard from './AllUnits';
import { postMultipleIncomeUnits } from '../../redux/actions/incomeUnit/incomeUnit';
import { createUnitState } from '../../redux/reducers/getIncomeUnit/createUnitReducer';
import ReactGA from 'react-ga4';


interface MultiresWizardProps {
    snapshotId: number;
    onCancel: () => void;
}

export default function MultiresWizard(props: MultiresWizardProps): JSX.Element {
    const { t } = useTranslation("guided_wizard");
    const dispatch = useDispatch();
    const [currentWizardPage, setCurrentWizardPage] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const [attributesRequested, setAttributesRequested] = useState(false);
    const [parkingAssigned, setParkingAssigned] = useState(false);
    const propertyType: "multires" | "landLease" | undefined = localStorage.getItem("propertyCode") ? (["307", "382"].includes(localStorage.getItem("propertyCode") as string) ? "landLease" : "multires") : undefined
    const elsLookupsState: GetElsLookupsState = useSelector((state: RootState) => state.lookupsReducer.getElsLookups);
    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview)
    const profileAttributes: ProfileAttributesState = useSelector((state: RootState) => state.profileReducer.getProfileAttributes as ProfileAttributesState)
    const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult)
    const createUnitsState: createUnitState = useSelector((state: RootState) => state.incomeUnitReducer.postIncomeUnit)
    const apartmentStyles = elsLookupsState.dropdowns ? (elsLookupsState.dropdowns["unitStyleType"] as Record<string, unknown>[]).filter((apartmentStyle) => apartmentStyle.activeFlag === 'Y').sort((a, b) => 
        (a.unitStylePosition as number) - (b.unitStylePosition as number)) : [];
    const multiresUnitSubtype = elsLookupsState.dropdowns ? (elsLookupsState.dropdowns["multiresSubType"] as Record<string, unknown>[]).sort((a, b) => (a.unitSubtypePosition as number) - (b.unitSubtypePosition as number)) : [];
    const multiresRowHouseUnitSubtype = elsLookupsState.dropdowns ? (elsLookupsState.dropdowns["multiresRowHouseSubType"] as Record<string, unknown>[]).sort((a, b) => (a.unitSubtypePosition as number) - (b.unitSubtypePosition as number)): [];
    const landLeaseUnitSubtype = elsLookupsState.dropdowns ? (elsLookupsState.dropdowns["landLeaseSubType"] as Record<string, unknown>[]).sort((a, b) => (a.unitSubtypePosition as number) - (b.unitSubtypePosition as number)) : [];
    const [apartmentStyleArray, setApartmentStyleArray] = useState(apartmentStyles.reduce((apartmentStyleRecord, apartmentStyleOption)=> ({[apartmentStyleOption["unitStyleCode"] as string]: 
        false, ...apartmentStyleRecord}),{}));
    const [apartmentNumber, setApartmentNumber] = useState(apartmentStyles.reduce((apartmentStyleRecord, apartmentStyleOption)=> ({[apartmentStyleOption["unitStyleCode"] as string]: 
        (propertyType === "landLease" ? landLeaseUnitSubtype : (apartmentStyleOption["unitStyleCode"] === apartmentStyles[0]["unitStyleCode"] ?  multiresUnitSubtype : multiresRowHouseUnitSubtype)
        ).reduce((unitSubtypeRecord, unitSubtype3Option)=> ({[unitSubtype3Option["unitSubtypeCode"] as string]: 
            0, ...unitSubtypeRecord}),{}), ...apartmentStyleRecord}),{}));
    const [parkings, setParkings] = useState<Record<string, unknown>>({
        'residentialParkingTotal': 0,
        'residentialSurfaceParkingTotal': 0,
        'residentialSurfaceParkingNotInRent': 0,
        'residentialSurfaceParkingMonthlyRate': null,
        'residentialSurfaceParkingInRent': 0,
        'residentialIndoorParkingTotal': 0,
        'residentialIndoorParkingNotInRent': 0,
        'residentialIndoorParkingMonthlyRate': null,
        'residentialIndoorParkingInRent': 0,
    });
    const aptTypeMap = {
        "apt": ["301", "302", "303", "304", "305", "306", "307", "308", "309", "343", "352"],
        "bst": ["310", "311", "312", "313", "314", "315"],
        "pen": ["327", "328", "329", "330", "331", "332", "333", "334"],
        "lrg": ["317", "318", "319", "320", "321", "322", "323", "324"],
        "sml": ["344", "345", "346", "347", "348", "349", "350", "351"]
    }

    useEffect(() => {
        dispatch(resetGetProfileAttributes());
        dispatch(getProfileAttributes(profileOverview.profile.profile_type_code, props.snapshotId));
        setAttributesRequested(true);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!parkingAssigned && attributesRequested && !profileAttributes.loading && profileAttributes.success) {
            const newParkings = {...parkings};
            newParkings["residentialIndoorParkingInRent"] = profileAttributes.data.residentialIndoorParkingInRent ?? 0;
            newParkings["residentialIndoorParkingNotInRent"] = profileAttributes.data.residentialIndoorParkingNotInRent ?? 0;
            newParkings["residentialIndoorParkingMonthlyRate"] = profileAttributes.data.residentialIndoorParkingMonthlyRate;
            newParkings["residentialIndoorParkingTotal"] = (newParkings["residentialIndoorParkingInRent"] as number) + (newParkings["residentialIndoorParkingNotInRent"] as number);
            newParkings["residentialSurfaceParkingInRent"] = profileAttributes.data.residentialSurfaceParkingInRent ?? 0;
            newParkings["residentialSurfaceParkingNotInRent"] = profileAttributes.data.residentialSurfaceParkingNotInRent ?? 0;
            newParkings["residentialSurfaceParkingMonthlyRate"] = profileAttributes.data.residentialSurfaceParkingMonthlyRate;
            newParkings["residentialSurfaceParkingTotal"] = (newParkings["residentialSurfaceParkingInRent"] as number) + (newParkings["residentialSurfaceParkingNotInRent"] as number);
            newParkings["residentialParkingTotal"] = (newParkings["residentialSurfaceParkingTotal"] as number) + (newParkings["residentialIndoorParkingTotal"] as number);
            setParkings(newParkings);
            setParkingAssigned(true);
        }
    }, [profileAttributes, attributesRequested, parkingAssigned, parkings])

    useEffect(() => {
        if (propertyType === undefined) {
            toast.error("Error getting property code, wizard closed");
            props.onCancel()
        }
        // eslint-disable-next-line
    }, [propertyType]);

    useEffect(() => {
        if (!saveResultState.loading && !createUnitsState.loading && submitted) {
            setSubmitted(false);
            props.onCancel();
        }
        // eslint-disable-next-line
    }, [saveResultState, createUnitsState, submitted]);

    const updateApartmentNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const nameArr = e.target.name.split("-");
        if (nameArr.length === 4) {
            const styleCode = nameArr[2];
            const typeCode = nameArr[3];
            const numberOnlyRegex = /^[0-9]+$/;
            if (numberOnlyRegex.test(value)) {
                (apartmentNumber[styleCode] as Record<string, unknown>)[typeCode] = parseInt(value);
                setApartmentNumber({...apartmentNumber});
            } else if (value === "") {
                (apartmentNumber[styleCode] as Record<string, unknown>)[typeCode] = 0;
                setApartmentNumber({...apartmentNumber});
                e.target.value = "0";
            } else if (styleCode in apartmentNumber && typeCode in (apartmentNumber[styleCode] as Record<string, unknown>)) {
                e.target.value = ((apartmentNumber[styleCode] as Record<string, unknown>)[typeCode] as number).toString();
            }
        }
    }

    const updateParking= (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const name = e.target.name;
        const numberOnlyRegex = /^[0-9]+$/;
        const newParkings = {...parkings};
        let validated = false;
        let warn = false;
        if (numberOnlyRegex.test(value)) {
            newParkings[name] = parseInt(value);
            if (name === "residentialParkingTotal"){
                if (newParkings["residentialParkingTotal"] as number < ((newParkings["residentialSurfaceParkingTotal"] as number) + (newParkings["residentialIndoorParkingTotal"] as number))) {
                    newParkings['residentialSurfaceParkingTotal'] = 0;
                    newParkings['residentialSurfaceParkingNotInRent'] = 0;
                    newParkings['residentialSurfaceParkingMonthlyRate'] = null;
                    newParkings['residentialSurfaceParkingInRent'] = 0;
                    newParkings['residentialIndoorParkingTotal'] = 0;
                    newParkings['residentialIndoorParkingNotInRent'] = 0;
                    newParkings['residentialIndoorParkingMonthlyRate'] = null;
                    newParkings['residentialIndoorParkingInRent'] = 0;
                    warn = true;
                }
                if (newParkings["residentialParkingTotal"] as number > 0) {
                    newParkings["residentialSurfaceParkingTotal"] = (newParkings['residentialParkingTotal'] as number) - (newParkings["residentialIndoorParkingTotal"] as number);
                    newParkings["residentialSurfaceParkingInRent"] = (newParkings['residentialSurfaceParkingTotal'] as number) - (newParkings["residentialSurfaceParkingNotInRent"] as number);
                    newParkings["residentialIndoorParkingInRent"] = (newParkings['residentialIndoorParkingTotal'] as number) - (newParkings["residentialIndoorParkingNotInRent"] as number);
                }
                validated = true;
            } else if (name === "residentialIndoorParkingTotal" && ((newParkings["residentialParkingTotal"] as number) >= (newParkings["residentialIndoorParkingTotal"] as number))) {
                if (newParkings["residentialIndoorParkingTotal"] as number < ((newParkings["residentialIndoorParkingNotInRent"] as number) + (newParkings["residentialIndoorParkingInRent"] as number))) {
                    newParkings['residentialIndoorParkingNotInRent'] = 0;
                    newParkings['residentialIndoorParkingMonthlyRate'] = null;
                    newParkings['residentialIndoorParkingInRent'] = 0;
                    warn = true;
                } 
                newParkings["residentialIndoorParkingInRent"] = (newParkings['residentialIndoorParkingTotal'] as number) - (newParkings["residentialIndoorParkingNotInRent"] as number);
                newParkings["residentialSurfaceParkingTotal"] = (newParkings['residentialParkingTotal'] as number) - (newParkings["residentialIndoorParkingTotal"] as number);
                if (newParkings["residentialSurfaceParkingTotal"] as number < ((newParkings["residentialSurfaceParkingNotInRent"] as number) + (newParkings["residentialSurfaceParkingInRent"] as number))) {
                    newParkings['residentialSurfaceParkingNotInRent'] = 0;
                    newParkings['residentialSurfaceParkingMonthlyRate'] = null;
                    newParkings['residentialSurfaceParkingInRent'] = 0;
                    // warn = true;
                } 
                newParkings["residentialSurfaceParkingInRent"] = (newParkings['residentialSurfaceParkingTotal'] as number) - (newParkings["residentialSurfaceParkingNotInRent"] as number);
                validated = true;
            } else if (name === "residentialIndoorParkingNotInRent" && ((newParkings["residentialIndoorParkingTotal"] as number) >= (newParkings["residentialIndoorParkingNotInRent"] as number))) {
                if (newParkings[name] === 0) {
                    newParkings['residentialIndoorParkingMonthlyRate'] = null;
                    warn = true;
                }
                newParkings['residentialIndoorParkingInRent'] = (newParkings['residentialIndoorParkingTotal'] as number) - (newParkings["residentialIndoorParkingNotInRent"] as number);
                validated = true;
            } else if (name === "residentialSurfaceParkingNotInRent" && ((newParkings["residentialSurfaceParkingTotal"] as number) >= (newParkings["residentialSurfaceParkingNotInRent"] as number))) {
                if (newParkings[name] === 0) {
                    newParkings['residentialSurfaceParkingMonthlyRate'] = null;
                    warn = true;
                }
                newParkings['residentialSurfaceParkingInRent'] = (newParkings['residentialSurfaceParkingTotal'] as number) - (newParkings["residentialSurfaceParkingNotInRent"] as number);
                validated = true;
            } 
        } else if (value === "") {
            newParkings[name] = 0;
            e.target.value = "0";
            validated = true
        } 
        if (!validated) {
            e.target.value = (parkings[name] as number).toString();
        } else {
            setParkings(newParkings);
        }
        if (warn) {
            toast.warn(t("data-reset") as string);
        }
    }

    const calculteFinalSuites = () : Record<string, unknown> => {
        const finalSuites: Record<string, unknown> = {};
        let totalSuites = 0;
        if (propertyType === "multires") {
            aptTypeMap.apt.forEach((typeCode, index) => {
                if (typeCode !== "309" && typeCode !== "343" && typeCode !== "352") {
                    const finalCount = 
                    Math.max(0, (((apartmentNumber[apartmentStyles[0]["unitStyleCode"] as string] as Record<string, unknown>)[typeCode] as number) 
                    - ((typeCode === "306" || typeCode === "307") ? 0 : (typeCode === "308" ? ((apartmentNumber[apartmentStyles[0]["unitStyleCode"] as string] as Record<string, unknown>)["315"] as number) 
                    : ((apartmentNumber[apartmentStyles[0]["unitStyleCode"] as string] as Record<string, unknown>)[aptTypeMap.bst[index]] as number)))
                    - ((apartmentNumber[apartmentStyles[0]["unitStyleCode"] as string] as Record<string, unknown>)[aptTypeMap.pen[index]] as number)
                    - ((apartmentNumber[apartmentStyles[0]["unitStyleCode"] as string] as Record<string, unknown>)[aptTypeMap.lrg[index]] as number)
                    - ((apartmentNumber[apartmentStyles[0]["unitStyleCode"] as string] as Record<string, unknown>)[aptTypeMap.sml[index]] as number)));
                    if (finalCount > 0) {
                        if (!((apartmentStyles[0]["unitStyleCode"] as string) in finalSuites)) {
                            finalSuites[apartmentStyles[0]["unitStyleCode"] as string] = {};
                        }
                        totalSuites += finalCount;
                        (finalSuites[apartmentStyles[0]["unitStyleCode"] as string] as Record<string, unknown>)[typeCode] = finalCount
                    }
                }
            });
        }
        for (const [key, unitObject] of Object.entries(apartmentNumber)) {
            for (const [subKey, numberOfUnits] of Object.entries(unitObject as Record<string, unknown>)) {
                if ((propertyType !== "multires" || aptTypeMap.apt.indexOf(subKey) < 0 || ["309", "343", "352"].includes(subKey) || key !== apartmentStyles[0]["unitStyleCode"]) && (numberOfUnits as number) > 0) {
                    if (!(key in finalSuites)) {
                        finalSuites[key] = {};
                    }
                    (finalSuites[key] as Record<string, unknown>)[subKey] = numberOfUnits;
                    totalSuites += numberOfUnits as number;
                }
            }
        }
        return {"total": totalSuites, "finalSuites": finalSuites};
    }

    const commonWizardElements = [
        <NumberOfParkingsWizard key="wizard-total-number-of-parkings" type="residential-parking-total" propertyName="residentialParkingTotal" parkings={parkings} updateParking={updateParking} />,
        <NumberOfParkingsWizard key="wizard-total-number-of-parkings-indoor" totalPropertyNameFromThePreviousStep="residentialParkingTotal" type="residential-indoor-parking-total" propertyName="residentialIndoorParkingTotal" parkings={parkings} updateParking={updateParking} />,
        <NumberOfParkingsWizard key="wizard-number-of-parkings-indoor-not-in-rent" totalPropertyNameFromThePreviousStep="residentialIndoorParkingTotal" type="residential-indoor-parking-not-in-rent" propertyName="residentialIndoorParkingNotInRent" parkings={parkings} updateParking={updateParking} />,
        <ParkingRateWizard key="wizard-parkings-indoor-rate" totalPropertyNameFromThePreviousStep="residentialIndoorParkingNotInRent" type="residential-indoor-parking-monthly-rate" propertyName="residentialIndoorParkingMonthlyRate" parkings={parkings} setParkings={setParkings} />,
        <NumberOfParkingsWizard key="wizard-number-of-parkings-surface-not-in-rent" totalPropertyNameFromThePreviousStep="residentialSurfaceParkingTotal" type="residential-surface-parking-not-in-rent" propertyName="residentialSurfaceParkingNotInRent" parkings={parkings} updateParking={updateParking} />,
        <ParkingRateWizard key="wizard-parkings-surface-rate" totalPropertyNameFromThePreviousStep="residentialSurfaceParkingNotInRent" type="residential-surface-parking-monthly-rate" propertyName="residentialSurfaceParkingMonthlyRate" parkings={parkings} setParkings={setParkings} />,
        <MultiresSummaryWizard key="wizard-multires-summary" parkings={parkings} calculteFinalSuites={calculteFinalSuites} />
    ];
    
    const orderedWizardElements = (propertyType === "landLease") ? 
    [
        <AllUnitsWizard key="wizard-all-units" subtypeList={landLeaseUnitSubtype} apartmentStyles={apartmentStyles} apartmentNumber={apartmentNumber} updateApartmentNumber={updateApartmentNumber} />,
        ...commonWizardElements
    ] :
    [
        <ApartmentStyleWizard key="wizard-apartment-style" apartmentStyles={apartmentStyles} apartmentStyleArray={apartmentStyleArray} setApartmentStyleArray={setApartmentStyleArray} />,
        <ApartmentUnitsWizard key="wizard-apartment-units-apt" questionType='apt' apartmentStyles={apartmentStyles} multiresUnitSubtype={multiresUnitSubtype} apartmentNumber={apartmentNumber} updateApartmentNumber={updateApartmentNumber} aptTypeMap={aptTypeMap} />,
        <ApartmentUnitsWizard key="wizard-apartment-units-bst" questionType='bst' apartmentStyles={apartmentStyles} multiresUnitSubtype={multiresUnitSubtype} apartmentNumber={apartmentNumber} updateApartmentNumber={updateApartmentNumber} aptTypeMap={aptTypeMap} />,
        <ApartmentUnitsWizard key="wizard-apartment-units-pen" questionType='pen' apartmentStyles={apartmentStyles} multiresUnitSubtype={multiresUnitSubtype} apartmentNumber={apartmentNumber} updateApartmentNumber={updateApartmentNumber} aptTypeMap={aptTypeMap} />,
        <ApartmentUnitsWizard key="wizard-apartment-units-lrg" questionType='lrg' apartmentStyles={apartmentStyles} multiresUnitSubtype={multiresUnitSubtype} apartmentNumber={apartmentNumber} updateApartmentNumber={updateApartmentNumber} aptTypeMap={aptTypeMap} />,
        <ApartmentUnitsWizard key="wizard-apartment-units-sml" questionType='sml' apartmentStyles={apartmentStyles} multiresUnitSubtype={multiresUnitSubtype} apartmentNumber={apartmentNumber} updateApartmentNumber={updateApartmentNumber} aptTypeMap={aptTypeMap} />,
        <OtherUnitsWizard key="wizard-other-units" apartmentStyles={apartmentStyles} multiresRowHouseUnitSubtype={multiresRowHouseUnitSubtype} apartmentNumber={apartmentNumber} updateApartmentNumber={updateApartmentNumber} aptTypeMap={aptTypeMap} />,
        ...commonWizardElements
    ]

    const pagesToGoThrough = (advancing: boolean) : number => {
        let pagesToAdvance = 1;
        let checkingCondition = true;
        while (checkingCondition) {
            checkingCondition = false;
            if (orderedWizardElements[advancing ? (currentWizardPage + pagesToAdvance) : (currentWizardPage - pagesToAdvance)].key === "wizard-total-number-of-parkings-indoor" && parkings["residentialParkingTotal"] === 0) {
                pagesToAdvance += 1;
                checkingCondition = true;
            } 
            if (orderedWizardElements[advancing ? (currentWizardPage + pagesToAdvance) : (currentWizardPage - pagesToAdvance)].key === "wizard-number-of-parkings-indoor-not-in-rent" && parkings["residentialIndoorParkingTotal"] === 0) {
                pagesToAdvance += 1;
                checkingCondition = true;
            } 
            if (orderedWizardElements[advancing ? (currentWizardPage + pagesToAdvance) : (currentWizardPage - pagesToAdvance)].key === "wizard-parkings-indoor-rate" && parkings["residentialIndoorParkingNotInRent"] === 0) {
                pagesToAdvance += 1;
                checkingCondition = true;
            } 
            if (orderedWizardElements[advancing ? (currentWizardPage + pagesToAdvance) : (currentWizardPage - pagesToAdvance)].key === "wizard-number-of-parkings-surface-not-in-rent" && parkings["residentialSurfaceParkingTotal"] === 0) {
                pagesToAdvance += 1;
                checkingCondition = true;
            } 
            if (orderedWizardElements[advancing ? (currentWizardPage + pagesToAdvance) : (currentWizardPage - pagesToAdvance)].key === "wizard-parkings-surface-rate" && parkings["residentialSurfaceParkingNotInRent"] === 0) {
                pagesToAdvance += 1;
                checkingCondition = true;
            } 
        }
        return pagesToAdvance;
    }

    const previousWizardPage = () => {
        if (orderedWizardElements[currentWizardPage - 1].key === "wizard-other-units" && !apartmentStyleArray["2"] && !apartmentStyleArray["3"]) {
            setCurrentWizardPage(currentWizardPage - ((!apartmentStyleArray["1"]) ? 7 : 2));
        } 
        else if (orderedWizardElements[currentWizardPage - 1].key === "wizard-apartment-units-sml" && !apartmentStyleArray["1"]) {
            setCurrentWizardPage(currentWizardPage - 6);
        } else {
            setCurrentWizardPage(currentWizardPage - pagesToGoThrough(false));
        }
    }

    const nextWizardPage = () => {
        if (orderedWizardElements[currentWizardPage].key === "wizard-apartment-style" && !apartmentStyleArray["1"]) {
            setCurrentWizardPage(currentWizardPage + ((!apartmentStyleArray["2"] && !apartmentStyleArray["3"]) ? 7 : 6));
        } 
        else if (orderedWizardElements[currentWizardPage + 1].key === "wizard-other-units" && !apartmentStyleArray["2"] && !apartmentStyleArray["3"]) {
            setCurrentWizardPage(currentWizardPage + 2);
        } else {
            setCurrentWizardPage(currentWizardPage + pagesToGoThrough(true));
        }
    }

    const submitMultiresData = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        // ReactGA event to track multiRes wizard completion
        ReactGA.event({
            category: 'MultiRes Guided Wizard',
            action: 'MultiRes Guided Wizard',
            label: 'Save - wizard completed'
        });
        console.log('Triggered MultiRes Save event')
        const finalSuites = calculteFinalSuites().finalSuites;
        const generated: Record<string, unknown>[] = []

        for (const [suiteType, numberObject] of Object.entries(finalSuites as Record<string, unknown>)) {
            for (const [typeCode, amt] of Object.entries(numberObject as Record<string, unknown>)) {
                if (localStorage.getItem("propertyCode") && ["470", "472", "478", "340", "341", "352", "361"].includes(localStorage.getItem("propertyCode") as string)) {
                    const newUnit = {
                        unitTypeCode: '3',
                        unitSubtypeCode: typeCode,
                        styleTypeCode: suiteType,
                        unitCount: amt,
                        bathCount: 1,
                        netLeasableArea: '',
                        monthlyMarketRent: 0,
                        new: true
                    }
                    generated.push(newUnit) 
                } else {
                    for (let i = 0; i < (amt as number); i++) {
                        const newUnit = {
                            unitTypeCode: '3',
                            unitSubtypeCode: typeCode,
                            styleTypeCode: suiteType,
                            bathCount: 1,
                            netLeasableArea: '',
                            monthlyMarketRent: 0,
                            new: true
                        }
                        generated.push(newUnit)
                    }
                }
            }
        }
        const finalParkings = {
            'residentialSurfaceParkingNotInRent': parkings['residentialSurfaceParkingNotInRent'] === 0 ? null : parkings['residentialSurfaceParkingNotInRent'],
            'residentialSurfaceParkingMonthlyRate': parkings['residentialSurfaceParkingMonthlyRate'],
            'residentialSurfaceParkingInRent': parkings['residentialSurfaceParkingInRent'] === 0 ? null : parkings['residentialSurfaceParkingInRent'],
            'residentialIndoorParkingNotInRent': parkings['residentialIndoorParkingNotInRent'] === 0 ? null : parkings['residentialIndoorParkingNotInRent'],
            'residentialIndoorParkingMonthlyRate': parkings['residentialIndoorParkingMonthlyRate'],
            'residentialIndoorParkingInRent': parkings['residentialIndoorParkingInRent'] === 0 ? null : parkings['residentialIndoorParkingInRent']
        }
        dispatch(postMultipleIncomeUnits(props.snapshotId, generated, 'a'))
        const fulldata = { ...profileAttributes.data, ...finalParkings } as Record<string, unknown>
        dispatch(putProfileAttributes(profileOverview.profile.profile_type_code, props.snapshotId, fulldata));
        setSubmitted(true);
    }

    const cancelWizard = () => {
        // ReactGA event to track MultiRes wizard cancellation
        ReactGA.event({
            category: 'MultiRes Guided Wizard',
            action: 'MultiRes Guided Wizard',
            label: `Cancel on Step: ${currentWizardPage + 1}`
        });
        console.log('Triggered MultiRes Cancel event')
        props.onCancel();
    }
    return (
        <LoadingSpinner loading={saveResultState.loading || profileAttributes.loading || !props.snapshotId}>
            <div className='guided-experience-wizard multires-wizard'>
                <div>
                    <p>{t("step-1") + (currentWizardPage + 1) + t("step-2") + orderedWizardElements.length}</p>
                    {orderedWizardElements[currentWizardPage]}
                </div>
                <div className='guided-experience-wizard-buttons'>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <Button type='cancel' onClick={cancelWizard} >
                                        {t("cancel").toLocaleUpperCase(i18n.language)}
                                    </Button>
                                </td>
                                <td>
                                    {currentWizardPage !== 0 && <Button type='secondary' onClick={() => previousWizardPage()} >
                                        {t("back").toLocaleUpperCase(i18n.language)}
                                    </Button> }
                                </td>
                                <td>
                                    {currentWizardPage !== (orderedWizardElements.length - 1) && <Button type='secondary' onClick={() => nextWizardPage()} >
                                        {t("next").toLocaleUpperCase(i18n.language)}
                                    </Button> }
                                </td>
                                <td>
                                    {currentWizardPage === (orderedWizardElements.length - 1) && <Button type='submit' onClick={submitMultiresData} >
                                        {t("submit").toLocaleUpperCase(i18n.language)}
                                    </Button> }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </LoadingSpinner>
    )
}