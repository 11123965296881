import React, {  useState } from 'react';
import "./GuidedExperienceWizard.css";
import { useTranslation } from 'react-i18next';
import DataTable from '../common/DataTable/DataTable';
import { format } from 'date-fns';
import StepDate from '../unit/StepDate';

interface StepUpDetailsWizardProps {
    updateWizardFunction : (e: string | undefined, i: number, f: string, n?: boolean) => void;
    tenantList: Record<string, unknown>[];
    floorDescriptionLookup: Record<string, unknown>;
    stepUpArray: number[];
}

export default function StepUpDetailsWizard(props: StepUpDetailsWizardProps): JSX.Element {
    const { t } = useTranslation("guided_wizard");
    const [currentPage, setCurrentPage] = useState(1);

    const handleDateChange = (date: Date | null | undefined, name: string) => {
        if (typeof name !== "string") {
            return;
        }
        if (date) {
            const dateStr = format(date, "yyyy-MM-dd")
            props.updateWizardFunction(dateStr, props.stepUpArray[currentPage - 1], name);
        } else {
            props.updateWizardFunction(undefined, props.stepUpArray[currentPage - 1], name);
        }
    };

    const handleCurrencyChange = (value: number | undefined | null, name: string, _oldAmt: number | undefined | null) => {
        props.updateWizardFunction((value === null || value === undefined) ? undefined : value.toString(), props.stepUpArray[currentPage - 1], name, true);
    };

    const headings = [<th className='small-column' key="step-up-details-wizard-heading-1" colSpan={2} id='wizard-floor'>{t("unit-heading.floor-level")}</th>,
        <th key="step-up-details-wizard-heading-2" colSpan={2} id='wizard-name'>{t("unit-heading.tenant-name")}</th>,
        <th key="step-up-details-wizard-heading-3" colSpan={2} id='wizard-unit'>{t("unit-heading.unit-number")}</th>]

    return (<div>
        <p>{t("step-up-wizard.step-up-description")}</p>
        {props.stepUpArray.length > 0 && <DataTable headings={headings} className='wizard-table' totalPages={props.stepUpArray.length} currentPage={currentPage} changePage={setCurrentPage}>
            <>
                <tr>
                    <td className='no-input-cell' colSpan={2} headers='wizard-floor'><label>{props.floorDescriptionLookup[props.tenantList[props.stepUpArray[currentPage - 1]]["floorLevel"] as string] as string}</label></td>
                    <td className='no-input-cell tenant-name-cell' colSpan={2} headers='wizard-name'><p>{props.tenantList[props.stepUpArray[currentPage - 1]]["tenantName"] as string}</p></td>
                    <td className='no-input-cell tenant-unit-cell' colSpan={2} headers='wizard-unit'><p>{props.tenantList[props.stepUpArray[currentPage - 1]]["tenantUnitNumber"] as string}</p></td>
                </tr>
                <StepDate key={`step-up-${props.stepUpArray[currentPage - 1]}`} formData={props.tenantList[props.stepUpArray[currentPage - 1]]} currencySymbol={t("step-up-wizard.step-up-placeholder")}
                    handleCurrencyChange={handleCurrencyChange} handleDateChange={handleDateChange} excludeTBody={true} colspan={3} headers={["wizard-floor", "wizard-name"]}/>
            </>
        </DataTable>}
    </div>)
}