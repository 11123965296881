import React, { createContext, useState, useContext, ReactNode } from 'react';

// As per https://mpacit.atlassian.net/browse/PIER2-484, spectral should be triggered only when user is navigating forward.
// Backward navigation should bypass validation but still save data to magnetar
// This is a global change that will affect all portfolios. 
type Direction = 'forward' | 'backward';

// Define the context shape
interface NavigationContextType {
  direction: Direction;
  setDirection: (direction: Direction) => void;
}

const NavigationContext = createContext<NavigationContextType | undefined>(undefined);

interface NavigationProviderProps {
  children: ReactNode;
}

export const NavigationProvider: React.FC<NavigationProviderProps> = ({ children }) => {
  const [direction, setDirection] = useState<Direction>('forward'); // Default direction is 'forward'

  return (
    <NavigationContext.Provider value={{ direction, setDirection }}>
      {children}
    </NavigationContext.Provider>
  );
};

// Custom hook to use the navigation context
export const useNavigation = (): NavigationContextType => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }
  return context;
};
