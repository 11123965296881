import {
  INTERNAL_IN_PROGRESS,
  // EXTERNAL_SUBMITTED
} from "../redux/actionTypes/actionsTypes"
import { ProfileOverviewState } from "../redux/reducers/getProfile/getProfileOverviewReducer"

export const excludeCampaignYears = [2020]
export const displayPreviousYearsNumber = 4;

export const isCurrentCampaignDisplayed = (profileOverview: ProfileOverviewState) => {
  if (profileOverview && profileOverview.profile && profileOverview.status === 200) {
    const current_campaign = profileOverview.profile.current_campaign
    if (current_campaign) {
      if (localStorage.getItem("isInternalUser") || [
        INTERNAL_IN_PROGRESS,
        // EXTERNAL_SUBMITTED
      ].includes(current_campaign.statusCode as string)) {
        return true
      }
    }
  }
  return false
}

export const isPreviousCampaignListDisplayed = (profileOverview: ProfileOverviewState) => {
  if (profileOverview && profileOverview.profile && profileOverview.status === 200) {
    const previous_campaigns = profileOverview.profile.previous_campaigns
    const excluded_campains = previous_campaigns.filter((c) => !excludeCampaignYears.includes(c.year))
    if (excluded_campains.length > 0) {
      if (localStorage.getItem("isInternalUser")) {
        return true
      } else {
        const valid_previous_campaigns = excluded_campains.filter((c) => c.currentSnapshotId)
        if (valid_previous_campaigns.length > 0) {
          const sorted = valid_previous_campaigns.sort((a, b) => b.year - a.year)
          const latest_campaign = sorted[0]
          const current_campaign = profileOverview.profile.current_campaign
          if (current_campaign && latest_campaign.year >= current_campaign.year - displayPreviousYearsNumber) {
            return true
          }
        }
      }
    }
  }
  return false
}

export const isCampaignListDisplayed = (profileOverview: ProfileOverviewState) => {
  if (profileOverview && profileOverview.profile && profileOverview.status === 200) {
    if (profileOverview.profile.profile_type_code === "GEV") {
      return false
    }
    return isCurrentCampaignDisplayed(profileOverview) || isPreviousCampaignListDisplayed(profileOverview)
  }
  return false
}

export const isThereCampaignToImport = (profileOverview: ProfileOverviewState, campaignYear: number) => {
  if (profileOverview && profileOverview.profile && profileOverview.status === 200) {
    const previous_campaigns = profileOverview.profile.previous_campaigns
    const valid_previous_campaigns = previous_campaigns.filter((c) => c.currentSnapshotId && !excludeCampaignYears.includes(c.year))
    const importFromCampaign = valid_previous_campaigns.find((c) => c.year === (campaignYear - 1))
    return importFromCampaign !== undefined
  }
  return false
}
