import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../redux/reducers/root';
import MenuState from '../../types/menu/MenuState';
import MenuItem from '../../types/menu/MenuItem';
import SidebarItemLarge from '../../components/common/SidebarItemLarge/SidebarItemLarge';
import './Sidebar.css'
import { setNextActiveMenuItem, setPrevActiveMenuItem } from '../../redux/actions/menu/menu';
import ReactGA from 'react-ga4';
import { useNavigation } from '../../utils/context/NavigationContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

interface SidebarProps {
    items: MenuItem[];
    collapsed: boolean;
    handleCollapse: () => void;
    year: string;
    compareId: (a: number, b: number) => string;
}

export interface SidebarItemProps {
    item: MenuItem;
    activeItemId: number;
    isChildActive?: (item: MenuItem, activeItemId: number) => boolean;
    index?: number;
    menuOnClick: (item: MenuItem) => void;
}

function Sidebar(props: SidebarProps) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const menuState = useSelector((state: RootState) => state.menuReducer.setMenu as MenuState);
    const { setDirection } = useNavigation();
    const isInternalUser = localStorage.getItem("isInternalUser");

    const menuOnClick = (menuItem: MenuItem) => {
        ReactGA.send({ hitType: "pageview", page: "#/portal", title: menuItem.name })
        if (props.compareId(menuItem.id, menuState.activeItem.id) === "larger") {
            dispatch(setNextActiveMenuItem(menuItem))
            setDirection('forward');
        }
        else if (props.compareId(menuItem.id, menuState.activeItem.id) === "smaller") {
            dispatch(setPrevActiveMenuItem(menuItem))
            setDirection('backward')
        }
    }

    return (
        <div className='sidebar-raw-root'>
            <div className="sidebar">
                <div className='sidebar-main-section'>

                    {props.collapsed || (
                        <h1 className='sidebar-year' onClick={() => isInternalUser ?
                            navigate('/internal-landing') : navigate('/landing')}>
                            {isInternalUser && "Selected year : "}
                            {localStorage.getItem("activeCampaignYear")}
                        </h1>
                    )}

                    <div className='sidebar-item-container'>
                        {props.items && props.items.map(item => (
                            <SidebarItemLarge
                                key={item.id}
                                item={item}
                                activeItemId={menuState.activeItem.id}
                                menuOnClick={menuOnClick}
                            />
                        ))
                        }
                    </div>
                </div>


                {props.collapsed || (
                    <div className='sidebar-footer-container'>

                        <div className='sidebar-footer-info-container'>
                            <div className='sidebar-logo-image-container'>
                                <img src="/images/MPAC_logo_no_bg.png" alt="MPAC Logo" />
                            </div>
                            <div className='sidebar-mpac-info'>
                                <div className='sidebar-mpac-info-text'>
                                    <div>Copyright 2025 <br /> MPAC. Version 1.0</div>
                                    <a href='https://www.mpac.ca/en/AboutUs/Policies/PrivacyStatement' target='#' title='Opens a new window'>
                                        Privacy Policy <FontAwesomeIcon icon={faArrowUpRightFromSquare} className='external-link-icon-img' />
                                    </a>
                                    <a href='https://www.mpac.ca/en/Contact' target='#' title='Opens a new window'>
                                        Contact us <FontAwesomeIcon icon={faArrowUpRightFromSquare} className='external-link-icon-img' />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </div>

    )
}

export default Sidebar