import React, { useEffect, useState } from 'react';
import "./GuidedExperienceWizard.css";
import { useTranslation } from 'react-i18next';
import DataTable from '../common/DataTable/DataTable';
import CommercialUnitDatePickerField from '../unit/CommercialUnitDatePickerField';
import { format } from 'date-fns';

interface FixEndDateWizardProps {
    updateWizardFunction : (e: React.ChangeEvent<Element> | string | undefined, i: number, f: string, n?: boolean, c?: boolean) => void;
    tenantList: Record<string, unknown>[];
    floorDescriptionLookup: Record<string, unknown>;
    leaseStatusLookup: Record<string, unknown>;
    leaseTypeLookup: Record<string, unknown>;
}

export default function FixEndDateWizard(props: FixEndDateWizardProps): JSX.Element {
    const { t } = useTranslation("guided_wizard");
    const [totalPages, setTotalPages] = useState<number | undefined>(undefined);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [filteredIndices, setFilteredIndices] = useState<number[]>([]);
    const [initiated, setInitiated] = useState(false);

    useEffect(() => {
        if (!initiated) {
            const indices = props.tenantList.map((_, index) => index);
            const filteredIndiceArr = indices.filter((index) => (
                (["2" ,"3", "4"].includes(props.tenantList[index]["leaseStatusCode"] as string) && (!props.tenantList[index]["endDate"] || props.tenantList[index]["endDate"] === ""))
                || ((props.tenantList[index]["leaseStatusCode"] === "1" || props.tenantList[index]["leaseStatusCode"] === "5") && props.tenantList[index]["endDate"])
            ));
            if (filteredIndiceArr.length > 0) {
                setInitiated(true);
            }
            if (filteredIndiceArr.length > 10) {
                setTotalPages(Math.ceil(filteredIndiceArr.length / 10));
            }
            setFilteredIndices(filteredIndiceArr);        }
    }, [props.tenantList, initiated]);

    const handleDateChange = (date: Date | null | undefined, name: string) => {
        if (typeof name !== "string") {
            return;
        }
        if (date) {
            const dateStr = format(date, "yyyy-MM-dd")
            props.updateWizardFunction(dateStr, currentIndex, name);
        } else {
            props.updateWizardFunction(undefined, currentIndex, name);
        }
    };

    const headings = [<th className='small-column' key="fix-end-date-wizard-heading-1">{t("unit-heading.floor-level")}</th>,
        <th key="fix-end-date-wizard-heading-2">{t("unit-heading.tenant-name")}</th>,
        <th key="fix-end-date-wizard-heading-3">{t("unit-heading.unit-number")}</th>,
        <th key="fix-end-date-wizard-heading-4">{t("unit-heading.gla")}</th>,
        <th key="fix-end-date-wizard-heading-5">{t("unit-heading.lease-status")}</th>,
        <th key="fix-end-date-wizard-heading-6">{t("unit-heading.lease-type")}</th>,
        <th key="fix-end-date-wizard-heading-7">{t("unit-heading.negotiated-date")}</th>,
        <th key="fix-end-date-wizard-heading-8">{t("unit-heading.start-date")}</th>,
        <th key="fix-end-date-wizard-heading-9">{t("unit-heading.end-date")}</th>,
        <th key="fix-end-date-wizard-heading-10">{t("unit-heading.current-rent")}</th>]

        const buildTableContent = (): JSX.Element => {
            const tableBody: JSX.Element[] = [];
            for (let index = 10 * (currentPage - 1); index < Math.min(filteredIndices.length, 10 * currentPage); index++) {
                const realIndex = filteredIndices[index];
                tableBody.push (<tr key={`fix-end-date-wizard-row-${realIndex}`}>
                    <td className='no-input-cell'><label>{props.floorDescriptionLookup[props.tenantList[realIndex]["floorLevel"] as string] as string}</label></td>
                    <td className='no-input-cell tenant-name-cell'><p>{props.tenantList[realIndex]["tenantName"] as string}</p></td>
                    <td className='no-input-cell tenant-unit-cell'><p>{props.tenantList[realIndex]["tenantUnitNumber"] as string}</p></td>
                    <td className='no-input-cell'><p>{props.tenantList[realIndex]["netLeasableArea"] as string}</p></td>
                    <td className='no-input-cell'><p>{props.leaseStatusLookup[props.tenantList[realIndex]["leaseStatusCode"] as string] as string}</p></td>
                    <td className='no-input-cell'><p>{props.leaseTypeLookup[props.tenantList[realIndex]["leaseTypeCode"] as string] as string}</p></td>
                    <td className='no-input-cell'><p>{props.tenantList[realIndex]["negotiatedDate"] as string}</p></td>
                    <td className='no-input-cell'><p>{props.tenantList[realIndex]["startDate"] as string}</p></td>
                    <td className="wizard-grey-out-cell" onFocus={() => setCurrentIndex(realIndex)}> 
                        <CommercialUnitDatePickerField name='endDate' formData={props.tenantList[realIndex]}
                        onChange={handleDateChange} asterisk={false} errors={{}} id={`update-import-wizard-${realIndex}-end-date`} />
                    </td>
                    <td className='no-input-cell'><p>{props.tenantList[realIndex]["currentBaseRent"] as string}</p></td>
                </tr>)
            }
            return (<>{tableBody}</>);
        }

    return (<div>
        <p>{t("fix-end-date-wizard.fix-end-date-description")}</p>
        <DataTable headings={headings} className='wizard-table' totalPages={totalPages} currentPage={currentPage} changePage={setCurrentPage}>
            {buildTableContent()}
        </DataTable>
    </div>)
}