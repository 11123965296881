import React from 'react';
import "./GuidedExperienceWizard.css";
import Input from '../common/Input/Input';
import { useTranslation } from 'react-i18next';
import DataTable from '../common/DataTable/DataTable';

interface BasicUnitInfoWizardProps {
    updateWizardFunction : (e: React.ChangeEvent<Element>, i: number, f: string, n?: boolean) => void;
    tenantList: Record<string, unknown>[];
    floorDescriptionLookup: Record<string, unknown>;
    // for pagination
    totalPages: number | undefined;
    currentPage: number;
    changePage: (e: number) => void;
}

export default function BasicUnitInfoWizard(props: BasicUnitInfoWizardProps): JSX.Element {
    const { t } = useTranslation("guided_wizard");

    const headings = [<th className='small-column' key="basic-info-wizard-heading-1">{t("unit-heading.floor-level")}</th>,
        <th className='full-column' key="basic-info-wizard-heading-2">{t("unit-heading.tenant-name")}</th>,
        <th key="basic-info-wizard-heading-3">{t("unit-heading.unit-number")}</th>,
        <th key="basic-info-wizard-heading-4">{t("unit-heading.gla")}</th>]

        const buildTableContent = (): JSX.Element => {
            const tableBody: JSX.Element[] = [];
            for (let index = 10 * (props.currentPage - 1); index < Math.min(props.tenantList.length, 10 * props.currentPage); index++) {
                tableBody.push (<tr key={`basic-info-wizard-row-${index}`}>
                    <td className='no-input-cell'><label>{props.floorDescriptionLookup[props.tenantList[index]["floorLevel"] as string] as string}</label></td>
                    <td><Input type='text' id={`basic-info-wizard-${index}-tenant-name`} name={`basic-info-wizard-${index}-tenant-name`} ariaLabel={`basic-info-wizard-${index}-tenant-name`}
                        onChange={(e) => props.updateWizardFunction(e, index, "tenantName")} value={props.tenantList[index]["tenantName"] as string} /></td>
                    <td><Input type='text' id={`basic-info-wizard-${index}-unit-number`} name={`basic-info-wizard-${index}-unit-number`} ariaLabel={`basic-info-wizard-${index}-unit-number`}
                        onChange={(e) => props.updateWizardFunction(e, index, "tenantUnitNumber")} value={props.tenantList[index]["tenantUnitNumber"] as string} /></td>
                    <td><Input type='number' id={`basic-info-wizard-${index}-gla`} name={`basic-info-wizard-${index}-gla`} ariaLabel={`basic-info-wizard-${index}-gla`}
                        onChange={(e) => props.updateWizardFunction(e, index, "netLeasableArea", true)} value={props.tenantList[index]["netLeasableArea"] as string} /></td>
                </tr>)
            }
            return (<>{tableBody}</>);
        }

    return (<div>
        <p>{t("basic-info-wizard.basic-info-description")}</p>
        <DataTable headings={headings} className='wizard-table' totalPages={props.totalPages} currentPage={props.currentPage} changePage={props.changePage}>
            {buildTableContent()}
        </DataTable>
    </div>)
}