import React from 'react';
import './Summary.css'
import { useTranslation } from 'react-i18next';
import NewAccordion from '../../components/common/NewAccordion/NewAccordion';

interface PropertySummaryProps {
    propertyData: Record<string, unknown>;
}

export default function PropertySummary(props: PropertySummaryProps): JSX.Element {

    const {propertyData} = props;

    const propertyOccupiedRow = ["officeOccupiedArea", "retailOccupiedArea", "warehouseStorageOccupiedArea", "manufactureOtherIndustrialUseOccupiedArea"]
    
    const propertyVacantRow = [ "officeVacantArea", "retailVacantArea", "warehouseStorageVacantArea", "manufactureOtherIndustrialUseVacantArea"]

    const { t } = useTranslation('parking_property');

    const rowTranslationKey = ['propertyTableHeaderOffice', 'propertyTableHeaderRetail', 'propertyTableHeaderWarehouse', 'propertyTableHeaderManufacturing'];

    return (
        <>
            {Object.keys(propertyData).length > 0 && <NewAccordion header={t("occupiedVacantSummary")}><div className='summary-grid'>
                <div className='summary-section-container'>
                    <table className='summary-table'>
                        <tbody>
                            <tr><th colSpan={2}>{t("propertyTableRowOccupied") + " " + t("propertyTableHeaderSpaceType")}</th></tr>
                            {propertyOccupiedRow.map((field, index) => (
                                <tr key={`row-occupied-${index}`}>
                                    <td>
                                        {(t(rowTranslationKey[index])).replaceAll(/([^\s])(\/)/g, "$1 $2").replaceAll(/(\/)([^\s])/g, "$1 $2")}
                                    </td>
                                    <td>
                                        {propertyData[field as keyof typeof propertyData] ? propertyData[field as keyof typeof propertyData] as number : "" }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className='summary-section-container'>
                    <table className='summary-table'>
                        <tbody>
                            <tr><th colSpan={2}>{t("propertyTableRowVacant") + " " + t("propertyTableHeaderSpaceType")}</th></tr>
                            {propertyVacantRow.map((field, index) => (
                                <tr key={`row-occupied-${index}`}>
                                    <td>
                                        {(t(rowTranslationKey[index])).replaceAll("/", " / ")}
                                    </td>
                                    <td>
                                        {propertyData[field as keyof typeof propertyData] ? propertyData[field as keyof typeof propertyData] as number : ""}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div></NewAccordion>}
        </>
    )
}