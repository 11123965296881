import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CampaignThroughputData, FarmingOperationCommercialGrainClass, farmingOperationCommercialGrainSelectedPayload, PercentageOfThroughput } from '../../types/gev/BusinessOperationsData';
import { OperationFieldProps } from '../../types/gev/BusinessOperationFormProps';
import { useBusinessOperationsState } from '../../utils/context/BusinessOperationsContext';
import { useFormErrorContext } from '../../utils/context/FormErrorContext';
import { useReadOnly } from '../../utils/ReadOnlyContext';
import { farmingOption } from '../../pages/BusinessOperations/BusinessOperations';
import Input from '../common/Input/Input';
import "./GEV.css";

function isFarmingOperation(dataset: FarmingOperationCommercialGrainClass) {
    return dataset &&
        typeof dataset.selected === 'boolean' &&
        'percentageOfThroughput' in dataset
}

export default function GEVPercentageOfThroughput(props: OperationFieldProps): JSX.Element {
    const { t } = useTranslation('gev')
    const { formData, setFormData } = useBusinessOperationsState();
    const { isReadOnly } = useReadOnly()
    const colKeys = ["yearValue", "owner", "nonOwner", "totalThroughput"];
    const { errors } = useFormErrorContext();
    const [errorFields, setErrorFields] = useState({});

    const initPOTData = () => {
        let pot: PercentageOfThroughput | undefined = undefined
        if (isFarmingOperation(formData[farmingOption])) {
            pot = formData[farmingOption].percentageOfThroughput
        }
        else {
            pot = structuredClone(farmingOperationCommercialGrainSelectedPayload.percentageOfThroughput)
        }
        const activeCampaignYear = Number(localStorage.getItem("activeCampaignYear"))
        if (pot && activeCampaignYear) {
            pot.campaignYearOne.yearValue = activeCampaignYear - 3
            pot.campaignYearTwo.yearValue = activeCampaignYear - 2
            pot.campaignYearThree.yearValue = activeCampaignYear - 1
        }
        return pot
    }
    const [potData, setPotData] = useState<PercentageOfThroughput | undefined>(initPOTData)

    const handlePotChange = (e: React.ChangeEvent<HTMLInputElement>, yearKey: keyof PercentageOfThroughput, yearObj: CampaignThroughputData) => {
        props.removeError && props.removeError(yearKey)
        e.preventDefault();
        const target = e.currentTarget
        const { value } = target;
        const trimValue = (value && value.trim()) ? value.trim() : null

        let ownerValue = null;
        if (trimValue) {
            const numberValue = Number(trimValue)
            if (numberValue < 0) {
                ownerValue = 0;
            } else if (numberValue > 100) {
                ownerValue = 100;
            } else {
                ownerValue = numberValue
            }
        }

        const newData: CampaignThroughputData = {
            yearValue: yearObj.yearValue,
            owner: ownerValue,
            nonOwner: ownerValue === null ? null : (100 - ownerValue),
            totalThroughput: ownerValue === null ? null : 100
        }
        target.value = (ownerValue === null) ? "" : String(ownerValue)

        setPotData(prevData => {
            const dataset = prevData as PercentageOfThroughput;
            return {
                ...dataset,
                [yearKey]: newData
            }
        });
    }

    useEffect(() => {
        setErrorFields({});
        if (errors.length > 0) {
            errors.forEach((error) => {
                if (error.primaryKey && (error.primaryKey.split('.')[2] === props.name || error.primaryKey.split('.')[1] === props.name)) {
                    const splitKey = error.primaryKey.split('.');
                    const localKey = splitKey.slice(-2).join(".");
                    setErrorFields(prevData => ({ ...prevData, [localKey]: error.message }));
                }
            })
        }
    }, [errors, props.name]);

    useEffect(() => {
        setFormData((prevData) => {
            const dataset = prevData.farmingOperationCommercialGrain;
            dataset.percentageOfThroughput = potData;
            return {
                ...prevData,
                [props.operation]: dataset
            }
        });
    }, [potData, props.operation, setFormData])

    return (
        <tr className='gev-input-tr'>
            <td className='index'>
                {props.index && props.index + "."}
            </td>
            <td>
                <div className='gev-input-label'>{t(props.operation + "." + props.name + ".label")}</div>
                <table width={"100%"} className='percentageOfThroughput' role="none">
                    <tbody>
                        <tr key={"heading"}>
                            {
                                colKeys.map((colKey, index) => {
                                    if (index === 0) {
                                        return <td key={colKey} width={"10%"}>{t(props.operation + "." + props.name + "." + colKey)}</td>
                                    } else if (index === 1) {
                                        return <td key={colKey} width={"50%"}>{t(props.operation + "." + props.name + "." + colKey)}</td>
                                    } else {
                                        return <td key={colKey} width={"20%"}>{t(props.operation + "." + props.name + "." + colKey)}</td>
                                    }
                                })
                            }
                        </tr>
                        {potData && Object.entries(potData as PercentageOfThroughput).map(([yearKey, yearObj]) => {
                            return (
                                <tr key={yearKey} id={`${props.operation}.${props.name}.${yearKey}`}>
                                    <td key={yearObj.yearValue}>{yearObj.yearValue}</td>
                                    <td key={`${yearKey}-owner`}>
                                        <Input type="number"
                                            id={yearKey}
                                            name={`${props.operation}.${props.name}.${yearKey}.owner`}
                                            value={yearObj.owner ?? ""}
                                            ariaLabel={`${props.operation}.${props.name}.${yearKey}.owner`}
                                            autoComplete="off"
                                            disabled={isReadOnly}
                                            onChange={(e) => handlePotChange(e, yearKey as keyof PercentageOfThroughput, yearObj)}
                                            className={`gev-throughput-input ${(`${yearKey}.owner` in errorFields) ? 'form-error' : ''}`}
                                        /> %
                                    </td>
                                    <td key={`${props.operation}.${props.name}.${yearKey}.nonOwner`}>{yearObj.nonOwner && (yearObj.nonOwner as number).toFixed(2)} %</td>
                                    <td key={`${props.operation}.${props.name}.${yearKey}.totalThroughput`}>{yearObj.totalThroughput} %</td>
                                </tr>
                            )
                        })
                        }
                    </tbody>
                </table>
            </td>
        </tr>
    )
}
