import React from 'react';
import './Summary.css'
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { ProfileOverviewState } from '../../redux/reducers/getProfile/getProfileOverviewReducer';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/root';
import { formatCurrency } from '../../utils/formatter';

interface IncomeExpenseSummaryProps {
    currencySymbol: string;
    totalHeader: string;
    tableData: Record<string, number>;
    separateTables?: boolean;
}

export default function IncomeExpenseSummary(props: IncomeExpenseSummaryProps): JSX.Element {

    const { t } = useTranslation(['navigation', 'pie']);
    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview)
    const incomeAttributeLookupType = profileOverview.profile.profile_type_code.toLowerCase()

    const addTotalToString = (s: string) => {
        if (s.split(" ")[0].toLocaleLowerCase() !== "total") {
            return "Total " + s;
        }
        return s;
    }

    const buildTable = () : JSX.Element | JSX.Element[] => {
        const rowArr: JSX.Element[] = [];
        Object.keys(props.tableData).forEach((key, index) => {
            const row = <tr key={`income-expense-row-${index}`}>
                <td>
                    {(key === "grandTotal") ? props.totalHeader : (key === "cost" ? t("costs.totalCapitalExpSummary", {ns: 'pie'}) : (key === "motelRevenue" ? addTotalToString(t("operatingRevenue", {ns: 'navigation'})) : 
                    ((key === "fixedCharges" && ["rho", "ltc", "mtl"].includes(incomeAttributeLookupType)) ? addTotalToString(t("GLF.charges", {ns: 'navigation'}))  : ((key === "revenue" && incomeAttributeLookupType === "ltc") ? 
                    addTotalToString(t("operatingRevenue", {ns: 'navigation'})) : ((key === "expenses" && incomeAttributeLookupType === "rho") ? addTotalToString(t( "operatingExpenses", {ns: 'navigation'} )) : addTotalToString(t( key, {ns: 'navigation'} )))))))}
                </td>
                <td className='summary-currency'>
                    <div>
                        {props.currencySymbol + " " + formatCurrency(props.tableData[key], i18n.language)}
                    </div>
                </td>
            </tr>;
            props.separateTables ? rowArr.push(<div className='summary-section-container' key={`income-expense-section-${index}`}><table className='summary-table'><thead className='invisible-header'><tr><th></th></tr></thead><tbody>{row}</tbody></table></div>) : rowArr.push(row);
        })
        if (!props.separateTables) {
            const lastTr = rowArr.pop();
            rowArr.splice(0, 0, lastTr as JSX.Element);
            
        }
        const table = props.separateTables ? rowArr : <div className='summary-section-container'><table className='summary-table'><thead className='invisible-header'><tr><th></th></tr></thead><tbody>{rowArr}</tbody></table></div>
        
        return table;
    }

    return (
        <>
            {buildTable()}
        </>
    )
}