import React, { PropsWithChildren, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../Button/Button";
import "./Modal.css";

interface ConfirmationDialogProps extends PropsWithChildren {
    onCancel: () => void,
    onConfirm: () => void,
    title?: string,
    showCloseButton?: boolean,
    headerClass?: string,
    buttonGroupClass?: string,
    cancelText?: string,
    canfirmText?: string,
}

function ConfirmationDialog(props: ConfirmationDialogProps): ReactElement {
    const { t } = useTranslation('common')
    const headerClassName = "modal-header" + (props.headerClass ? (" " + props.headerClass) : "")
    const buttonGroupClassName = "btn-grp" + (props.buttonGroupClass ? (" " + props.buttonGroupClass) : "")

    const confirm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        props.onConfirm()
    }

    const cancel = (e: React.UIEvent) => {
        e.preventDefault()
        props.onCancel()
    }

    return (
        <div className="modal-bg">
            <div className="modal-container confirm-dialog-max-width">
                <div className={headerClassName}>
                    <span className={"modal-title"}>
                        {props?.title}
                    </span>
                    {
                        props.showCloseButton &&
                        <div className="close-btn">
                            <Button forceEnabled={true} type="cancel" ariaLabel="cancel" onClick={(e) => cancel(e)}>
                                <FontAwesomeIcon icon={faClose} />
                            </Button>
                        </div>
                    }
                </div>
                <div className="modal-body">
                    <form onSubmit={(e) => confirm(e)}>
                        <div className="modal-content">
                            {props.children}
                        </div>
                        <div className={buttonGroupClassName}>
                            <Button type="cancel" onClick={(e) => cancel(e)}>
                                {props.cancelText ? props.cancelText : t("no").toLocaleUpperCase()}
                            </Button>
                            <Button type="submit">
                                {props.canfirmText ? props.canfirmText : t("yes").toLocaleUpperCase()}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ConfirmationDialog;