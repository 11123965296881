import { faBuilding, faCartShopping, faFileInvoiceDollar, faFilePdf, faMoneyBillTrendUp } from "@fortawesome/free-solid-svg-icons"
import MenuItem from "../../../types/menu/MenuItem"

const CIXNavList: MenuItem[] = [
  {
    id: 1,
    name: 'contactInfo',
    componentName: 'ContactInfo',
    complete: false,
    icon: faFilePdf
  },
  {
    id: 2,
    name: 'incomeExpense',
    componentName: 'IncomeExpense',
    complete: false,
    icon: faMoneyBillTrendUp,
    subItems: [
      {
        id: 21,
        name: 'revenue',
        complete: false,
        hasTabs: true,
        componentName: 'RevenueContainer',
        subItems: [
          {
            id: 211,
            name: 'rents',
            complete: false,
            accordionHeader: 'Rents'
          },
          {
            id: 212,
            name: 'recoveries',
            complete: false,
            accordionHeader: 'Recoveries'
          },
          {
            id: 213,
            name: 'otherRevenue',
            complete: false,
            accordionHeader: 'Other Revenue'
          },
          {
            id: 214,
            name: 'rentalLoss',
            complete: false,
            accordionHeader: 'Rental Loss'
          },
        ]
      },
      {
        id: 22,
        name: 'expenses',
        complete: false,
        hasTabs: true,
        componentName: 'ExpenseContainer',
        subItems: [
          {
            id: 221,
            name: 'general',
            complete: false,
          },
          {
            id: 222,
            name: 'admin',
            complete: false,
          },
          {
            id: 223,
            name: 'utilities',
            complete: false,
          },
          {
            id: 224,
            name: 'propMaintenance',
            complete: false,
          },
          {
            id: 225,
            name: 'other',
            complete: false,
          }
        ],
      },
      {
        id: 23,
        name: 'capitalExpSummary',
        complete: false,
        componentName: 'CapitalCostsSummary',
      },
      {
        id: 24,
        name: 'comments',
        componentName: 'Comment',
        complete: false
      }
    ],
  },
  {
    id: 3,
    name: 'commercial',
    complete: false,
    icon: faCartShopping,
    subItems: [
      {
        id: 31,
        name: 'leaseInformationTable',
        complete: false,
        componentName: 'LeaseInfo',
      },
      {
        id: 32,
        name: 'vacantAskingRate',
        complete: false,
        componentName: 'VacantAskingRate',
      },
      {
        id: 33,
        name: 'parkingSummary',
        complete: false,
        componentName: 'ParkingPropertySummary',
      }
    ]
  },
  {
    id: 4,
    name: 'apartment',
    complete: false,
    icon: faBuilding,
    subItems: [
      {
        id: 41,
        name: 'suiteBreakdownTable',
        complete: false,
        componentName: 'SuiteBreakdown'
      }
    ]
  },
  {
    id: 5,
    name: 'summaryReview',
    complete: false,
    componentName: 'Summary',
    icon: faFilePdf
  },
  {
    id: 6,
    name: 'submit',
    complete: false,
    componentName: 'Submit',
    icon: faFileInvoiceDollar
  }
]

export default CIXNavList