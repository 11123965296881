import React, { ReactElement, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronDown } from "@fortawesome/free-solid-svg-icons";

interface NewAccordionProps {
    header: string | ReactElement;
    children: JSX.Element | JSX.Element[];
    className?: string;
}

export default function NewAccordion(props: NewAccordionProps): ReactElement {
    const { header, children, className } = props
    const [accordionOpen, setAccordionOpen] = useState(true);

    return (
        <div className={className}>
            <div onClick={() => setAccordionOpen(!accordionOpen)} className="new-accordion-header">
                <h3 className="new-accordion-title">{header}</h3>
                <div className="new-accordion-hr" />
                <div className="new-accordion-icon"><FontAwesomeIcon icon={accordionOpen ? faChevronDown : faChevronLeft} /></div>
            </div>
            <div className={accordionOpen ? "" : "new-accordion-hide"}>
                {children}
            </div>
        </div>
    )
}

