import React from 'react';
import "./GuidedExperienceWizard.css";
import { useTranslation } from 'react-i18next';
import DataTable from '../common/DataTable/DataTable';
import RadioButton from '../common/RadioGroup/RadioButton';

interface LeaseStatusWizardProps {
    updateWizardFunction : (e: React.ChangeEvent<Element>, i: number, f: string, n?: boolean) => void;
    tenantList: Record<string, unknown>[];
    floorDescriptionLookup: Record<string, unknown>;
    leaseStatusLookup: Record<string, unknown>[];
    // for pagination
    totalPages: number | undefined;
    currentPage: number;
    changePage: (e: number) => void
}

export default function LeaseStatusWizard(props: LeaseStatusWizardProps): JSX.Element {
    const { t } = useTranslation("guided_wizard");
    const leaseStatusLookup = props.leaseStatusLookup.filter((leaseStatus) => (leaseStatus["value"] as string) !== "");

    const headings = [<tr key="lease-status-wizard-heading-row-1">
        <th className='small-column' key="lease-status-wizard-heading-1" rowSpan={2}>{t("unit-heading.floor-level")}</th>
        <th key="lease-status-wizard-heading-2" rowSpan={2}>{t("unit-heading.tenant-name")}</th>
        <th key="lease-status-wizard-heading-3" rowSpan={2}>{t("unit-heading.unit-number")}</th>
        <th key="lease-status-wizard-heading-4" colSpan={leaseStatusLookup.length}>{t("unit-heading.lease-status") + " " + t("unit-heading.select-one")}</th></tr>,
        <tr key="lease-status-wizard-heading-row-2">
            {leaseStatusLookup.map((leaseStatus) => 
            <th key={`lease-status-${leaseStatus["value"] as string}`}>{leaseStatus["text"] as string}</th>)}
        </tr>]

        const buildTableContent = (): JSX.Element => {
            const tableBody: JSX.Element[] = [];
            for (let index = 10 * (props.currentPage - 1); index < Math.min(props.tenantList.length, 10 * props.currentPage); index++) {
                tableBody.push (<tr key={`lease-status-wizard-row-${index}`}>
                    <td className='no-input-cell'><label>{props.floorDescriptionLookup[props.tenantList[index]["floorLevel"] as string] as string}</label></td>
                    <td className='no-input-cell tenant-name-cell'><p>{props.tenantList[index]["tenantName"] as string}</p></td>
                    <td className='no-input-cell tenant-unit-cell'><p>{props.tenantList[index]["tenantUnitNumber"] as string}</p></td>
                    {leaseStatusLookup.map((leaseStatus) => 
                            <td key={`lease-status-wizard-${index}-${(leaseStatus["text"] as string).toLowerCase()}`} className='center-cell'><RadioButton name={`lease-status-wizard-${index}-lease-status`} id={`lease-status-wizard-${index}-${(leaseStatus["text"] as string).toLowerCase()}`}
                                text="" textPosition='right' value={leaseStatus["value"] as string} currentValue={props.tenantList[index]["leaseStatusCode"] as string} onChange={(e) => props.updateWizardFunction(e, index, "leaseStatusCode")}/></td>)
                        }
                </tr>)
            }
            return (<>{tableBody}</>);
        }

    return (<div>
        <p>{t("lease-status-wizard.lease-status-description")}</p>
        <DataTable headings={headings} multiLineHeading={true} className='wizard-table wizard-lease-status' totalPages={props.totalPages} currentPage={props.currentPage} changePage={props.changePage}>
            {buildTableContent()}
        </DataTable>
    </div>)
}