export function formatCurrency(value: number | undefined | null, language: string) {
  if (typeof value === "number") {
    const farmattedValue = value.toLocaleString(language, {
      style: 'currency',
      currency: "CAD",
      currencyDisplay: "code",
    }).replaceAll("CAD", '').trim()
    return farmattedValue
  }
  return value
}

export function formatRoll(roll: string | undefined | null) {
  if (typeof roll === "string") {
    if (roll.length !== 15) {
      return roll
    }
    const formatted = roll.slice(0, 4) + " " + roll.slice(4, 7) + " " + roll.slice(7, 10) + " " + roll.slice(10, 15)
    return formatted
  }
  return roll
}