import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { BusinessOperationsKeys } from '../../pages/BusinessOperations/BusinessOperations';
import { OperationDataClass, OperationFieldProps } from '../../types/gev/BusinessOperationFormProps';
import { useBusinessOperationsState } from '../../utils/context/BusinessOperationsContext';
import { useReadOnly } from '../../utils/ReadOnlyContext';
import "./GEV.css";

export default function GEVTexttarea(props: OperationFieldProps): JSX.Element {
    const { operation, name } = props;
    const { t } = useTranslation('gev')
    const { isReadOnly } = useReadOnly()
    const { formData, setFormData } = useBusinessOperationsState();

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        props.removeError && props.removeError(name)
        const { value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [operation]: {
                ...prevData[operation as BusinessOperationsKeys],
                [name]: (value ? value : null),
            },
        }));
    };

    const currentValue = formData[operation as BusinessOperationsKeys]?.[name as keyof OperationDataClass] ?? '';

    return (
        <>
            <tr className='gev-input-tr'>
                <td className='index'>
                    {props.index && props.index + "."}
                </td>
                <td>
                    <label className='gev-input-label' htmlFor={props.operation + "." + props.name}>
                        {t(operation + "." + name)}
                    </label>
                    <textarea id={props.operation + "." + props.name}
                        name={props.operation + "." + props.name}
                        value={currentValue as unknown as string}
                        title={t(props.operation + "." + props.name)}
                        rows={3}
                        readOnly={isReadOnly}
                        onChange={handleChange}
                        className={props.hasErrors ? 'form-error' : ''}
                        maxLength={1100}    
                    />
                    <div className="input-error-message">
                        {props.errors && props.errors[props.name] as string}
                    </div>
                </td>
            </tr>
        </>
    )
}
