import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";
import Input from "../Input/Input";
import './Search.css';

interface SearchProps {
  showInputByDefault: boolean,
  handleSearch: (searchString: string) => void
}
export default function Search(props: SearchProps): JSX.Element {
  const { t } = useTranslation('landing');
  const [showSearchInput, setShowSearchInput] = useState(props.showInputByDefault);
  const [searchInput, setSearchInput] = useState('');
  return (
    <fieldset className='noborder-fieldset search-wrapper'>
        <legend className='invisible-legend'><span>Search Roll group</span></legend>
        {showSearchInput && (
            <Input name='search-roll' id='search-roll' 
            type='text' placeholder={t('search-roll')}
            title="search-roll-input"
            ariaLabel='search-roll-input' value={searchInput}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.handleSearch(e.currentTarget.value);
              setSearchInput(e.currentTarget.value) }
            }/>
        )}
        <button className='search-btn' onClick={() => setShowSearchInput(!showSearchInput)} aria-label="Search button">
          <FontAwesomeIcon icon={faMagnifyingGlass} className='search-icon'/>
        </button>
    </fieldset>
  );
}