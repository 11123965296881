import ProfileOverviewType from "../../../types/profile/ProfileOverview";
import { GET_PROFILE_OVERVIEW, RESET_GET_PROFILE_OVERVIEW } from "../../actionTypes/actionsTypes";
import defaultAsyncReducer from "../../defaultReducer";
import { AppAction, BaseAsyncReducerState } from "../../types";

const initialProfile: ProfileOverviewType = {
  profile_id: 0,
  profile_type_code: "",
  profile_name: "",
  profile_address: "",
  municipality: "",
  previous_campaigns: [],
  current_campaign: {
    year: 0,
    campaignId: 0,
    currentSnapshotId: 0,
    statusCode: null
  }
}
export interface ProfileOverviewState extends BaseAsyncReducerState {
  found: boolean,
  profile: ProfileOverviewType,
  message: string
}

const initialState: ProfileOverviewState = {
  found: false,
  profile: initialProfile,
  message: "",
  loading: false,
  error: false
}

function getProfileOverviewReducer(state = initialState, action: AppAction) {
  if (action.type === RESET_GET_PROFILE_OVERVIEW) {
    return initialState;
  } else {
    return defaultAsyncReducer(state, action, GET_PROFILE_OVERVIEW)
  }
}

export default getProfileOverviewReducer;