import React from "react";
import { useTranslation } from "react-i18next";
import "./LandingBanner.css";

export default function LandingBanner(): JSX.Element{

    const { t } = useTranslation('landing');
    return (
        <div className="landing-banner">
            <img className="landing-banner-img" src="./images/landing_banner_img.jpg" alt={t("banner") as string} />
        </div>
    )
}