import React, { useEffect, useState } from 'react';
import "./GuidedExperienceWizard.css";
import { useTranslation } from 'react-i18next';
import DataTable from '../common/DataTable/DataTable';

interface SummaryWizardProps {
    tenantList: Record<string, unknown>[];
    confirmedTenants: Record<string, unknown>[];
    floorDescriptionLookup: Record<string, unknown>;
    leaseStatusLookup: Record<string, unknown>;
    leaseTypeLookup: Record<string, unknown>;
    setTenantList: (value: Record<string, unknown>[]) => void;
    setConifirmedList: (value: Record<string, unknown>[]) => void;
    stepUpArr: number[];
    setStepUpArr: (value: number[]) => void;
}

export default function SummaryWizard(props: SummaryWizardProps): JSX.Element {
    const { t } = useTranslation("guided_wizard");
    const [totalPages, setTotalPages] = useState<number | undefined>(undefined);
    const [currentPage, setCurrentPage] = useState(1);
    const [summaryTenantList, setSummaryTenantList] = useState<Record<string, unknown>[]>([]);

    const cleanData = (tenantList: Record<string, unknown>[]) => {
        const cleanedList: Record<string, unknown>[] = tenantList.map((tenant, index) => {
            if (tenant["leaseStatusCode"] === "1") {
                tenant["tenantName"] = "Vacant";
                tenant["negotiatedDate"] = undefined;
                tenant["endDate"] = undefined;
                tenant["includeInsuranceFlag"]= undefined;
                tenant["includeManagementFlag"]= undefined;
                tenant["includeMaintenanceFlag"]= undefined;
                tenant["includeHydroFlag"]= undefined;
                tenant["includeWaterFlag"]= undefined;
                tenant["includeHvacFlag"]= undefined;
                tenant["unitSubtypeCode"]= undefined;
                tenant["currentBaseRent"]= undefined;
                tenant["propertyTaxRecovery"]= undefined;
                tenant["operatingRecovery"]= undefined;
                tenant["tenantImprovement"]= undefined;
                tenant["leaseTypeCode"]= undefined;
                tenant["freeRentMonth"]= undefined;
                tenant["landLeaseFlag"]= undefined;
                tenant["stepUpDate1"] = undefined;
                tenant["stepUpRate1"] = undefined;
                tenant["stepUpDate2"] = undefined;
                tenant["stepUpRate2"] = undefined;
                tenant["stepUpDate3"] = undefined;
                tenant["stepUpRate3"] = undefined;
                tenant["stepUpDate4"] = undefined;
                tenant["stepUpRate4"] = undefined;
                const indexOfIndexInStepUpArr = props.stepUpArr.indexOf(index);
                if (indexOfIndexInStepUpArr >= 0) {
                    const newStepUpArr = [...props.stepUpArr];
                    newStepUpArr.splice(indexOfIndexInStepUpArr, 1);
                    props.setStepUpArr(newStepUpArr);
                }
            } else if (tenant["leaseStatusCode"] === "5") {
                tenant["endDate"] = undefined;
            }
            if (tenant["leaseTypeCode"] === "1") {
                tenant["includeInsuranceFlag"]= undefined;
                tenant["includeManagementFlag"]= undefined;
                tenant["includeMaintenanceFlag"]= undefined;
                tenant["includeHydroFlag"]= undefined;
                tenant["includeWaterFlag"]= undefined;
                tenant["includeHvacFlag"]= undefined;
            }
            return tenant;
        });
        return cleanedList;
    }

    useEffect(() => {
        const tenantList = cleanData(props.tenantList);
        props.setTenantList(tenantList);
        const confirmedList = cleanData(props.confirmedTenants);
        props.setConifirmedList(confirmedList);
        const summaryArr = [...tenantList, ...confirmedList];
        if (summaryArr.length > 10) {
            setTotalPages(Math.ceil(summaryArr.length / 10));
        }
        setSummaryTenantList(summaryArr);
        // eslint-disable-next-line
    }, []);

    const headings = [<th className='small-column' key="summary-wizard-heading-1">{t("unit-heading.floor-level")}</th>,
        <th key="summary-wizard-heading-2">{t("unit-heading.tenant-name")}</th>,
        <th key="summary-wizard-heading-3">{t("unit-heading.unit-number")}</th>,
        <th key="summary-wizard-heading-4">{t("unit-heading.gla")}</th>,
        <th key="summary-wizard-heading-5">{t("unit-heading.lease-status")}</th>,
        <th key="summary-wizard-heading-6">{t("unit-heading.lease-type")}</th>,
        <th key="summary-wizard-heading-7">{t("unit-heading.negotiated-date")}</th>,
        <th key="summary-wizard-heading-8">{t("unit-heading.start-date")}</th>,
        <th key="summary-wizard-heading-9">{t("unit-heading.end-date")}</th>,
        <th key="summary-wizard-heading-10">{t("unit-heading.current-rent")}</th>]

        const buildTableContent = (): JSX.Element => {
            const tableBody: JSX.Element[] = [];
            for (let index = 10 * (currentPage - 1); index < Math.min(summaryTenantList.length, 10 * currentPage); index++) {
                tableBody.push (<tr key={`summary-wizard-row-${index}`}>
                    <td className='no-input-cell'><label>{props.floorDescriptionLookup[summaryTenantList[index]["floorLevel"] as string] as string}</label></td>
                    <td className='no-input-cell tenant-name-cell'><p>{summaryTenantList[index]["tenantName"] as string}</p></td>
                    <td className='no-input-cell tenant-unit-cell'><p>{summaryTenantList[index]["tenantUnitNumber"] as string}</p></td>
                    <td className='no-input-cell'><p>{summaryTenantList[index]["netLeasableArea"] as string}</p></td>
                    <td className='no-input-cell'><p>{props.leaseStatusLookup[summaryTenantList[index]["leaseStatusCode"] as string] as string}</p></td>
                    <td className='no-input-cell'><p>{props.leaseTypeLookup[summaryTenantList[index]["leaseTypeCode"] as string] as string}</p></td>
                    <td className='no-input-cell'><p>{summaryTenantList[index]["negotiatedDate"] as string}</p></td>
                    <td className='no-input-cell'><p>{summaryTenantList[index]["startDate"] as string}</p></td>
                    <td className='no-input-cell'><p>{summaryTenantList[index]["endDate"] as string}</p></td>
                    <td className='no-input-cell'><p>{summaryTenantList[index]["currentBaseRent"] as string}</p></td>
                </tr>)
            }
            return (<>{tableBody}</>);
        }

    return (<div>
        <p>{t("summary-wizard.summary-description")}</p>
        <DataTable headings={headings} className='wizard-table' totalPages={totalPages} currentPage={currentPage} changePage={setCurrentPage}>
            {buildTableContent()}
        </DataTable>
    </div>)
}