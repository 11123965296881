import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updateCampaignStatus } from '../../redux/actions/campaign/updateStatus';
import { getIncomeFile } from '../../redux/actions/file/file';
import { getProfileOverview, resetGetProfileDetails } from '../../redux/actions/profile/profile';
import { searchProfileByRoll } from '../../redux/actions/profile/searchRoll';
import { setReadOnly } from '../../redux/actions/readOnly/readOnly';
import { EXTERNAL_IN_PROGRESS, EXTERNAL_SUBMITTED, INTERNAL_IN_PROGRESS, NEW_CREATION, RESET_FILE, RESET_GET_PROFILE_OVERVIEW, RESET_PROPERTIES, RESET_SEARCH_PROFILE_BY_ROLL, RESET_UPDATE_CAMPAIGN_STATUS } from '../../redux/actionTypes/actionsTypes';
import { RootState } from '../../redux/reducers/root';
import { UpdateCampaignState } from '../../redux/reducers/getCampaign/updateCampaignStatusReducer';
import { FileState } from '../../redux/reducers/getFile/getFileReducer';
import { ProfileByRollState } from '../../redux/reducers/getProfile/getProfileByRollReducer';
import { ProfileOverviewState } from '../../redux/reducers/getProfile/getProfileOverviewReducer';
import { GeneratePDFPayload } from '../../types/pdf/GeneratePDFPayload';
import { CampaignListByRoll } from '../../types/profile/CampaignListByRoll';
import AnchorAsButton from '../common/AnchorAsButton/AnchorAsButton';
import Button from '../common/Button/Button';
import LoadingSpinner from '../common/LoadingSpinner/LoadingSpinner';
import { isCampaignListDisplayed, isCurrentCampaignDisplayed, isPreviousCampaignListDisplayed, isThereCampaignToImport, displayPreviousYearsNumber, excludeCampaignYears } from '../../utils/campaignList';
import "./CampaignList.css"

export default function CampaignList(): JSX.Element {
    const { t } = useTranslation('campaign_list')
    const { i18n } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const searchRollStore: ProfileByRollState = useSelector((state: RootState) => state.profileReducer.getProfileByRoll)
    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview)
    const fileState: FileState = useSelector((state: RootState) => state.fileReducer.file)
    const updateCampaignStatusState: UpdateCampaignState = useSelector((state: RootState) => state.campaignReducer.updateCampaignStatus);
    const [campaignList, setCampaignList] = useState<CampaignListByRoll[] | null>(null)
    const [currentCampaign, setCurrentCampaign] = useState<CampaignListByRoll | undefined>(undefined)
    const [searchingRoll, setSearchingRoll] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [getingProfileOverview, setGetingProfileOverview] = useState(false)
    const [downloading, setDownloading] = useState(false)
    const currentCampaignYear = profileOverview.profile.current_campaign.year
    const roll = localStorage.getItem('rollNumber') as string
    const profileId = Number(localStorage.getItem("profileId") as string)
    const isInternalUser = localStorage.getItem("isInternalUser")
    const [choosedSnapshot, setChoosedSnapshot] = useState<Record<string, number>>({
        campaignYear: 0,
        campaignId: 0,
        snapshotId: 0,
    })

    useEffect(() => {
        dispatch({ type: RESET_SEARCH_PROFILE_BY_ROLL })
        dispatch(searchProfileByRoll(roll))
        setSearchingRoll(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (searchingRoll) {
            if (searchRollStore.status) {
                // console.log(searchRollStore)
                if (searchRollStore.status === 200 && searchRollStore.profileID === profileId && searchRollStore.campaigns) {
                    const campaigns = searchRollStore.campaigns.filter((campaign) => !excludeCampaignYears.includes(campaign.year))
                    if (campaigns) {
                        const currentCampaign = campaigns.find((campaign) => (
                            campaign.year === currentCampaignYear
                            // && campaign.currentSnapshotId
                        ))
                        setCurrentCampaign(currentCampaign)
                        const campaignList = campaigns.filter((campaign) => campaign.year !== currentCampaignYear)
                        const sortedCampaignList = campaignList.sort((a, b) => b.year - a.year)
                        if (isInternalUser) {
                            setCampaignList(sortedCampaignList)
                        } else {
                            const earliest_year = currentCampaignYear - displayPreviousYearsNumber
                            const previousCampaignList = sortedCampaignList.filter((campaign) => {
                                return campaign.currentSnapshotId && campaign.year >= earliest_year
                            })
                            setCampaignList(previousCampaignList)
                        }
                    }
                } else {
                    setCurrentCampaign(undefined)
                    setCampaignList([])
                    if (searchRollStore.message) {
                        toast.error(searchRollStore.message)
                    }
                }
                setSearchingRoll(false)
            }
        }
    }, [currentCampaignYear, isInternalUser, profileId, searchRollStore, searchingRoll])

    const isReadOnly = (campaign: CampaignListByRoll, snapshot: Record<string, unknown>) => {
        if (isInternalUser) {
            return false
        }

        if (campaign.year !== currentCampaignYear) {
            return true
        }

        const campaignStatus = campaign.statusCode
        if (campaignStatus === EXTERNAL_IN_PROGRESS) {
            if (snapshot && snapshot["snapshotTypeCode"] === "2") {
                return false
            }
        }

        return true
    }

    const gotoReturn = (
        campaign: CampaignListByRoll,
        snapshot: Record<string, unknown>,
        isReadonly: boolean
    ) => {
        dispatch(setReadOnly(isReadonly))
        dispatch(resetGetProfileDetails())

        const choosedSnapshot = {
            campaignYear: campaign.year,
            campaignId: snapshot["campaignId"] as number,
            snapshotId: snapshot["snapshotId"] as number,
        }
        // setChoosedSnapshot(choosedSnapshot)
        console.log("goto", choosedSnapshot)

        localStorage.setItem("activeCampaignYear", String(choosedSnapshot.campaignYear))
        localStorage.setItem('campaignId', String(choosedSnapshot.campaignId))
        localStorage.setItem('currentSnapshotId', String(choosedSnapshot.snapshotId))
        localStorage.setItem('campaignStatusCode', campaign.statusCode as string);
        navigate('/portal')
    }

    const updateStatusAndGotoReturn = (campaign: CampaignListByRoll, isCopySnapshot = "false") => {
        dispatch({ type: RESET_UPDATE_CAMPAIGN_STATUS })
        if (campaign.statusCode === EXTERNAL_SUBMITTED) {
            dispatch(updateCampaignStatus(INTERNAL_IN_PROGRESS, String(profileOverview.profile.profile_id), String(campaign.campaignId)))
        } else if (campaign.statusCode === NEW_CREATION) {
            dispatch(updateCampaignStatus(EXTERNAL_IN_PROGRESS, String(profileOverview.profile.profile_id), String(campaign.campaignId), isCopySnapshot))
        }
        setUpdating(true)
    }

    useEffect(() => {
        if (updating && updateCampaignStatusState.status) {
            setUpdating(false)
            if (updateCampaignStatusState.updated) {
                localStorage.setItem("activeCampaignYear", String(updateCampaignStatusState.campaign["campaignYear"]))
                localStorage.setItem('campaignId', String(updateCampaignStatusState.campaign["campaignId"]))
                localStorage.setItem("currentSnapshotId", String(updateCampaignStatusState.campaign["currentSnapshotId"]))
                localStorage.setItem('campaignStatusCode', updateCampaignStatusState.campaign["statusCode"] as string)
                localStorage.setItem("editMode", "true")
                dispatch(setReadOnly(false))
                dispatch({ type: RESET_PROPERTIES })
                dispatch({ type: RESET_GET_PROFILE_OVERVIEW })
                dispatch(getProfileOverview(updateCampaignStatusState.campaign["profileId"] as number))
                setGetingProfileOverview(true)
            } else if (updateCampaignStatusState.error) {
                toast.error("Failed to update status");
            }
        }
    }, [dispatch, profileOverview.profile.profile_id, updateCampaignStatusState, updating])

    useEffect(() => {
        if (getingProfileOverview && profileOverview.status) {
            if (profileOverview.found) {
                dispatch(resetGetProfileDetails())
                navigate('/portal')
            } else if (profileOverview.error) {
                toast.error("Failed to get profile overview");
            }
            setGetingProfileOverview(false)
        }
    }, [dispatch, getingProfileOverview, navigate, profileOverview])

    const displaySnapshotActions = (
        campaign: CampaignListByRoll,
        snapshotType: string
    ) => {
        const snapshots = campaign.snapshots
        if (snapshots) {
            const snapshot = snapshots.find((s) => s["snapshotTypeCode"] === snapshotType)
            if (snapshot) {
                const isReadonly = isReadOnly(campaign, snapshot)
                if (campaign.year === currentCampaignYear) {
                    return <>
                        <div className={'action-container-column'}>
                            {
                                (isReadonly === false && !isInternalUser) &&
                                <Button onClick={() => gotoReturn(campaign, snapshot, false)} type="submit" text={t("continue")} />
                            }
                            <div className='action-container'>
                                <AnchorAsButton onClick={() => downloadPdf(campaign, snapshot)}>
                                    {t("download")}
                                </AnchorAsButton>
                                |
                                <AnchorAsButton onClick={() => gotoReturn(campaign, snapshot, true)}>
                                    {t("view")}
                                </AnchorAsButton>
                                {
                                    (isReadonly === false && isInternalUser) &&
                                    <>
                                        |
                                        <AnchorAsButton onClick={() => gotoReturn(campaign, snapshot, false)}>
                                            {t("edit")}
                                        </AnchorAsButton>
                                    </>
                                }
                            </div>
                        </div>
                    </>
                } else {
                    return <>
                        <div className='action-container'>
                            <AnchorAsButton onClick={() => downloadPdf(campaign, snapshot)}>
                                {t("download")}
                            </AnchorAsButton>
                            |
                            <AnchorAsButton onClick={() => gotoReturn(campaign, snapshot, true)}>
                                {t("view")}
                            </AnchorAsButton>
                            {
                                (isReadonly === false) &&
                                <>
                                    |
                                    <AnchorAsButton onClick={() => gotoReturn(campaign, snapshot, false)}>
                                        {t("edit")}
                                    </AnchorAsButton>
                                </>
                            }
                        </div>
                    </>
                }
            } else {
                const blankDiv = <div className='action-container'></div>
                const statusDiv = <div className='action-container'>{campaign.statusCode}</div>
                if (snapshotType === "2") {
                    if (campaign.statusCode === NEW_CREATION) {
                        return (
                            <div className='action-container'>
                                {
                                    isThereCampaignToImport(profileOverview, campaign.year) &&
                                    <Button onClick={() => updateStatusAndGotoReturn(campaign, "true")} type="submit" text={t("start-import")} />
                                }
                                <Button onClick={() => updateStatusAndGotoReturn(campaign)} type="secondary" text={t("start-blank")} />
                            </div>
                        )
                    } else if (campaign.statusCode === INTERNAL_IN_PROGRESS) {
                        return blankDiv
                    } else {
                        if (isInternalUser) {
                            return statusDiv
                        } else {
                            return blankDiv
                        }
                    }
                } else {
                    // snapshotType === "3"
                    if (isInternalUser) {
                        if (campaign.statusCode === EXTERNAL_SUBMITTED) {
                            return (
                                <div className='action-container'>
                                    <AnchorAsButton onClick={() => updateStatusAndGotoReturn(campaign)}>
                                        {t("start-review")}
                                    </AnchorAsButton>
                                </div>
                            )
                        } else if ([NEW_CREATION, EXTERNAL_IN_PROGRESS].includes(campaign.statusCode as string)) {
                            return blankDiv
                        } else {
                            return statusDiv
                        }
                    } else {
                        return blankDiv
                    }

                }
            }
        }
        return null
    }

    const downloadPdf = (
        campaign: CampaignListByRoll,
        snapshot: Record<string, unknown>,
    ) => {
        const choosedSnapshot = {
            campaignYear: campaign["year"],
            campaignId: snapshot["campaignId"] as number,
            snapshotId: snapshot["snapshotId"] as number,
        }
        setChoosedSnapshot(choosedSnapshot)
        console.log("download", choosedSnapshot)

        const payload: GeneratePDFPayload = {
            language: i18n.language,
            rollNumber: roll,
            profileId: profileOverview.profile.profile_id,
            campaignYear: choosedSnapshot.campaignYear,
            campaignId: choosedSnapshot.campaignId,
            snapshotId: choosedSnapshot.snapshotId,
            snapshotRequestType: String(snapshot["snapshotTypeCode"]),
        }
        dispatch(getIncomeFile(payload))
        setDownloading(true)
    }

    useEffect(() => {
        if (downloading && fileState.loading === false) {
            if (fileState.status === 200) {
                const base64string = "data:application/pdf;base64," + fileState.pdf
                const downloadLink = document.createElement("a")
                downloadLink.href = base64string
                downloadLink.download = roll + "_" + choosedSnapshot.campaignYear + "_AIRP.pdf"
                downloadLink.click()
            } else if (fileState.message) {
                toast.error(fileState.message)
            }
            dispatch({ type: RESET_FILE })
            setDownloading(false)
        }
    }, [choosedSnapshot.campaignYear, dispatch, downloading, fileState, roll])

    return (
        <>
            {
                (localStorage.getItem("profileId") && Number(localStorage.getItem("profileId")) === profileOverview.profile.profile_id
                    && isCampaignListDisplayed(profileOverview))
                    ?
                    (
                        (searchingRoll || downloading || updating || getingProfileOverview)
                            ?
                            <LoadingSpinner loading={true}></LoadingSpinner>
                            :
                            <>
                                <div className='flex flex-row justify-content-center'>
                                    <table className='campaign-list-table' role='none'>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td>{t("yours")}</td>
                                                <td>{t("mpac-reviewed")}</td>
                                            </tr>
                                            {
                                                (currentCampaign && isCurrentCampaignDisplayed(profileOverview)) &&
                                                <tr>
                                                    <td> {currentCampaign['year']}</td>
                                                    <td>{displaySnapshotActions(currentCampaign, "2")}</td>
                                                    <td>{displaySnapshotActions(currentCampaign, "3")}</td>
                                                </tr>
                                            }
                                            {
                                                campaignList && isPreviousCampaignListDisplayed(profileOverview)
                                                && campaignList.map((campaign, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                {campaign['year']}
                                                            </td>
                                                            <td>
                                                                {displaySnapshotActions(campaign, "2")}
                                                            </td>
                                                            <td>
                                                                {displaySnapshotActions(campaign, "3")}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </>
                    )
                    :
                    <></>
            }
        </>
    )
}
