import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollar, faEuro, faGbp, faJpy } from "@fortawesome/free-solid-svg-icons";
import "./CurrencyInput.css"
import { RootState } from "../../../redux/reducers/root";
import { useSelector } from "react-redux";
import { GlobalSettingState } from "../../../redux/reducers/getGlobalSetting/getGlobalSettingReducer";
import { useReadOnly } from "../../../utils/ReadOnlyContext";

interface CurrencyInputProps {
    id?: string,
    name?: string,
    currency?: string,
    value?: number | null | undefined,
    onChange?: (newAmt: number | undefined | null, name: string, oldAmt: number | undefined | null) => void,
    errormessage?: string | null | undefined,
    symbol?: string | React.ReactElement,
    className?: string,
    placeholder?: string,
    title?: string,
    readOnly?: boolean,
    allowNegative?: boolean,
    disabled?: boolean,
}

export const CurrncyIcon = {
    "CAD": <FontAwesomeIcon icon={faDollar} height={'14px'} />,
    "USD": <FontAwesomeIcon icon={faDollar} height={'14px'} />,
    "EUR": <FontAwesomeIcon icon={faEuro} height={'14px'} />,
    "GBP": <FontAwesomeIcon icon={faGbp} height={'14px'} />,
    "JPY": <FontAwesomeIcon icon={faJpy} height={'14px'} />,
}

function CurrencyInput(props: CurrencyInputProps): ReactElement {
    const { isReadOnly } = useReadOnly()
    const { i18n } = useTranslation();
    const name = props.name ? props.name : (props.id ? props.id : new Date().getTime().toString());
    const currentCurrency = "CAD"

    const [value, setValue] = useState(props.value)
    const [error, setError] = useState('')
    const [localeGroupSeparator, setLocaleGroupSeparator] = useState<string>(",")
    const [separators, setSeparators] = useState<Record<string, string>>({})
    const [allowedInputChars, setAllowedInputChars] = useState<string[]>([])
    const globalSettingState: GlobalSettingState = useSelector((state: RootState) => state.globalSettingReducer.globalSetting);

    const formatValue = (value: number | null | undefined) => {
        if (value == null || value === undefined) {
            return "";
        }
        let farmattedValue = value.toLocaleString(i18n.language, {
            style: 'currency',
            currency: currentCurrency,
            currencyDisplay: "code",
        }).replaceAll(currentCurrency, '').trim()
        if (localeGroupSeparator !== separators.group) {
            farmattedValue = farmattedValue.replaceAll(localeGroupSeparator, separators.group)
        }
        return farmattedValue
    }

    const [displayValue, setDisplayValue] = useState(formatValue(props.value));

    const getSeparator = (locale: string, separatorType: string) => {
        const numberWithGroupAndDecimalSeparator = -10000.1;
        return Intl.NumberFormat(locale).formatToParts(numberWithGroupAndDecimalSeparator).find(part => part.type === separatorType)?.value;
    }

    const filterAllowedInputChars = (input: string) => {
        const filterInputCharArray = []
        for (const char of input) {
            if (allowedInputChars.includes(char)) {
                filterInputCharArray.push(char)
            }
        }
        const join = filterInputCharArray.join("")
        return join
    }

    const cleanToNumberFormat = (value: string) => {
        const cleanCharArray = []
        for (const char of value) {
            if (allowedInputChars.includes(char)) {
                if (char === separators.decimal) {
                    cleanCharArray.push(".")
                } else if (char === separators.group) {
                    // discard group separator
                } else {
                    cleanCharArray.push(char)
                }
            }
        }
        const cleaned = cleanCharArray.join("")
        return cleaned
    }

    const isValidAfterClean = (cleaned: string, original: string) => {
        const numCleaned = Number(cleaned)
        if (isNaN(numCleaned)) {
            return false
        }
        if (numCleaned === 0) {
            const numOriginal = Number(original)
            if (isNaN(numOriginal)) {
                return false
            }
            if (cleaned === "" && original === "") {
                return true
            }
        }
        return true
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const targetValue = e.target.value;

        const display = filterAllowedInputChars(targetValue);
        setDisplayValue(display)
        const numberFormat = cleanToNumberFormat(display);
        const targetNumber = Number(numberFormat)
        isNaN(targetNumber) ? setError("Invalid number") : setError("");
        e.stopPropagation();
    }

    const onBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        const targetValue = e.target.value.trim()
        const cleaned = cleanToNumberFormat(targetValue)
        if (isValidAfterClean(cleaned, targetValue)) {
            let newValue = undefined
            if (cleaned !== "") {
                const valueParts = Intl.NumberFormat(i18n.language, { maximumFractionDigits: 2 })
                    .formatToParts(Number(cleaned))
                const filteredPart = valueParts.filter(part => part.type !== 'group')
                const decimalPart = filteredPart.find(part => part.type === 'decimal')
                if (decimalPart) {
                    decimalPart.value = '.'
                }
                const newValueString = (filteredPart.map((part) => part.value)).join("")
                newValue = Number(newValueString)
            }
            setValue(newValue)
            setDisplayValue(formatValue(newValue))
            if (props.onChange) {
                props.onChange(newValue, name, value)
            }
        } else {
            setDisplayValue(formatValue(value))
            setError("")
        }
    }

    useEffect(() => {
        const getGroupChar = (groupChar: string) => {
            if (groupChar.charCodeAt(0) === 8239) {
                return " ";
            } else {
                return groupChar;
            }
        }
        const initSeparators = () => {
            const groupSeparator = getSeparator(i18n.language, 'group');
            setLocaleGroupSeparator(groupSeparator as string);
            const separators: Record<string, string> = {
                decimal: getSeparator(i18n.language, 'decimal') as string,
                group: getGroupChar(groupSeparator as string) as string,
            }
            if (props.allowNegative !== false) {
                separators["minus"] = getSeparator(i18n.language, 'minusSign') as string;
            }
            setSeparators(separators);
            setAllowedInputChars(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].concat(Object.values(separators)));
        }

        const formatValue = (value: number | null | undefined) => {
            if (value == null || value === undefined) {
                return "";
            }
            let farmattedValue = value.toLocaleString(i18n.language, {
                style: 'currency',
                currency: currentCurrency,
                currencyDisplay: "code",
            }).replaceAll(currentCurrency, '').trim()
            if (localeGroupSeparator !== separators.group) {
                farmattedValue = farmattedValue.replaceAll(localeGroupSeparator, separators.group)
            }
            return farmattedValue
        }

        initSeparators();
        setDisplayValue(formatValue(value));
    }, [i18n.language, localeGroupSeparator, props.allowNegative, separators.group, value]);

    useEffect(() => {
        setValue(props.value)
        setDisplayValue(formatValue(props.value))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value])

    return (
        <div className={(props.disabled ? "disabled-currency-wrapper" : "currency-wrapper")}>
            <div id={name + "-container"} className={(props.className ? props.className + " " : "") + "currency-container"}>
                <div className="currency-symbol">
                    {props.symbol ? props.symbol : (globalSettingState.setting["currency"] as Record<string, unknown>)["symbol"] as string}
                </div>
                <input {...props} type="text"
                    id={name} name={name} aria-label={name}
                    className={"currency-input"}
                    onChange={(e) => onChange(e)}
                    onBlur={(e) => onBlur(e)}
                    value={displayValue}
                    autoComplete="off"
                    disabled={isReadOnly || props.disabled}
                    maxLength={20}
                />
            </div>
            {(error || props.errormessage) && (
                <p id={name + "-error"} className="form-error-message">
                    {error === "" ? props.errormessage : error}
                </p>
            )}
        </div>
    )
}

export default CurrencyInput;
