import React from 'react';
import { useTranslation } from 'react-i18next';
import { useReadOnly } from '../../utils/ReadOnlyContext';
import Asterisk from '../common/Asterisk/Asterisk';
import CurrencyInput from '../common/CurrencyInput/CurrencyInput';
import "./CommercialUnit.css";

interface RateProps {
    formData: Record<string, unknown>,
    handleCurrencyChange: (newAmt: number | undefined | null, name: string, oldAmt: number | undefined | null) => void,
    currencySymbol?: string,
    errors: Record<string, string>,
    disabled?: boolean
}

export default function OperatingExpense(props: RateProps): JSX.Element {
    const { isReadOnly } = useReadOnly()
    const { t } = useTranslation('unit');

    return (
        <tbody>
            <tr>
                <td className='label-cell padding-left-10 padding-right-10'>
                    {t('headings.annual-rent')} <Asterisk />
                </td>
                <td className='currency-input-td'>
                    <CurrencyInput id="annualRent"
                        value={props.formData.annualRent as number | undefined}
                        onChange={props.handleCurrencyChange}
                        title={t('tooltips.annual-rent-tooltip')}
                        errormessage={props.errors.annualRent}
                        disabled={isReadOnly || props.disabled}
                    />
                </td>
            </tr>
            <tr>
                <td className='label-cell padding-left-10 padding-right-10'>
                    {t('headings.current-base-rent')}  <Asterisk />
                </td>
                <td className='currency-input-td'>
                    <CurrencyInput id="currentBaseRent"
                        value={props.formData.currentBaseRent as number | undefined}
                        onChange={props.handleCurrencyChange}
                        symbol={props.currencySymbol}
                        title={t('tooltips.current-base-rent-tooltip')}
                        errormessage={props.errors.currentBaseRent}
                        disabled={isReadOnly || props.disabled}
                    />
                </td>
            </tr>
            <tr>
                <td className='label-cell padding-left-10 padding-right-10'>
                    {t('headings.prop-tax-recoveries')}
                </td>
                <td className={(props.formData.leaseTypeCode === "1" && props.formData.propertyTaxRecovery === undefined) ? "highlighted-cell" : undefined}>
                    <CurrencyInput id="propertyTaxRecovery"
                        value={props.formData.propertyTaxRecovery as number | undefined}
                        onChange={props.handleCurrencyChange}
                        symbol={props.currencySymbol}
                        title={t('tooltips.prop-tax-recoveries-tooltip')}
                        errormessage={props.errors.propertyTaxRecovery}
                        disabled={isReadOnly || props.disabled}
                    />
                </td>
            </tr>
            <tr>
                <td className='label-cell padding-left-10 padding-right-10 no-wrap'>
                    {t('headings.operating-recoveries')}
                </td>
                <td className={(props.formData.leaseTypeCode === "1" && props.formData.operatingRecovery === undefined) ? "highlighted-cell" : undefined}>
                    <CurrencyInput id="operatingRecovery"
                        value={props.formData.operatingRecovery as number | undefined}
                        onChange={props.handleCurrencyChange}
                        symbol={props.currencySymbol}
                        title={t('tooltips.operating-recoveries-tooltip')}
                        errormessage={props.errors.operatingRecovery}
                        disabled={isReadOnly || props.disabled}
                    />
                </td>
            </tr>
            <tr>
                <td className='label-cell padding-left-10 padding-right-10'>
                    {t('headings.tenant-improvements')}
                </td>
                <td className='currency-input-td'>
                    <CurrencyInput id="tenantImprovement"
                        value={props.formData.tenantImprovement as number | undefined}
                        onChange={props.handleCurrencyChange}
                        symbol={props.currencySymbol}
                        title={t('tooltips.tenant-improvements-tooltip')}
                        errormessage={props.errors.tenantImprovement}
                        disabled={isReadOnly || props.disabled}
                    />
                </td>
            </tr>
        </tbody>
    );
}
