import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/root';
import { GetElsLookupsState } from '../../redux/reducers/getLookups/getElsLookupsReducer';
import Dropdown, { Option } from '../common/Dropdown/Dropdown';
import Asterisk from '../common/Asterisk/Asterisk';
import "./CommercialUnit.css";

interface CommercialUnitDropdownFieldProps {
    formData: Record<string, unknown>,
    name: string,
    errors: Record<string, string>,
    heading: string,
    title?: string,
    multiple?: boolean,
    options?: Option[],
    disabled?: boolean
}

export default function CommercialUnitDropdownField(props: CommercialUnitDropdownFieldProps): JSX.Element {
    const elsLookupsState: GetElsLookupsState = useSelector((state: RootState) => state.lookupsReducer.getElsLookups);

    const getValue = () => {
        const value = props.formData[props.name];
        if (props.name === "variables" && Array.isArray(value)) {
            return value.map((v: {code: string}) => v.code);
        }
        return value as string | undefined;
    };

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (props.name === "variables") {
            const selectedOptions = Array.from(e.target.selectedOptions, option => ({
                code: option.value,
                description: option.text
            }));
            props.formData[props.name] = selectedOptions;
        } else {
            props.formData[props.name] = props.multiple
                ? Array.from(e.target.selectedOptions, option => option.value)
                : e.target.value;
        }
    };

    useEffect(() => {
        if (props.multiple && !props.disabled) {
            const selectEl = document.querySelector<HTMLSelectElement>(`select[name="${props.name}"]`);
            if (!selectEl) return;

            const handleMouseDown = (e: MouseEvent) => {
                const optionElement = e.target as HTMLOptionElement;
                if (optionElement && optionElement.tagName.toLowerCase() === 'option') {
                    e.preventDefault();
                    const select = optionElement.closest('select');
                    if (!select) return;
                    
                    const originalScrollTop = select.scrollTop;
                    
                    optionElement.selected = !optionElement.selected;
                    
                    select.focus();
                    
                    setTimeout(() => {
                        select.scrollTop = originalScrollTop;
                    }, 0);
                    
                    const event = new Event('change', { bubbles: true });
                    select.dispatchEvent(event);
                }
            };

            selectEl.addEventListener('mousedown', handleMouseDown);

            return () => {
                selectEl.removeEventListener('mousedown', handleMouseDown);
            };
        }
    }, [props.name, props.multiple, props.disabled]);

    const options: Option[] = props.options 
        ? props.options 
        : (([{value: undefined, text: ""}] as Option[]).concat(elsLookupsState.dropdowns[props.name + "Options"] as Option[]));

    const filteredOptions = options.filter(opt => opt && opt.value !== "");

    return (
        <>
            <tr>
                <td className='label-cell'>
                    {props.heading} <Asterisk />
                </td>
            </tr>
            <tr>
                <td>
                    <Dropdown name={props.name}
                              options={filteredOptions}
                              value={getValue()}
                              onChange={handleChange}
                              className='border'
                              title={props.title}
                              errorMessage={props.errors[props.name]}
                              hideErrorCross={true}
                              ariaLabel={props.heading}
                              testid={props.name + "-dropdown"}
                              multiple={props.multiple} 
                              disabled={props.disabled} />
                </td>
            </tr>
        </>
    );
}
