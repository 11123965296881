import React, { useEffect } from 'react';
import "./GuidedExperienceWizard.css";
import { useTranslation } from 'react-i18next';
import DataTable from '../common/DataTable/DataTable';
import Dropdown, { Option } from '../common/Dropdown/Dropdown';
import { GetElsLookupsState } from '../../redux/reducers/getLookups/getElsLookupsReducer';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/root';
import Input from '../common/Input/Input';
import Button from '../common/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';

interface UpdateImportWizardProps {
    updateWizardFunction : (e: React.ChangeEvent<Element> | string | undefined, i: number, f: string, n?: boolean, c?: boolean) => void;
    removeUnit: (index: number) => void;
    tenantList: Record<string, unknown>[];
    floorDescriptionLookup: Record<string, unknown>;
    leaseStatusLookup: Record<string, unknown>;
    confirmedArr: number[];
    confirmedArrSeparated: boolean;
    setConfirmedArrSeparated: (value: boolean) => void;
    setStepUpArr: (value: number[]) => void;
    setTenantList: (value: Record<string, unknown>[]) => void;
    setConfirmedTenants: (value: Record<string, unknown>[]) => void;
    setTotalPages: (value: number | undefined) => void;
    // for pagination
    totalPages: number | undefined;
    currentPage: number;
    changePage: (e: number) => void;
}

export default function UpdateImportWizard(props: UpdateImportWizardProps): JSX.Element {
    const { t } = useTranslation("guided_wizard");
    const elsLookupsState: GetElsLookupsState = useSelector((state: RootState) => state.lookupsReducer.getElsLookups);

    useEffect(() => {
        if (!props.confirmedArrSeparated) {
            const confirmedTenantList: Record<string, unknown>[] = [];
            const otherTenantList: Record<string, unknown>[] = [];
            const stepUpArr: number[] = [];
            props.tenantList.forEach((tenant, i) => {
                if (props.confirmedArr.indexOf(i) < 0){
                    otherTenantList.push(tenant);
                    if ((tenant["stepUpDate1"] !== undefined && tenant["stepUpDate1"] !== "" && tenant["stepUpDate1"] !== null) ||
                        (tenant["stepUpRate1"] !== undefined && tenant["stepUpRate1"] !== "" && tenant["stepUpRate1"] !== null) ||
                        (tenant["stepUpDate2"] !== undefined && tenant["stepUpDate2"] !== "" && tenant["stepUpDate2"] !== null) ||
                        (tenant["stepUpRate2"] !== undefined && tenant["stepUpRate2"] !== "" && tenant["stepUpRate2"] !== null) ||
                        (tenant["stepUpDate3"] !== undefined && tenant["stepUpDate3"] !== "" && tenant["stepUpDate3"] !== null) ||
                        (tenant["stepUpRate3"] !== undefined && tenant["stepUpRate3"] !== "" && tenant["stepUpRate3"] !== null) ||
                        (tenant["stepUpDate4"] !== undefined && tenant["stepUpDate4"] !== "" && tenant["stepUpDate4"] !== null) ||
                        (tenant["stepUpRate4"] !== undefined && tenant["stepUpRate4"] !== "" && tenant["stepUpRate4"] !== null)) {
                            stepUpArr.push(otherTenantList.length - 1);
                        }
                } else {
                    confirmedTenantList.push(tenant);
                }
            });
            props.setStepUpArr(stepUpArr);
            props.setTenantList(otherTenantList);
            props.setConfirmedTenants(confirmedTenantList);
            if (otherTenantList.length > 10) {
                props.setTotalPages(Math.ceil(otherTenantList.length / 10));
                props.changePage(1);
            } else {
                props.setTotalPages(undefined);
            }
            props.setConfirmedArrSeparated(true);
        }
        // eslint-disable-next-line
    }, []);

    const headings = [<th className='small-column' key="update-import-wizard-heading-1">{t("unit-heading.floor-level")}</th>,
        <th key="update-import-wizard-heading-2">{t("unit-heading.tenant-name")}</th>,
        <th key="update-import-wizard-heading-3">{t("unit-heading.unit-number")}</th>,
        <th key="update-import-wizard-heading-4">{t("unit-heading.gla")}</th>,
        <th key="update-import-wizard-heading-5"></th>]

        const buildTableContent = (): JSX.Element => {
            const tableBody: JSX.Element[] = [];
            for (let index = 10 * (props.currentPage - 1); index < Math.min(props.tenantList.length, 10 * props.currentPage); index++) {
                tableBody.push (<tr key={`update-import-wizard-row-${index}`}>
                    <td>
                        <Dropdown name={`update-import-wizard-${index}-floor-level`}
                        options={elsLookupsState.dropdowns["floorLevelOptions"] as Option[]}
                        value={props.tenantList[index]["floorLevel"] as string}
                        ariaLabel={`update-import-wizard-${index}-floor-level`}
                        className='marginless'
                        onChange={(e) => props.updateWizardFunction(e, index, "floorLevel")} /></td>
                    <td><Input type='text' id={`update-import-wizard-${index}-tenant-name`} name={`update-import-wizard-${index}-tenant-name`} ariaLabel={`update-import-wizard-${index}-tenant-name`}
                        onChange={(e) => props.updateWizardFunction(e, index, "tenantName")} value={props.tenantList[index]["tenantName"] as string} /></td>
                    <td><Input type='text' id={`update-import-wizard-${index}-unit-number`} name={`update-import-wizard-${index}-unit-number`} ariaLabel={`update-import-wizard-${index}-unit-number`}
                        onChange={(e) => props.updateWizardFunction(e, index, "tenantUnitNumber")} value={props.tenantList[index]["tenantUnitNumber"] as string} /></td>
                    <td><Input type='number' id={`update-import-wizard-${index}-gla`} name={`update-import-wizard-${index}-gla`} ariaLabel={`update-import-wizard-${index}-gla`}
                        onChange={(e) => props.updateWizardFunction(e, index, "netLeasableArea", true)} value={props.tenantList[index]["netLeasableArea"] as string} /></td>
                    <td className='center-cell'>
                        <Button onClick={() => props.removeUnit(index)} className="remove-button" text={<FontAwesomeIcon icon={faTrashCan} className="trash" />} ariaLabel={t("remove") + " " + index} />
                    </td>
                </tr>)
            }
            return (<>{tableBody}</>);
        }

    return (<div>
        <p>{t("update-import-wizard.update-import-description")}</p>
        <DataTable headings={headings} className='wizard-table' totalPages={props.totalPages} currentPage={props.currentPage} changePage={props.changePage}>
            {buildTableContent()}
        </DataTable>
    </div>)
}