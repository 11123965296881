import React, { useState } from 'react';
import "./GuidedExperienceWizard.css";
import { useTranslation } from 'react-i18next';
import DataTable from '../common/DataTable/DataTable';
import CommercialUnitDatePickerField from '../unit/CommercialUnitDatePickerField';
import { format } from 'date-fns';

interface LeaseTermWizardProps {
    updateWizardFunction : (e: string | undefined, i: number, f: string, n?: boolean) => void;
    tenantList: Record<string, unknown>[];
    floorDescriptionLookup: Record<string, unknown>;
    leaseStatusLookup: Record<string, unknown>;
    // for pagination
    totalPages: number | undefined;
    currentPage: number;
    changePage: (e: number) => void;
}

export default function LeaseTermWizard(props: LeaseTermWizardProps): JSX.Element {
    const { t } = useTranslation("guided_wizard");
    const [currentIndex, setCurrentIndex] = useState(-1);

    const handleDateChange = (date: Date | null | undefined, name: string) => {
        if (typeof name !== "string") {
            return;
        }
        if (date) {
            const dateStr = format(date, "yyyy-MM-dd")
            props.updateWizardFunction(dateStr, currentIndex, name);
        } else {
            props.updateWizardFunction(undefined, currentIndex, name);
        }
    };

    const headings = [<th className='small-column' key="basic-info-wizard-heading-1">{t("unit-heading.floor-level")}</th>,
        <th key="basic-info-wizard-heading-2">{t("unit-heading.tenant-name")}</th>,
        <th key="basic-info-wizard-heading-3">{t("unit-heading.unit-number")}</th>,
        <th key="lease-status-wizard-heading-4">{t("unit-heading.lease-status")}</th>,
        <th className='med-column' key="lease-status-wizard-heading-5">{t("unit-heading.negotiated-date")}</th>,
        <th className='med-column' key="lease-status-wizard-heading-6">{t("unit-heading.start-date")}</th>,
        <th className='med-column' key="lease-status-wizard-heading-7">{t("unit-heading.end-date")}</th>]

        const buildTableContent = (): JSX.Element => {
            const tableBody: JSX.Element[] = [];
            for (let index = 10 * (props.currentPage - 1); index < Math.min(props.tenantList.length, 10 * props.currentPage); index++) {
                tableBody.push (<tr key={`basic-info-wizard-row-${index}`}>
                    <td className='no-input-cell'><label>{props.floorDescriptionLookup[props.tenantList[index]["floorLevel"] as string] as string}</label></td>
                    <td className='no-input-cell tenant-name-cell'><p>{props.tenantList[index]["tenantName"] as string}</p></td>
                    <td className='no-input-cell tenant-unit-cell'><p>{props.tenantList[index]["tenantUnitNumber"] as string}</p></td>
                    <td className='no-input-cell'><p>{props.leaseStatusLookup[props.tenantList[index]["leaseStatusCode"] as string] as string}</p></td>
                    <td className="wizard-grey-out-cell" onFocus={() => setCurrentIndex(index)}>{(props.tenantList[index]["leaseStatusCode"] as string) !== "1" && 
                        <CommercialUnitDatePickerField name='negotiatedDate' formData={props.tenantList[index]}
                        onChange={handleDateChange} asterisk={false} errors={{}} id={`lease-term-wizard-${index}-negotiated-date`} />
                    }</td>
                    <td className="wizard-grey-out-cell" onFocus={() => setCurrentIndex(index)}>{(props.tenantList[index]["leaseStatusCode"] as string) !== "1" && 
                        <CommercialUnitDatePickerField name='startDate' formData={props.tenantList[index]}
                        onChange={handleDateChange} asterisk={false} errors={{}} id={`lease-term-wizard-${index}-start-date`} />
                    }</td>
                    <td className="wizard-grey-out-cell" onFocus={() => setCurrentIndex(index)}>{!["1", "5"].includes(props.tenantList[index]["leaseStatusCode"] as string) && 
                        <CommercialUnitDatePickerField name='endDate' formData={props.tenantList[index]}
                        onChange={handleDateChange} asterisk={false} errors={{}} id={`lease-term-wizard-${index}-end-date`} />
                    }</td>
                </tr>)
            }
            return (<>{tableBody}</>);
        }

    return (<div>
        <p>{t("lease-term-wizard.lease-term-description")}</p>
        <DataTable headings={headings} className='wizard-table' totalPages={props.totalPages} currentPage={props.currentPage} changePage={props.changePage}>
            {buildTableContent()}
        </DataTable>
    </div>)
}