import React, { useEffect, useState } from 'react';
import "./GuidedExperienceWizard.css";
import { useTranslation } from 'react-i18next';
import DataTable from '../common/DataTable/DataTable';
import CommercialUnitDatePickerField from '../unit/CommercialUnitDatePickerField';
import { format } from 'date-fns';

interface VacancyStartWizardProps {
    updateWizardFunction : (e: string | undefined, i: number, f: string, n?: boolean) => void;
    tenantList: Record<string, unknown>[];
    floorDescriptionLookup: Record<string, unknown>;
    leaseStatusLookup: Record<string, unknown>;
}

export default function VacancyStartWizard(props: VacancyStartWizardProps): JSX.Element {
    const { t } = useTranslation("guided_wizard");
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [totalPages, setTotalPages] = useState<number | undefined>(undefined);
    const [currentPage, setCurrentPage] = useState(1);

    const handleDateChange = (date: Date | null | undefined, name: string) => {
        if (typeof name !== "string") {
            return;
        }
        if (date) {
            const dateStr = format(date, "yyyy-MM-dd")
            props.updateWizardFunction(dateStr, currentIndex, name);
        } else {
            props.updateWizardFunction(undefined, currentIndex, name);
        }
    };

    useEffect(() => {
        const filteredTenantList = props.tenantList.filter((tenant) => tenant["leaseStatusCode"] === "1");
        if (filteredTenantList.length > 10) {
            setTotalPages(Math.ceil(filteredTenantList.length / 10));
        }
        // eslint-disable-next-line
    }, []);

    const headings = [<th className='small-column' key="basic-info-wizard-heading-1">{t("unit-heading.floor-level")}</th>,
        <th key="basic-info-wizard-heading-2">{t("unit-heading.tenant-name")}</th>,
        <th key="basic-info-wizard-heading-3">{t("unit-heading.unit-number")}</th>,
        <th key="lease-status-wizard-heading-4">{t("unit-heading.lease-status")}</th>,
        <th key="lease-status-wizard-heading-5">{t("unit-heading.vacancy-start-date")}</th>]

        const buildTableContent = (): JSX.Element => {
            const tableBody: JSX.Element[] = [];
            const indices = props.tenantList.map((_, index) => index);
            const filteredIndices = indices.filter((index) => props.tenantList[index]["leaseStatusCode"] === "1");
            for (let index = 10 * (currentPage - 1); index < Math.min(filteredIndices.length, 10 * currentPage); index++) {
                tableBody.push (<tr key={`basic-info-wizard-row-${index}`}>
                    <td className='no-input-cell'><label>{props.floorDescriptionLookup[props.tenantList[filteredIndices[index]]["floorLevel"] as string] as string}</label></td>
                    <td className='no-input-cell tenant-name-cell'><p>{props.tenantList[filteredIndices[index]]["tenantName"] as string}</p></td>
                    <td className='no-input-cell tenant-unit-cell'><p>{props.tenantList[filteredIndices[index]]["tenantUnitNumber"] as string}</p></td>
                    <td className='no-input-cell'><p>{props.leaseStatusLookup[props.tenantList[filteredIndices[index]]["leaseStatusCode"] as string] as string}</p></td>
                    <td onFocus={() => setCurrentIndex(filteredIndices[index])}>
                        <CommercialUnitDatePickerField name='startDate' formData={props.tenantList[filteredIndices[index]]}
                            onChange={handleDateChange} asterisk={false} errors={{}} id={`start-date-${filteredIndices[index]}`} />
                    </td>
                </tr>)
            }
            return (<>{tableBody}</>);
        }

    return (<div>
        <p>{t("vacancy-start-wizard.vacancy-start-description")}</p>
        <DataTable headings={headings} className='wizard-table' totalPages={totalPages} currentPage={currentPage} changePage={setCurrentPage}>
            {buildTableContent()}
        </DataTable>
    </div>)
}