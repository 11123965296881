
import { SET_ACTIVE_MENU_ITEM, SET_MENU, SET_NEXT_ACTIVE_MENU_ITEM, SET_PREV_ACTIVE_MENU_ITEM, UPDATE_MENU_ITEM_STATUS } from "../../actionTypes/actionsTypes";
import { LocalAction } from "../../types";
import MenuItem from "../../../types/menu/MenuItem";

export const setMenu = (menu: MenuItem[]): LocalAction => ({
  type: SET_MENU,
  data: menu
});

export const setActiveMenuItem = (item: MenuItem): LocalAction => {
  let newItem = item;
  if (item.componentName) {
    newItem = item;
  } else if (item.subItems && item.subItems.length > 0) {
    newItem = item.subItems[0];
  }

  // if (newItem.hasTabs && newItem.subItems && newItem.subItems.length > 0
  //   && (newItem.selectedTabIndex === null || newItem.selectedTabIndex === undefined)) {
  //   newItem = { ...newItem, selectedTabIndex: 0 };
  // }

  return {
    type: SET_ACTIVE_MENU_ITEM,
    data: newItem
  }
};

export const setNextActiveMenuItem = (item: MenuItem): LocalAction => {
  return {
    type: SET_NEXT_ACTIVE_MENU_ITEM,
    data: item
  }
};

export const setPrevActiveMenuItem = (item: MenuItem): LocalAction => {
  return {
    type: SET_PREV_ACTIVE_MENU_ITEM,
    data: item
  }
};

export const updateMenuItemStatus = (id: number, complete: boolean): LocalAction => ({
  type: UPDATE_MENU_ITEM_STATUS,
  data: {
    'id': id,
    'complete': complete
  }
});

