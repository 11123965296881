import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import DataTable from '../../../components/common/DataTable/DataTable';
import TableRow, { TableCellData } from '../../../components/common/TableRow/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers/root';
import { SaveDataState } from '../../../redux/reducers/getSaveData/saveDataReducer';
import { resetSaving } from '../../../redux/actions/saveData/saveData';
import { getProfileAttributes, putProfileAttributes, resetGetProfileAttributes } from '../../../redux/actions/profile/profile';
import { ProfileOverviewState } from '../../../redux/reducers/getProfile/getProfileOverviewReducer';
import { toast } from 'react-toastify';
import { SaveResultState } from '../../../redux/reducers/getSaveData/saveResultReducer';
import { setNextActiveMenuItem } from '../../../redux/actions/menu/menu';
import { initialMenuItem } from '../../../redux/reducers/getMenu/getMenuReducer';
import PropertyData from '../../../types/parkingProperty/PropertyData';
import { ProfileAttributesState } from '../../../redux/reducers/getProfile/profileAttributes/getAttributesReducer';
import './VacantAskingRate.css'

function VacantAskingRate() {
  const { t } = useTranslation('parking_property');

  const initialPropertyData: PropertyData = {
    "officeOccupiedArea": null,
    "retailOccupiedArea": null,
    "warehouseStorageOccupiedArea": null,
    "manufactureOtherIndustrialUseOccupiedArea": null,
    "officeVacantArea": null,
    "retailVacantArea": null,
    "warehouseStorageVacantArea": null,
    "manufactureOtherIndustrialUseVacantArea": null,
    "officeVacantRate": null,
    "retailVacantRate": null,
    "warehouseStorageVacantRate": null,
    "manufactureOtherIndustrialUseVacantRate": null,
  }

  const propertyHeadings = [
    <th id='property-space-type' className='pier-datatable-clear-th' key="property-column-1-header">{t("propertyTableHeaderSpaceType")}</th>,
    <th id='property-office' className='pier-datatable-clear-th' key="property-column-2-header">{t("propertyTableHeaderOffice")}</th>,
    <th id='property-retail' className='pier-datatable-clear-th' key="property-column-3-header">{t("propertyTableHeaderRetail")}</th>,
    <th id='property-warehouse' className='pier-datatable-clear-th' key="property-column-4-header">{t("propertyTableHeaderWarehouse")}</th>,
    <th id='property-manufacturing' className='pier-datatable-clear-th' key="property-column-5-header">{t("propertyTableHeaderManufacturing")}</th>,
  ]

  const propertyVacantAskingRow: TableCellData[] = [
    { name: "officeVacantRate", type: "float", symbol: '$', header: "property-office", forced: true, positiveOnly: true, placeholder: "($/sf)" },
    { name: "retailVacantRate", type: "float", symbol: '$', header: "property-retail", forced: true, positiveOnly: true, placeholder: "($/sf)" },
    { name: "warehouseStorageVacantRate", type: "float", symbol: '$', header: "property-warehouse", forced: true, positiveOnly: true, placeholder: "($/sf)" },
    { name: "manufactureOtherIndustrialUseVacantRate", type: "float", symbol: '$', header: "property-manufacturing", forced: true, positiveOnly: true, placeholder: "($/sf)" },
  ]

  const dispatch = useDispatch();
  const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
  const profileAttributes: ProfileAttributesState = useSelector((state: RootState) => state.profileReducer.getProfileAttributes as ProfileAttributesState)
  const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData);
  const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult);
  const [propertyData, setPropertyData] = useState<PropertyData>(initialPropertyData as PropertyData)
  const [hasErrors, setHasErrors] = useState(false);
  const [rowErrors, setRowErrors] = useState({
    parkingDaily: false,
    parkingMonthly: false,
    parkingReserved: false,
    propertyOccupied: false,
    propertyVacant: false,
    propertyVacantAsking: false
  });

  const handleErrorsChange = (row: keyof typeof rowErrors, hasErrorsInRow: boolean) => {
    setRowErrors(prevState => {
      if (prevState[row] !== hasErrorsInRow) {
        return {
          ...prevState,
          [row]: hasErrorsInRow
        };
      }
      return prevState
    })
  }

  useEffect(() => {
    dispatch(resetGetProfileAttributes())
    dispatch(getProfileAttributes(profileOverview.profile.profile_type_code, Number(localStorage.getItem('currentSnapshotId') as string)))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (profileAttributes.data && !profileAttributes.loading) {
      setPropertyData({
        officeOccupiedArea: profileAttributes.data.officeOccupiedArea,
        retailOccupiedArea: profileAttributes.data.retailOccupiedArea,
        warehouseStorageOccupiedArea: profileAttributes.data.warehouseStorageOccupiedArea,
        manufactureOtherIndustrialUseOccupiedArea: profileAttributes.data.manufactureOtherIndustrialUseOccupiedArea,
        officeVacantArea: profileAttributes.data.officeVacantArea,
        retailVacantArea: profileAttributes.data.retailVacantArea,
        warehouseStorageVacantArea: profileAttributes.data.warehouseStorageVacantArea,
        manufactureOtherIndustrialUseVacantArea: profileAttributes.data.manufactureOtherIndustrialUseVacantArea,
        officeVacantRate: profileAttributes.data.officeVacantRate,
        retailVacantRate: profileAttributes.data.retailVacantRate,
        warehouseStorageVacantRate: profileAttributes.data.warehouseStorageVacantRate,
        manufactureOtherIndustrialUseVacantRate: profileAttributes.data.manufactureOtherIndustrialUseVacantRate,
      })
    }
  }, [profileAttributes.data, profileAttributes.loading])

  useEffect(() => {
    const hasErrors = Object.values(rowErrors).some(error => error === true)
    setHasErrors(hasErrors)
  }, [rowErrors])

  const handlePropertyChange = (name: keyof PropertyData, value: string | boolean | number | null) => {
    setPropertyData(oldParkingData => ({
      ...oldParkingData,
      [name]: value
    }))
  }

  useEffect(() => {
    if (saveData.saving) {
      if (hasErrors) {
        toast.error("Error going to next page: Values must be positive.")
        dispatch(resetSaving())
        dispatch(setNextActiveMenuItem(initialMenuItem))
        return
      }

      const data = { ...profileAttributes.data, ...propertyData } as Record<string, unknown>
      dispatch(putProfileAttributes(profileOverview.profile.profile_type_code, Number(localStorage.getItem('currentSnapshotId') as string), data))
    }
  }, [dispatch, hasErrors, profileAttributes.data, profileOverview.profile.profile_type_code, propertyData, saveData.saving])

  useEffect(() => {
    if (saveResultState.status === 200) {
      dispatch(getProfileAttributes(profileOverview.profile.profile_type_code, Number(localStorage.getItem('currentSnapshotId') as string)))
    }
  }, [dispatch, profileOverview.profile.profile_type_code, saveResultState.status])

  return (
    <div className='partial-width'>
      <div className='property-section'>
        <h3>{t("propertyHeader")}</h3>
        <DataTable
          title={t("propertyTableTitle")}
          headings={propertyHeadings}>
          <TableRow rowLabelHeader='property-space-type' rowLabel={t("propertyTableRowVacantAsking")} cellData={propertyVacantAskingRow} dataType={propertyData} onChange={handlePropertyChange} onErrorsChange={(hasErrors) => handleErrorsChange('propertyVacantAsking', hasErrors)} />
        </DataTable>
      </div>
    </div>
  )
}

export default VacantAskingRate