import React from 'react';
import "./GuidedExperienceWizard.css";
import { useTranslation } from 'react-i18next';
import DataTable from '../common/DataTable/DataTable';
import CurrencyInput from '../common/CurrencyInput/CurrencyInput';
import { GlobalSettingState } from '../../redux/reducers/getGlobalSetting/getGlobalSettingReducer';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/root';

interface LeaseRateWizardProps {
    updateWizardFunction : (e: string | undefined, i: number, f: string, n?: boolean) => void;
    tenantList: Record<string, unknown>[];
    floorDescriptionLookup: Record<string, unknown>;
    unitSubtypeLookup: Record<string, unknown>;
    // for pagination
    totalPages: number | undefined;
    currentPage: number;
    changePage: (e: number) => void;
}

export default function LeaseRateWizard(props: LeaseRateWizardProps): JSX.Element {
    const { t } = useTranslation("guided_wizard");
    const globalSettingState: GlobalSettingState = useSelector((state: RootState) => state.globalSettingReducer.globalSetting);
    const currencySymbol = (globalSettingState.setting["currency"] as Record<string, unknown>)["symbol"] as string;

    const handleCurrencyChange = (value: number | undefined | null, name: string, _oldAmt: number | undefined | null) => {
        const nameArr = name.split("-");
        props.updateWizardFunction((value === null || value === undefined) ? undefined : value.toString(), parseInt(nameArr[1]), nameArr[0], true);
    };

    const headings = [<th className='small-column' key="lease-rate-wizard-heading-1">{t("unit-heading.floor-level")}</th>,
        <th key="lease-rate-wizard-heading-2">{t("unit-heading.tenant-name")}</th>,
        <th key="lease-rate-wizard-heading-3">{t("unit-heading.unit-number")}</th>,
        <th key="lease-rate-wizard-heading-4">{t("unit-heading.unit-use")}</th>,
        <th key="lease-rate-wizard-heading-5">{t("unit-heading.current-rent")}</th>,
        <th key="lease-rate-wizard-heading-6">{t("unit-heading.tax-recovery")}</th>,
        <th key="lease-rate-wizard-heading-7">{t("unit-heading.operating-recovery")}</th>,
        <th key="lease-rate-wizard-heading-8">{t("unit-heading.tenant-improvement")}</th>]

        const buildTableContent = (): JSX.Element => {
            const tableBody: JSX.Element[] = [];

            for (let index = 10 * (props.currentPage - 1); index < Math.min(props.tenantList.length, 10 * props.currentPage); index++) {
                tableBody.push (<tr key={`lease-rate-wizard-row-${index}`}>
                    <td className='no-input-cell'><label>{props.floorDescriptionLookup[props.tenantList[index]["floorLevel"] as string] as string}</label></td>
                    <td className='no-input-cell tenant-name-cell'><p>{props.tenantList[index]["tenantName"] as string}</p></td>
                    <td className='no-input-cell tenant-unit-cell'><p>{props.tenantList[index]["tenantUnitNumber"] as string}</p></td>
                    <td className='no-input-cell'><p>{props.unitSubtypeLookup[props.tenantList[index]["unitSubtypeCode"] as string] as string}</p></td>
                    <td className='no-padding-cell wizard-grey-out-cell'>
                        {(props.tenantList[index]["leaseStatusCode"] as string) !== "1" && <CurrencyInput name={`currentBaseRent-${index}`} id={`lease-rate-wizard-${index}-current-rent`}
                        value={props.tenantList[index]["currentBaseRent"] as number | undefined}
                        onChange={handleCurrencyChange} 
                        placeholder={"(" + currencySymbol + ")"} />}</td>
                    <td className='no-padding-cell wizard-grey-out-cell'>
                        {(props.tenantList[index]["leaseStatusCode"] as string) !== "1" && <CurrencyInput name={`propertyTaxRecovery-${index}`} id={`lease-rate-wizard-${index}-tax-recovery`}
                        value={props.tenantList[index]["propertyTaxRecovery"] as number | undefined}
                        onChange={handleCurrencyChange} 
                        placeholder={"(" + currencySymbol + ")"} />}</td>
                    <td className='no-padding-cell wizard-grey-out-cell'>
                        {(props.tenantList[index]["leaseStatusCode"] as string) !== "1" && <CurrencyInput name={`operatingRecovery-${index}`} id={`lease-rate-wizard-${index}-operating-recovery`}
                        value={props.tenantList[index]["operatingRecovery"] as number | undefined}
                        onChange={handleCurrencyChange} 
                        placeholder={"(" + currencySymbol + ")"} />}</td>
                    <td className='no-padding-cell wizard-grey-out-cell'>
                        {(props.tenantList[index]["leaseStatusCode"] as string) !== "1" && <CurrencyInput name={`tenantImprovement-${index}`} id={`lease-rate-wizard-${index}-tenant-improvement`}
                        value={props.tenantList[index]["tenantImprovement"] as number | undefined}
                        onChange={handleCurrencyChange} 
                        placeholder={"(" + currencySymbol + ")"} />}</td>
                </tr>)
            }
            return (<>{tableBody}</>);
        }

    return (<div>
        <p>{t("lease-rate-wizard.lease-rate-description")}</p>
        <DataTable headings={headings} className='wizard-table' totalPages={props.totalPages} currentPage={props.currentPage} changePage={props.changePage}>
            {buildTableContent()}
        </DataTable>
    </div>)
}