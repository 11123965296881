import React from 'react';
import "../GuidedExperience/GuidedExperienceWizard.css";
import { useTranslation } from 'react-i18next';
import Checkbox from '../common/Checkbox/Checkbox';

interface ApartmentStyleWizardProps {
    apartmentStyles: Record<string, unknown>[];
    apartmentStyleArray: Record<string, unknown>;
    setApartmentStyleArray: (value: Record<string, unknown>) => void;
}

export default function ApartmentStyleWizard(props: ApartmentStyleWizardProps): JSX.Element {
    const { t } = useTranslation("guided_wizard");

    return (<div>
        <p>{t("apartment-style-wizard.apartment-style-description")}</p>
        <table className='wizard-table apartment-table'>
            <tbody>
                {props.apartmentStyles.map((apartmentStyle, index) => (
                    <tr key={`apartment-style-wizard-row-${index}`}>
                        <td><label htmlFor={`apartment-style-wizard-${apartmentStyle.unitStyleCode}`}>{apartmentStyle.unitStyleDescription as string}</label></td>
                        <td className='center-cell'>
                            <Checkbox name={`apartment-style-wizard-${apartmentStyle.unitStyleCode}`} id={`apartment-style-wizard-${apartmentStyle.unitStyleCode}`}
                                defaultChecked={props.apartmentStyleArray[apartmentStyle.unitStyleCode as string] as boolean} onChange={() => 
                                props.setApartmentStyleArray({...props.apartmentStyleArray, [apartmentStyle.unitStyleCode as string]: !(props.apartmentStyleArray[apartmentStyle.unitStyleCode as string] as boolean)})}/>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>)
}