import React, { useEffect, useState } from 'react';
import GEVInput from './GEVInput';
import GEVYesNo from './GEVYesNo';
import GEVTexttarea from './GEVTexttarea';
import GEVGrainLicenseType from './GEVGrainLicenseType';
import GEVPerCrop from './GEVPerCrop';
import GEVPercentageOfThroughput from './GEVPercentageOfThroughput';
import { OperationProps } from '../../types/gev/BusinessOperationFormProps';
import { FormError, useFormErrorContext } from '../../utils/context/FormErrorContext';
import ErrorMessages from '../common/ErrorMessages/ErrorMessages';
import { BusinessOperationsKeys } from '../../pages/BusinessOperations/BusinessOperations';

export default function FarmingOperationCommercialGrain(props: OperationProps): JSX.Element {
    // useEffect(() => {
    //     if (props.validating) {
    //         const err: Record<string, string | Record<string, string>> = {}
    //         const grainLicenseType = data["grainLicenseType"] as Record<string, boolean | null>
    //         if (grainLicenseType &&
    //             !Object.keys(grainLicenseType).some((option) => grainLicenseType[option] === true)) {
    //             err["grainLicenseType"] = t("missingRequiredField")
    //         }
    //         if (!data["licenseRegisteredTo"]) {
    //             err["licenseRegisteredTo"] = t("missingRequiredField")
    //         }
    //         if (data["licenseRegisteredTo"] && data["licenseRegisteredTo"].toString().length > 100) {
    //             err["licenseRegisteredTo"] = t("max-100-chars")
    //         }
    //         if (data["isLicenseInUse"] === null) {
    //             err["isLicenseInUse"] = t("missingRequiredField")
    //         }
    //         if (!data["totalBuschelCapacity"]) {
    //             err["totalBuschelCapacity"] = t("missingRequiredField")
    //         }
    //         if (data["totalBuschelCapacity"] && data["totalBuschelCapacity"].toString().length > 15) {
    //             err["totalBuschelCapacity"] = t("max-15-digits")
    //         }
    //         if (!data["totalAcresCropped"]) {
    //             err["totalAcresCropped"] = t("missingRequiredField")
    //         }
    //         if (data["totalAcresCropped"] && data["totalAcresCropped"].toString().length > 15) {
    //             err["totalAcresCropped"] = t("max-15-digits")
    //         }
    //         const acresPerCrop = data["acresPerCrop"] as Record<string, number | string | null>
    //         if (!Object.keys(initialPerCrop).concat("other").some((key) => acresPerCrop[key] !== null)) {
    //             err["acresPerCrop"] = { "options": t("missingRequiredField") }
    //         } else {
    //             const acresPerCropErr: Record<string, string> = {}
    //             Object.keys(initialPerCrop).forEach((key) => {
    //                 if (acresPerCrop[key] !== null && acresPerCrop[key].toString().length > 15) {
    //                     acresPerCropErr[key] = t("max-15-digits")
    //                 }
    //             })
    //             if (acresPerCrop["other"] !== null && acresPerCrop["other"].toString().length > 100) {
    //                 acresPerCropErr["other"] = t("max-100-chars")
    //             }
    //             if (Object.keys(acresPerCropErr).length) {
    //                 err["acresPerCrop"] = acresPerCropErr;
    //             }
    //         }
    //         const yieldPerCrop = data["yieldPerCrop"] as Record<string, number | string | null>
    //         if (!Object.keys(initialPerCrop).concat("other").some((key) => yieldPerCrop[key] !== null)) {
    //             err["yieldPerCrop"] = { "options": t("missingRequiredField") }
    //         } else {
    //             const yieldPerCropErr: Record<string, string> = {}
    //             Object.keys(initialPerCrop).forEach((key) => {
    //                 if (yieldPerCrop[key] !== null && yieldPerCrop[key].toString().length > 15) {
    //                     yieldPerCropErr[key] = t("max-15-digits")
    //                 }
    //             })
    //             if (yieldPerCrop["other"] !== null && yieldPerCrop["other"].toString().length > 100) {
    //                 yieldPerCropErr["other"] = t("max-100-chars")
    //             }
    //             if (Object.keys(yieldPerCropErr).length) {
    //                 err["yieldPerCrop"] = yieldPerCropErr;
    //             }
    //         }
    //         if (data["isTruckScaleOnSite"] === null) {
    //             err["isTruckScaleOnSite"] = t("missingRequiredField")
    //         }
    //         if (data["isTruckScaleOnSite"] && !data["truckScaleCapacity"]) {
    //             err["truckScaleCapacity"] = t("missingRequiredField")
    //         }
    //         if (data["truckScaleCapacity"] && data["truckScaleCapacity"].toString().length > 100) {
    //             err["truckScaleCapacity"] = t("max-100-chars")
    //         }
    //         if (data["areAnyBuildingsAddedOrRemoved"] === null) {
    //             err["areAnyBuildingsAddedOrRemoved"] = t("missingRequiredField")
    //         }
    //         if (data["areAnyBuildingsAddedOrRemoved"] && !data["comments"]) {
    //             err["comments"] = t("missingRequiredField")
    //         }
    //         if (data["comments"] && data["comments"].toString().length > 200) {
    //             err["comments"] = t("max-200-chars")
    //         }
    //         props.validate(operation)
    //         props.setData(operation, data)
    //         if (Object.keys(err).length) {
    //             props.setErrors(operation, err)
    //         }
    //     }
    // }, [data, props, t])
    const { errors, removeError} = useFormErrorContext();
    const [formErrors, setFormErrors] = useState<FormError[]>([]);
    const { operation, incrementErrCount, resetErrCount } = props
    useEffect(() => {
        if (errors.length > 0) {
            errors.forEach((error) => {
                if (error.primaryKey && error.primaryKey.split('.')[1] === operation) {
                    const splitKey = error.primaryKey.split('.');
                    splitKey.shift();
                    error.primaryKey = splitKey.join(".");
                    setFormErrors(prevData => [...prevData, error])
                    incrementErrCount(operation as BusinessOperationsKeys)
                }
            })
        }
        else {
            setFormErrors([]);
        }
    }, [errors, operation, incrementErrCount]);

    const removeFormError = (field: string, linkedField?: string) => {
        const error = formErrors.find(err => err.primaryKey.includes(field))
        if (error !== undefined) {
            removeError(error.primaryKey);
            const filteredFormError = formErrors.filter(err => !(err.primaryKey.includes(field)))
            if (filteredFormError.length === 0) {
                resetErrCount(operation as BusinessOperationsKeys);
            }
            setFormErrors(filteredFormError);
        }
        if (linkedField) {
            removeFormError(linkedField);
        }
    }

    return (
        <div>
            <ErrorMessages errors={formErrors} />
            <form>
                <table width={"100%"} role="none" className='gev-table'>
                    <tbody>
                        <GEVGrainLicenseType
                            index={1}
                            operation={operation}
                            name={'grainLicenseType'}
                            errors={props.errors}
                            hasErrors={formErrors.some(err => err.primaryKey.includes('grainLicenseType'))}
                            removeError={removeFormError}
                        />
                        <GEVInput
                            index={2}
                            operation={operation}
                            name={'licenseRegisteredTo'}
                            errors={props.errors}
                            hasErrors={formErrors.some(err => err.primaryKey.includes('licenseRegisteredTo'))}
                            removeError={removeFormError}
                        />
                        <GEVYesNo
                            index={3}
                            operation={operation}
                            name={'isLicenseInUse'}
                            errors={props.errors}
                            hasErrors={formErrors.some(err => err.primaryKey.includes('isLicenseInUse'))}
                            removeError={removeFormError}
                        />
                        <GEVInput
                            index={4}
                            operation={operation}
                            name={'totalBuschelCapacity'}
                            type="number"
                            errors={props.errors}
                            hasErrors={formErrors.some(err => err.primaryKey.includes('totalBuschelCapacity'))}
                            removeError={removeFormError}
                        />
                        <GEVInput
                            index={5}
                            operation={operation}
                            name={'totalAcresCropped'}
                            type="number"
                            errors={props.errors}
                            hasErrors={formErrors.some(err => err.primaryKey.includes('totalAcresCropped'))}
                            removeError={removeFormError}
                        />
                        <GEVPerCrop
                            index={6}
                            operation={operation}
                            name={'acresPerCrop'}
                            errors={props.errors}
                            hasErrors={formErrors.some(err => err.primaryKey.includes('acresPerCrop'))}
                            removeError={removeFormError}
                        />
                        <GEVPerCrop
                            index={7}
                            operation={operation}
                            name={'yieldPerCrop'}
                            errors={props.errors}
                            removeError={removeFormError}
                        />
                        <GEVYesNo
                            index={8}
                            operation={operation}
                            name={'isTruckScaleOnSite'}
                            errors={props.errors}
                            hasErrors={formErrors.some(err => err.primaryKey.includes('isTruckScaleOnSite'))}
                            removeError={removeFormError}
                        />
                        <GEVInput
                            operation={operation}
                            name={'truckScaleCapacity'}
                            errors={props.errors}
                            hasErrors={formErrors.some(err => err.primaryKey.includes('truckScaleCapacity'))}
                            removeError={removeFormError}
                        />
                        <GEVPercentageOfThroughput
                            index={9}
                            operation={operation}
                            name={'percentageOfThroughput'}
                            errors={props.errors}
                            removeError={removeFormError}
                        />
                        <GEVYesNo
                            index={10}
                            operation={operation}
                            name={'areAnyBuildingsAddedOrRemoved'}
                            errors={props.errors}
                            hasErrors={formErrors.some(err => err.primaryKey.includes('areAnyBuildingsAddedOrRemoved'))}
                            removeError={removeFormError}
                        />
                        <GEVTexttarea
                            operation={operation}
                            name={'comments'}
                            errors={props.errors}
                            hasErrors={formErrors.some(err => err.primaryKey.includes('comments'))}
                            removeError={removeFormError}
                        />
                    </tbody>
                </table>
            </form>
        </div>
    );
}
