import React, { useState } from 'react';
import "./GuidedExperienceWizard.css";
import { useTranslation } from 'react-i18next';
import DataTable from '../common/DataTable/DataTable';
import RadioButton from '../common/RadioGroup/RadioButton';
import Input from '../common/Input/Input';

interface FreeMonthWizardProps {
    updateWizardFunction : (e: string, i: number, f: string, n?: boolean) => void;
    tenantList: Record<string, unknown>[];
    floorDescriptionLookup: Record<string, unknown>;
    hasFreeMonth: boolean | undefined;
    setHasFreeMont: (value: boolean) => void;
    // for pagination
    totalPages: number | undefined;
    currentPage: number;
    changePage: (e: number) => void;
}

export default function FreeMonthWizard(props: FreeMonthWizardProps): JSX.Element {
    const { t } = useTranslation("guided_wizard");
    const [currentIndex, setCurrentIndex] = useState(-1);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const numberOnlyRegex = /^[0-9]+$/;
        if (numberOnlyRegex.test(value)) {
            props.updateWizardFunction(value, currentIndex, "freeRentMonth", true);
        } else if (value === "") {
            props.updateWizardFunction("0", currentIndex, "freeRentMonth", true);
            e.target.value = "0";
        } else {
            e.target.value = props.tenantList[currentIndex]["freeRentMonth"] ? (props.tenantList[currentIndex]["freeRentMonth"] as number).toString() : "0"; 
        }
        
    };

    const headings = [<th className='small-column' key="basic-info-wizard-heading-1">{t("unit-heading.floor-level")}</th>,
        <th key="basic-info-wizard-heading-2">{t("unit-heading.tenant-name")}</th>,
        <th key="basic-info-wizard-heading-3">{t("unit-heading.unit-number")}</th>,
        <th key="basic-info-wizard-heading-4">{t("unit-heading.free-month")}</th>]

        const buildTableContent = (): JSX.Element => {
            const tableBody: JSX.Element[] = [];
            for (let index = 10 * (props.currentPage - 1); index < Math.min(props.tenantList.length, 10 * props.currentPage); index++) {
                tableBody.push (<tr key={`basic-info-wizard-row-${index}`}>
                    <td className='no-input-cell'><label>{props.floorDescriptionLookup[props.tenantList[index]["floorLevel"] as string] as string}</label></td>
                    <td className='no-input-cell tenant-name-cell'><p>{props.tenantList[index]["tenantName"] as string}</p></td>
                    <td className='no-input-cell tenant-unit-cell'><p>{props.tenantList[index]["tenantUnitNumber"] as string}</p></td>
                    <td onFocus={() => setCurrentIndex(index)}  className="wizard-grey-out-cell">
                        {(props.tenantList[index]["leaseStatusCode"] as string) !== "1" && <Input type='text' id={`wizard-free-month-${index}`} name={`wizard-free-month-${index}`} ariaLabel={`wizard-free-month-${index}`}
                            onChange={handleInputChange} value={props.tenantList[index]["freeRentMonth"] ?  (props.tenantList[index]["freeRentMonth"] as number).toString() : "0"} /> }
                    </td>
                </tr>)
            }
            return (<>{tableBody}</>);
        }

    return (<div>
        <div className='wizard-radio-cell wizard-question'>
            <fieldset>
                <legend>{t("free-month-wizard.free-month-question")}</legend>
                <RadioButton name="free-month-question" id="free-month-question-yes" text={t("yes")} textPosition='right' value="true" currentValue={props.hasFreeMonth ?? ""} onChange={() => props.setHasFreeMont(true)} />
                <RadioButton name="free-month-question" id="free-month-question-no" text={t("no")} textPosition='right' value="false" currentValue={props.hasFreeMonth ?? ""} onChange={() => props.setHasFreeMont(false)} />
            </fieldset>
        </div>
        {props.hasFreeMonth === true && <DataTable headings={headings} className='wizard-table' totalPages={props.totalPages} currentPage={props.currentPage} changePage={props.changePage}>
            {buildTableContent()}
        </DataTable>}
    </div>)
}