import React, { useEffect, useState } from 'react';
import "./GuidedExperienceWizard.css";
import { useTranslation } from 'react-i18next';
import DataTable from '../common/DataTable/DataTable';
import Checkbox from '../common/Checkbox/Checkbox';

interface NotExpiredLeaseWizardProps {
    tenantList: Record<string, unknown>[];
    floorDescriptionLookup: Record<string, unknown>;
    leaseStatusLookup: Record<string, unknown>;
    leaseTypeLookup: Record<string, unknown>;
    confirmedArr: number[];
    setConfirmedArr: (value: number[]) => void;
}

export default function NotExpiredLeaseWizard(props: NotExpiredLeaseWizardProps): JSX.Element {
    const { t } = useTranslation("guided_wizard");
    const [totalPages, setTotalPages] = useState<number | undefined>(undefined);
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredIndices, setFilteredIndices] = useState<number[]>([]);

    const checkboxEventHandler = (e: React.ChangeEvent<Element>, index: number) => {
        const newConfirmedArr = [...props.confirmedArr];
        const indexOf = newConfirmedArr.indexOf(index);
        if ((e.target as HTMLInputElement).checked) {
            if (indexOf < 0) {
                newConfirmedArr.push(index);
            }
        } else {
            if (indexOf >= 0) {
                newConfirmedArr.splice(indexOf, 1);
            }
        }
        props.setConfirmedArr(newConfirmedArr.sort());
    }

    useEffect(() => {
        const indices = props.tenantList.map((_, index) => index);
        // const filteredIndiceArr = indices;
        const filteredIndiceArr = indices.filter((index) => ((props.tenantList[index]["endDate"] && new Date(props.tenantList[index]["endDate"] as string) >=  new Date()) || props.tenantList[index]["leaseStatusCode"] === "1" || props.tenantList[index]["leaseStatusCode"] === "5"));
        if (filteredIndiceArr.length > 10) {
            setTotalPages(Math.ceil(filteredIndiceArr.length / 10));
        }
        setFilteredIndices(filteredIndiceArr);
        // eslint-disable-next-line
    }, []);

    const headings = [<th className='small-column' key="not-expired-lease-wizard-heading-1">{t("unit-heading.floor-level")}</th>,
        <th key="not-expired-lease-wizard-heading-2">{t("unit-heading.tenant-name")}</th>,
        <th key="not-expired-lease-wizard-heading-3">{t("unit-heading.unit-number")}</th>,
        <th key="not-expired-lease-wizard-heading-4">{t("unit-heading.gla")}</th>,
        <th key="not-expired-lease-wizard-heading-5">{t("unit-heading.lease-status")}</th>,
        <th key="not-expired-lease-wizard-heading-6">{t("unit-heading.lease-type")}</th>,
        <th key="not-expired-lease-wizard-heading-7">{t("unit-heading.negotiated-date")}</th>,
        <th key="not-expired-lease-wizard-heading-8">{t("unit-heading.start-date")}</th>,
        <th key="not-expired-lease-wizard-heading-9">{t("unit-heading.end-date")}</th>,
        <th key="not-expired-lease-wizard-heading-10">{t("unit-heading.current-rent")}</th>,
        <th key="not-expired-lease-wizard-heading-11">{t("confirm")}</th>]

        const buildTableContent = (): JSX.Element => {
            const tableBody: JSX.Element[] = [];
            for (let index = 10 * (currentPage - 1); index < Math.min(filteredIndices.length, 10 * currentPage); index++) {
                const realIndex = filteredIndices[index];
                tableBody.push (<tr key={`not-expired-lease-wizard-row-${realIndex}`}>
                    <td className='no-input-cell'><label>{props.floorDescriptionLookup[props.tenantList[realIndex]["floorLevel"] as string] as string}</label></td>
                    <td className='no-input-cell tenant-name-cell'><p>{props.tenantList[realIndex]["tenantName"] as string}</p></td>
                    <td className='no-input-cell tenant-unit-cell'><p>{props.tenantList[realIndex]["tenantUnitNumber"] as string}</p></td>
                    <td className='no-input-cell'><p>{props.tenantList[realIndex]["netLeasableArea"] as string}</p></td>
                    <td className='no-input-cell'><p>{props.leaseStatusLookup[props.tenantList[realIndex]["leaseStatusCode"] as string] as string}</p></td>
                    <td className='no-input-cell'><p>{props.leaseTypeLookup[props.tenantList[realIndex]["leaseTypeCode"] as string] as string}</p></td>
                    <td className='no-input-cell'><p>{props.tenantList[realIndex]["negotiatedDate"] as string}</p></td>
                    <td className='no-input-cell'><p>{props.tenantList[realIndex]["startDate"] as string}</p></td>
                    <td className='no-input-cell'><p>{props.tenantList[realIndex]["endDate"] as string}</p></td>
                    <td className='no-input-cell'><p>{props.tenantList[realIndex]["currentBaseRent"] as string}</p></td>
                    <td className='center-cell'>
                        <Checkbox key={`not-expired-lease-wizard-${realIndex}`} name={`not-expired-lease-wizard-${realIndex}`} id={`not-expired-lease-wizard-${realIndex}`}
                            defaultChecked={props.confirmedArr.indexOf(realIndex) >= 0} onChange={(e) => checkboxEventHandler(e, realIndex)}/>
                    </td>
                </tr>)
            }
            return (<>{tableBody}</>);
        }

    return (<div>
        <p>{t("not-expired-lease-wizard.not-expired-lease-description")}</p>
        <DataTable headings={headings} className='wizard-table' totalPages={totalPages} currentPage={currentPage} changePage={setCurrentPage}>
            {buildTableContent()}
        </DataTable>
    </div>)
}