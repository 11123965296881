/**
 * Update: 15/04/2024 - Mariam Bawa - update import statements as part of data type refactoring 
 * Update: 18/04/2024 - Mariam Bawa - Cast ELS Income attribute response to work with updated LookupsState
 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers/root';
import { ProfileOverviewState } from '../../../redux/reducers/getProfile/getProfileOverviewReducer';
import { SaveDataState } from '../../../redux/reducers/getSaveData/saveDataReducer';
import { getProfileAttributes, putProfileAttributes, resetGetProfileAttributes } from '../../../redux/actions/profile/profile';
import ProfileAttributeTableFragmentGenerator from '../../../utils/TableGenerator/ProfileAttributeTableFragmentGenerator';
import { SaveResultState } from '../../../redux/reducers/getSaveData/saveResultReducer';
import { ProfileAttributesState } from '../../../redux/reducers/getProfile/profileAttributes/getAttributesReducer';
import SubsidyData from '../../../types/subsidy/SubsidyData';
import updateProperties from '../../../utils/DataModifier/DataModifier';
import CurrencyInput from '../../../components/common/CurrencyInput/CurrencyInput';
import Input from '../../../components/common/Input/Input';
import Total from '../../../components/common/Total/Total';
import "../PIE.css";
import { ControlledAccordion, useAccordionProvider } from "@szhsin/react-accordion";
import AccordionItem, { DefaultAccordionProviderOptions } from '../../../components/common/AccordionItem/AccordionItem';
import RevenueTab from '../Revenues/RevenueTab';
import { LookupsState } from '../../../redux/reducers/getLookups/lookupsReducer';
import { getLookups } from '../../../redux/actions/lookups/lookups';
import { IncomeAttributeState } from '../../../redux/reducers/getIncomeAttributes/getIncomeAttributesReducer';
import { getIncomeAttributesBySnapshotId, putIncomeAttributesBySnapshotId } from '../../../redux/actions/incomeAttributes/incomeAttributes';
import IncomeAttributeLookupObject from '../../../types/lookups/IncomeAttributeLookupObject';

const initialSubsidyData: SubsidyData = {
  'nursingPersonalCareTotal': null,
  'programSupportServiceTotal': null,
  'rawFoodTotal': null,
  'cchsaTotal': null,
  "globalPierDiem": null,
  'equalizationAdjustmentTotal': null,
  'aggregateSubsidyTotal': null,
  'structuralComplianceFundingTotal': null,
  'municipalTaxAllowanceTotal': null,
  'additionalConstructionFundingTotal': null,
  'subsidyOtherTotal': null,
  'subsidyOtherDescription': null
}
const subsidyKeys = Object.keys(initialSubsidyData)

export default function Subsidy(): JSX.Element {
  const { t } = useTranslation(["ltc_profile_attribute", "pie"])
  const dispatch = useDispatch()

  const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview)
  const profileAttributes: ProfileAttributesState = useSelector((state: RootState) => state.profileReducer.getProfileAttributes as ProfileAttributesState)
  const incomeAttributesState: IncomeAttributeState = useSelector((state: RootState) => state.incomeAttributesReducer.incomeAttributes);
  const lookupState = useSelector((state: RootState) => state.lookupsReducer.getLookups as LookupsState);
  const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData)
  const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult)
  const currentSnapshotId = Number(localStorage.getItem('currentSnapshotId') as string)
  const [subsidyData, setSubsidyData] = useState<SubsidyData>(initialSubsidyData)
  const [totalIncome, setTotalIncome] = useState<number | undefined>(undefined)
  const providerValue = useAccordionProvider(DefaultAccordionProviderOptions)
  const lookupType = "ltc-income-attributes";

  useEffect(() => {
    dispatch(resetGetProfileAttributes())
    dispatch(getProfileAttributes(profileOverview.profile.profile_type_code, currentSnapshotId))
    if ((!incomeAttributesState.loading && !incomeAttributesState.success)
      || incomeAttributesState.snapshotId !== currentSnapshotId) {
      dispatch(getIncomeAttributesBySnapshotId(currentSnapshotId));
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
      if (!lookupState.success) {
        dispatch(getLookups(lookupType));
      }
    }, [dispatch, lookupState.success]);

  useEffect(() => {
    if (!profileAttributes.loading && profileAttributes.data) {
      const initData = { ...initialSubsidyData }
      updateProperties(initData, profileAttributes.data)
      setSubsidyData(initData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileAttributes.data, profileAttributes.loading])

  useEffect(() => {
    if (saveData.saving) {
      const fullData = { ...profileAttributes.data, ...subsidyData }
      dispatch(putProfileAttributes(profileOverview.profile.profile_type_code, currentSnapshotId, fullData));
      const incomeAttributes = (incomeAttributesState.data as Record<string, unknown>[]).filter((data) => data.value || data.description || data.percent);
      dispatch(putIncomeAttributesBySnapshotId(currentSnapshotId, incomeAttributes, "revenue"));
    }
  }, [currentSnapshotId, dispatch, profileAttributes.data, incomeAttributesState.data, profileOverview.profile.profile_type_code, saveData.saving, subsidyData])

  useEffect(() => {
    if (saveResultState.status === 200) {
      dispatch(getProfileAttributes(profileOverview.profile.profile_type_code, currentSnapshotId));
    }
  }, [saveResultState, dispatch, profileOverview.profile.profile_type_code, currentSnapshotId]);

  const handleChange = (newAmt: number | undefined | null, name: string, _oldAmt: number | undefined | null) => {
    const na = (newAmt === undefined || newAmt === null) ? null : newAmt
    setSubsidyData({ ...subsidyData, [name]: na })
  }

  const handleCommentChange = (value: string | undefined, name: string) => {
    setSubsidyData({ ...subsidyData, [name]: value ? value : null })
  }

  const handleIncomeAttrChange = (newAmt: number | undefined | null, name: string, _oldAmt: number | undefined | null) => {
    const idx = name.lastIndexOf("-");
    if (idx !== -1) {
      const code = name.substring(idx + 1);
      const row = (incomeAttributesState.data as Record<string, unknown>[]).find(row => row.code === code);
      const na = newAmt ? newAmt : null;
      if (row) {
        if (code === "159") {
          row["percent"] = na;
        } else {
          row['value'] = na;
        }
      } else {
        if (code === "159") {
          (incomeAttributesState.data as Record<string, unknown>[]).push({
            snapshotId: currentSnapshotId,
            code: code,
            description: null,
            value: null,
            type: "1",
            percent: na,
            year: null,
          });
        } else {
          (incomeAttributesState.data as Record<string, unknown>[]).push({
            snapshotId: currentSnapshotId,
            code: code,
            description: null,
            value: na,
            type: "1",
            percent: null,
            year: null,
          });
        }
      }

      // Update totals
      const newTotal = calculateTotal();
      setTotalIncome(newTotal);
    }
  }

  const handleIncomeAttrCommentChange = (value: string, name: string) => {
    const idx = name.lastIndexOf("-");
    if (idx !== -1) {
      const code = name.substring(idx + 1);
      const incomeAttributesData = (incomeAttributesState.data as Record<string, unknown>[]).find(row => row.code === code);
      const v = value.trim() === "" ? null : value.trim();
      if (incomeAttributesData) {
        incomeAttributesData["description"] = v;
      } else {
        (incomeAttributesState.data as Record<string, unknown>[]).push({
          snapshotId: currentSnapshotId,
          code: code,
          description: v,
          value: null,
          type: "1",
          percent: null,
          year: null,
        });
      }
    }
  }

  const calculateTotal = () => {
    let total = 0;
    let vacancyPercent = 0;
    let filteredAttributes: Record<string, unknown>[] = [];

    if (incomeAttributesState.data) {
      filteredAttributes = (incomeAttributesState.data as Record<string, unknown>[]).filter(row => {
        return (lookupState.lookups[1]["revenue"] as IncomeAttributeLookupObject[]).some(f => {
          return f.incomeAttributeCode === row['code']
        })
      });

      filteredAttributes.forEach(record => {
        if (record['code'] === "159") {
          if (record['percent'] && record['percent'] !== 0) {
            vacancyPercent = (record['percent'] as number);
          }
        } else {
          if (record['value']) {
            total += record['value'] as number
          }
        }
      });
      if (vacancyPercent !== 0) {
        total = total - (total * vacancyPercent / 100);
      }
    }
    subsidyKeys.forEach((key) => {
      const value = subsidyData[key as keyof SubsidyData]
      if (value && typeof value === "number") {
        total = total + (subsidyData[key as keyof SubsidyData] as number ?? 0)
      }
    })
    return total;
  }
  useEffect(() => {
    if (!incomeAttributesState.loading && incomeAttributesState.data && lookupState.success && !incomeAttributesState.loading && incomeAttributesState.data) {
      const total = calculateTotal();
      setTotalIncome(total);
    }
    // eslint-disable-next-line
  }, [incomeAttributesState, incomeAttributesState, lookupState.success, subsidyData]);

  return (
    <div className='partial-width'>
      <>
        <div className="accordion">
          <ControlledAccordion providerValue={providerValue}>
            <AccordionItem
              header={t("basicProvincialSubsidy", { ns: 'navigation' })}
              key={"basicProvincialSubsidy"}
              itemKey={"basicProvincialSubsidy"}
            >
              <table className="table-with-border pie-table accordion-content">
                <thead>
                  <tr>
                    <th id="attribute-label" scope="col"></th>
                    <th id="attribute-value" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {subsidyKeys.map((key) => {
                    if (key.startsWith("subsidyOther")) {
                      if (key === "subsidyOtherTotal") {
                        return (
                          <tr key={key}>
                            <td headers="attribute-label">
                              <Input type="text" name={"subsidyOtherDescription"}
                                id={"subsidyOtherDescription"}
                                placeholder={t('headers.subsidyOtherTotal') + " " + t("comment", { ns: "pie" })}
                                value={subsidyData["subsidyOtherDescription"] as string}
                                autoComplete="off"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCommentChange(e.target.value, e.target.name)}
                              />
                            </td>
                            <td headers="attribute-value">
                              <CurrencyInput name={"subsidyOtherTotal"}
                                onChange={handleChange}
                                value={subsidyData["subsidyOtherTotal"] as number}
                              />
                            </td>
                          </tr>
                        )
                      } else {
                        return null
                      }
                    } else {
                      return <ProfileAttributeTableFragmentGenerator key={key} name={key}
                        data={subsidyData[key as keyof SubsidyData] as number}
                        translationFile='ltc_profile_attribute'
                        valueChangeHandler={handleChange} isRequiredField={false} />
                    }
                  }
                  )
                  }
                </tbody>
              </table>
            </AccordionItem>
            <AccordionItem
              header={t("operatingRevenue", { ns: 'navigation' })}
              key={"revenue"}
              itemKey={"revenue"}
            >
              <RevenueTab handleTotalIncome={handleIncomeAttrChange}
                handleCommentChange={handleIncomeAttrCommentChange}
                name={"revenue"}
                lookups={lookupState.lookups[1]["revenue"] as IncomeAttributeLookupObject[]}
                incomeAttributes={(incomeAttributesState.data && (incomeAttributesState.data as Record<string, unknown>[]).length > 0) ? (incomeAttributesState.data as Record<string, unknown>[]) : undefined}
                lookupType={lookupType} />
            </AccordionItem>
          </ControlledAccordion>
        </div>
        </>
        <div className='total-container'>
          <Total text={t('headers.total')} value={totalIncome} />
        </div>
    </div>
  )
}