import React, { useEffect, useState } from 'react';
import './Summary.css'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/root';
import { GetFullTenantSummaryState } from '../../redux/reducers/getIncomeUnit/getFullTenantSummaryReducer';
import { GetElsLookupsState } from '../../redux/reducers/getLookups/getElsLookupsReducer';
import NewAccordion from '../../components/common/NewAccordion/NewAccordion';

export default function UnitSummary(): JSX.Element {

    const GetFullTenantSummaryState: GetFullTenantSummaryState = useSelector((state: RootState) => state.incomeUnitReducer.getFullTenentSummary);
    const [unitUseCount, setUnitUseCount] = useState<Partial<Record<string, Record<string,unknown>[]>>>({})
    const elsLookupsState: GetElsLookupsState = useSelector((state: RootState) => state.lookupsReducer.getElsLookups);
    const simplifiedUnitSubtypeLookup = ((elsLookupsState.dropdowns.unitSubType1 as Record<string, unknown>[]).reduce((simplifiedLookup, unitSubtype) => 
        ({[unitSubtype["unitSubtypeCode"] as string]: unitSubtype["unitSubtypeDescription"] as string, ...simplifiedLookup}),{"undefined": ""}));
    const { t } = useTranslation('guided_wizard');

    useEffect(() => {
        if (GetFullTenantSummaryState.success && !GetFullTenantSummaryState.loading && GetFullTenantSummaryState.tenantSummary && GetFullTenantSummaryState.tenantSummary.length > 0) {
            setUnitUseCount(Object.groupBy(GetFullTenantSummaryState.tenantSummary, ({ unitSubtypeCode }) => unitSubtypeCode as string));
        }
    }, [GetFullTenantSummaryState]);

    const buildUnitSummary = () => {
        const unitSummary = [];
        for (let i = 0; i < Object.keys(unitUseCount).length; i+= 4) {
            unitSummary.push(<div className='summary-section-container' key={`unit-summary-${i}`}>
                <table className='summary-table summary-table-headerless'>
                    <thead className='invisible-header'><tr><th></th></tr></thead>
                    <tbody>
                    {Object.keys(unitUseCount).slice(i, Math.min(i + 4, Object.keys(unitUseCount).length)).map((key, index)=> (
                        <tr key={"unit-use-row-" + (i + index)}>
                            <td>
                                {key === "-" ? "-" : simplifiedUnitSubtypeLookup[key] as string}
                            </td>
                            <td>
                                {key === "-" ? "-" : (unitUseCount[key] ? unitUseCount[key].length : 0)}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>);
        }
        return unitSummary;
    }

    return (
        <>
            {Object.keys(unitUseCount).length > 0 && 
                <NewAccordion header={t("lease-summary")}><div className='summary-grid'>{buildUnitSummary()}</div></NewAccordion>}
        </>
    )
}