import React, { useEffect, useState } from 'react';
import './Summary.css'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getApartmentTenantSummaryBySnapshotId, getTenantSummaryBySnapshotId } from '../../redux/actions/incomeUnit/incomeUnit';
import { RootState } from '../../redux/reducers/root';
import { GetElsLookupsState } from '../../redux/reducers/getLookups/getElsLookupsReducer';
import { BaseAsyncReducerState } from '../../redux/types';
import NewAccordion from '../../components/common/NewAccordion/NewAccordion';
import { GetAssistedLivingState } from '../../redux/reducers/getIncomeUnit/getAssistedLivingReducer';
import { GetIndependentLivingState } from '../../redux/reducers/getIncomeUnit/getIndependentLivingReducer';
import { GetMemoryCareState } from '../../redux/reducers/getIncomeUnit/getMemoryCareReducer';

interface SuiteSummaryProps {
    unitType?: "4" | "5" | "6";
}

export default function SuiteSummary(props: SuiteSummaryProps): JSX.Element {
    const dispatch = useDispatch()
    const { t } = useTranslation(['guided_wizard', 'navigation']);
    const apartmentTenantSummaryState: BaseAsyncReducerState = useSelector((state: RootState) => state.incomeUnitReducer.getApartmentTenantSummary);    
    const independentLivingState: GetIndependentLivingState = useSelector((state: RootState) => state.incomeUnitReducer.getIndependentLiving)
    const assistedLivingState: GetAssistedLivingState = useSelector((state: RootState) => state.incomeUnitReducer.getAssistedLiving)
    const memoryCareState: GetMemoryCareState = useSelector((state: RootState) => state.incomeUnitReducer.getMemoryCare)
    const elsLookupsState: GetElsLookupsState = useSelector((state: RootState) => state.lookupsReducer.getElsLookups);
    const [suiteCounts, setSuiteCounts] = useState<Record<string, number>>({});
    const propertyType: "multires" | "nonMultires" | "landLease" | "golfMotel" | "unitSubType4" | undefined = props.unitType ? "unitSubType4" : (localStorage.getItem("propertyCode") ? (["340", "341", "352", "361"].includes(localStorage.getItem("propertyCode") as string) ? "multires"  : 
        (["307", "382"].includes(localStorage.getItem("propertyCode") as string) ? "landLease" : (["490", "450"].includes(localStorage.getItem("propertyCode") as string) ? "golfMotel" : "nonMultires"))) : undefined)
    const multiresUnitSubtype = (elsLookupsState.dropdowns["multiresSubType"] as Record<string, unknown>[]).sort((a, b) => (a.unitSubtypePosition as number) - (b.unitSubtypePosition as number));
    const multiresRowHouseUnitSubtype = (elsLookupsState.dropdowns["multiresRowHouseSubType"] as Record<string, unknown>[]).sort((a, b) => (a.unitSubtypePosition as number) - (b.unitSubtypePosition as number));
    const nonMultiresUnitSubtype = (elsLookupsState.dropdowns["nonMultiresSubType"] as Record<string, unknown>[]).sort((a, b) => (a.unitSubtypePosition as number) - (b.unitSubtypePosition as number));
    const landLeaseUnitSubtype = (elsLookupsState.dropdowns["landLeaseSubType"] as Record<string, unknown>[]).sort((a, b) => (a.unitSubtypePosition as number) - (b.unitSubtypePosition as number));
    const golfMotelUnitSubtype = (elsLookupsState.dropdowns["golfMotelSubType"] as Record<string, unknown>[]).sort((a, b) => (a.unitSubtypePosition as number) - (b.unitSubtypePosition as number));
    const unitSubType4 = (elsLookupsState.dropdowns["unitSubType4"] as Record<string, unknown>[]).sort((a, b) => (a.unitSubtypePosition as number) - (b.unitSubtypePosition as number));
    const lookup = props.unitType ? unitSubType4 : (propertyType === "multires" ? [...multiresUnitSubtype, ...multiresRowHouseUnitSubtype] : (propertyType === "landLease" ? landLeaseUnitSubtype : (propertyType === "golfMotel" ? golfMotelUnitSubtype : nonMultiresUnitSubtype)))
    const simplifiedLookup = lookup.reduce((simplifiedLookup, unitSubtype) => ({[unitSubtype["unitSubtypeCode"] as string]: unitSubtype["unitSubtypeDescription"] as string, ...simplifiedLookup}),{"undefined": ""});
    const grouping = {
        "301": "1 Bed",
        "302": "2 Bed",
        "303": "3 Bed",
        "304": "4 Bed and up",
        "305": "4 Bed and up",
        "306": "4 Bed and up",
        "307": "4 Bed and up",
        "308": "Bachelor",
        "309": "Bachelor",
        "310": "1 Bed",
        "311": "2 Bed",
        "312": "3 Bed",
        "313": "4 Bed and up",
        "314": "4 Bed and up",
        "315": "Bachelor",
        "317": "1 Bed",
        "318": "2 Bed",
        "319": "3 Bed",
        "320": "4 Bed and up",
        "321": "4 Bed and up",
        "322": "4 Bed and up",
        "323": "4 Bed and up",
        "324": "Bachelor",
        "327": "1 Bed",
        "328": "2 Bed",
        "329": "3 Bed",
        "330": "4 Bed and up",
        "331": "4 Bed and up",
        "332": "4 Bed and up",
        "333": "4 Bed and up",
        "334": "Bachelor",
        "343": "Studio",
        "344": "1 Bed",
        "345": "2 Bed",
        "346": "3 Bed",
        "347": "4 Bed and up",
        "348": "4 Bed and up",
        "349": "4 Bed and up",
        "350": "4 Bed and up",
        "351": "Bachelor",
        "352": "Studio"
    }
    useEffect(() => {
        if (props.unitType) {
            dispatch(getTenantSummaryBySnapshotId(Number(localStorage.getItem("currentSnapshotId")), props.unitType));
        } else {
            dispatch(getApartmentTenantSummaryBySnapshotId(Number(localStorage.getItem("currentSnapshotId"))))
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const stateToUse = (props.unitType === "4" ? independentLivingState : (props.unitType === "5" ? assistedLivingState : (props.unitType === "6" ? memoryCareState : apartmentTenantSummaryState)))
        if (stateToUse.success && !stateToUse.loading && 
            ((props.unitType && (stateToUse as (GetAssistedLivingState | GetIndependentLivingState | GetMemoryCareState)).tenantSummary && ((stateToUse as (GetAssistedLivingState | GetIndependentLivingState | GetMemoryCareState)).tenantSummary as Record<string, unknown>[]).length > 0)
            || (!props.unitType && stateToUse.data && (stateToUse.data as Record<string, unknown>[]).length > 0))) {
            const suiteNumber: Record<string, number> = {};
            ((props.unitType ? (stateToUse as (GetAssistedLivingState | GetIndependentLivingState | GetMemoryCareState)).tenantSummary : stateToUse.data) as Record<string, unknown>[]).forEach((suite) => {
                const groupKey = ((suite["unitSubtypeCode"] as string) in grouping) ? grouping[suite["unitSubtypeCode"] as keyof typeof grouping] : simplifiedLookup[suite["unitSubtypeCode"]as keyof typeof simplifiedLookup] as string;
                if (!(groupKey in suiteNumber)) {
                    suiteNumber[groupKey] = 0;
                }
                if ("unitCount" in suite && suite["unitCount"] && (suite["unitCount"] as number) > 0) {
                    suiteNumber[groupKey] = suiteNumber[groupKey] + (suite["unitCount"] as number);
                } else {
                    suiteNumber[groupKey] = suiteNumber[groupKey] + 1;
                }
            });
            setSuiteCounts(suiteNumber);
        }
        // eslint-disable-next-line
    }, [apartmentTenantSummaryState, independentLivingState, assistedLivingState, memoryCareState, props.unitType])

    const buildSuiteSummary = () => {
        const suiteSummary = [];
        for (let i = 0; i < Object.keys(suiteCounts).length; i+= 4) {
            suiteSummary.push(<div className='summary-section-container' key={`suite-summary-${i}`}>
                <table className='summary-table summary-table-headerless'>
                    <thead className='invisible-header'><tr><th></th></tr></thead>
                    <tbody>
                    {Object.keys(suiteCounts).slice(i, Math.min(i + 4, Object.keys(suiteCounts).length)).map((key, index)=> (
                        <tr key={`suite-row-${i + index}`}>
                            <td>
                                {key === "undefined" ? "" : key}
                            </td>
                            <td>
                                {key === "-" ? "-" : suiteCounts[key]}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>);
        }
        return suiteSummary;
    }

    return (
        <>
            {Object.keys(suiteCounts).length > 0 && 
                <NewAccordion header={props.unitType === "4" ? t("independentLiving", {ns: "navigation"}) : 
                    (props.unitType === "5" ? t("assistedLiving", {ns: "navigation"}) : 
                    (props.unitType === "6" ? t("memoryCare", {ns: "navigation"}) : t("suite-summary", {ns: "guided_wizard"})))}>
                        <div className='summary-grid'>{buildSuiteSummary()}</div>
                </NewAccordion>}
        </>
    )
}