/**
 * Created: April 22, 2024 - Mariam Bawa - A reusable pagination component
 */

import React from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from 'react-paginate';
import { numberPerPageOptions } from "../../../assets/data/pagination/pagination";
import Dropdown from "../Dropdown/Dropdown";
import './Pagination.css';

interface PaginationProps {
  totalPages: number,
  nextLabel: string,
  previousLabel: string
  onPageChange: (selectedItem: { selected: number }) => void,
  itemsPerPage: number,
  setItemsPerPage: (itemCount: number) => void,
  className?: string,
  breakLabel?: string,
  forcePage?: number
}

export default function Pagination(props: PaginationProps): React.ReactNode {
  const { t } = useTranslation('table');

  const updateItemsPerPage = (e: React.ChangeEvent) => {
    props.setItemsPerPage(parseInt((e.target as HTMLSelectElement).value));
  }

  return (
    <div className='pagination-container'>
      <ReactPaginate
        className={`pagination ${props.className}`}
        breakLabel={props.breakLabel ? props.breakLabel : "..."}
        nextLabel={props.nextLabel}
        previousLabel={props.previousLabel}
        onPageChange={props.onPageChange}
        pageRangeDisplayed={props.itemsPerPage}
        pageCount={props.totalPages}
        forcePage={props.forcePage} />
      <Dropdown options={numberPerPageOptions}
        value={props.itemsPerPage} onChange={(e) => updateItemsPerPage(e)}
        ariaLabel={t("per-page")} testid="per-page-dropdown" />
    </div>
  );
}