import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { BusinessOperationsKeys } from '../../pages/BusinessOperations/BusinessOperations';
import { OperationDataClass, OperationFieldProps } from '../../types/gev/BusinessOperationFormProps';
import { useBusinessOperationsState } from '../../utils/context/BusinessOperationsContext';
import { useReadOnly } from '../../utils/ReadOnlyContext';
import Input from '../common/Input/Input';
import "./GEV.css";

export default function GEVInput(props: OperationFieldProps): JSX.Element {
    const { operation, name } = props;
    const { t } = useTranslation('gev')
    const { isReadOnly } = useReadOnly()
    const { formData, setFormData } = useBusinessOperationsState();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.removeError && props.removeError(name)
        const { value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [operation]: {
                ...prevData[operation as BusinessOperationsKeys],
                [name]: (value && value.trim()) ? value.trim() : null,
            },
        }));
    };

    const currentValue = formData[operation as BusinessOperationsKeys]?.[name as keyof OperationDataClass] ?? '';
    return (
        <>
            <tr className='gev-input-tr'>
                <td className='index'>
                    {props.index && props.index + "."}
                </td>
                <td>
                    <label className='gev-input-label' htmlFor={`${operation}.${name}`}>
                        {t(operation + "." + name)}
                    </label>
                    <Input type={props.type === "number" ? "number" : "text"}
                        id={operation + "." + name}
                        name={operation + "." + name}
                        value={currentValue as unknown as string}
                        className={'gev-input-100' + (props.hasErrors ? ' form-error' : '')}
                        title={t(operation + "." + name)}
                        ariaLabel={t(operation + "." + name)}
                        autoComplete="off"
                        disabled={isReadOnly}
                        onChange={handleChange}
                    />
                    <div className="input-error-message">
                        {props.errors && props.errors[name] as string}
                    </div>
                </td>
            </tr>
        </>
    )
}
