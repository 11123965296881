import { API_REQUEST, GET_ASSESSMENT_INFO, RESET_GET_ASSESSMENT_INFO, RESET_PUT_ASSESSMENT_INFO, SAVE_RESULT } from "../../actionTypes/actionsTypes"
import { AppAction, LocalAction } from "../../types"

export const getAssessmentInfo = (snapshotId: number | string): AppAction => {
  return {
    type: API_REQUEST,
    payload: {
      url: `/income/snapshots/${snapshotId}/assessment-info`,
      next: GET_ASSESSMENT_INFO,
      method: 'GET',
      authorization: true
    }
  }
}

export const resetGetAssessmentInfo = (): LocalAction => ({
  type: RESET_GET_ASSESSMENT_INFO
})

export const putAssessmentInfo = (snapshotId: number | string, data: Record<string, unknown>): AppAction => ({
  type: API_REQUEST,
  payload: {
    url: `/income/snapshots/${snapshotId}/assessment-info`,
    data: data,
    next: SAVE_RESULT,
    method: 'PUT',
    authorization: true
  }
})

export const resetPutAssessmentInfo = (): LocalAction => ({
  type: RESET_PUT_ASSESSMENT_INFO
})

